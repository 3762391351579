<template>
  <div>
    <ModalCard
      :imgCard="imgCard"
      :imgCardBlack="imgCardBlack"
      :title="title"
      :subTitle="subTitle"
      :text="text"
    ></ModalCard>

    <div class="responsive">
      <div class="item" v-for="item in items" :key="item.id">
        <div class="card">
          <div class="card-header">
            <p class="p-titleCard">{{ item.title }}</p>
            <label class="switch">
              <input type="checkbox" id="checkbox" v-model="item.checked" />
              <span class="sliderCard round"></span>
            </label>
            <!-- <label for="checkbox">{{ checked }}</label> -->
          </div>
          <div class="card-body">
            <img class="img-card" :src="item.image" alt="" />
            <img class="img-cardBlack" :src="item.imageBlack" alt="" />
            <div class="container-body">
              <div class="container-bodyT">
                <p class="body-titleCard">{{ item.subtitle }}</p>
              </div>
              <div class="container-credits">
                <Credits></Credits>
              </div>
            </div>
          </div>

          <div
            @click="onClick(item.index)"
            v-if="!item.checked"
            class="card-footer"
          >
            <p class="p-footer">Obtener información</p>
            <i :id="item.id" class="fa fa-chevron-right arrow"></i>
          </div>
          <router-link class="link-router" :to="item.path">
            <div v-if="item.checked" class="card-footer card-footer2">
              <p class=" p-footer p-footer2">CREAR NUEVA CAMPAÑA</p>
              <i class="fa fa-chevron-right arrow"></i>
            </div>
          </router-link>
        </div>
      </div>
      <!-- <div class="item">
      <div class="card">
        <div class="card-header">
          <p class="p-title">SMS</p>
          <label class="switch">
            <input type="checkbox" id="checkbox" v-model="checked2" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="card-body">
          <img class="img-card" src="../../assets/gif/home/sms.gif" alt="" />
          <div class="container-body">
            <div class="container-bodyT">
              <p class="body-title">Kpi´s sugeridos por la industria</p>
            </div>
            <div class="container-credits">
              <Credits></Credits>
            </div>
          </div>
        </div>
        <div v-if="!checked2" class="card-footer">
          <p class="p-footer">Obtener información</p>
          <i @click="onClick" class="fa fa-chevron-right arrow"></i>
        </div>
        
        <div v-if="checked2" class="card-footer card-footer2">
          <p class=" p-footer p-footer2">Ver más</p>
          <i class="fa fa-chevron-right arrow2"></i>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="card">
        <div class="card-header">
          <p class="p-title">Mail</p>
          <label class="switch">
            <input type="checkbox" id="checkbox" v-model="checked3" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="card-body">
          <img class="img-card" src="../../assets/gif/home/Mail.gif" alt="" />
          <div class="container-body">
            <div class="container-bodyT">
              <p class="body-title">Kpi´s sugeridos por la industria</p>
            </div>
            <div class="container-credits">
              <Credits></Credits>
            </div>
          </div>
        </div>
        <div v-if="!checked3" class="card-footer">
          <p class="p-footer">Obtener información</p>
          <i class="fa fa-chevron-right arrow"></i>
        </div>
        <div v-if="checked3" class="card-footer card-footer2">
          <p class=" p-footer p-footer2">Ver más</p>
          <i class="fa fa-chevron-right arrow2"></i>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="card">
        <div class="card-header">
          <p class="p-title">Voip</p>
          <label class="switch">
            <input type="checkbox" id="checkbox" v-model="checked4" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="card-body">
          <img class="img-card" src="../../assets/gif/home/VoIP.gif" alt="" />
          <div class="container-body">
            <div class="container-bodyT">
              <p class="body-title">Kpi´s sugeridos por la industria</p>
            </div>
            <div class="container-credits">
              <Credits></Credits>
            </div>
          </div>
        </div>
        <div v-if="!checked4" class="card-footer">
          <p class="p-footer">Obtener información</p>
          <i class="fa fa-chevron-right arrow"></i>
        </div>
        <div v-if="checked4" class="card-footer card-footer2">
          <p class=" p-footer p-footer2">Ver más</p>
          <i class="fa fa-chevron-right arrow2"></i>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="card">
        <div class="card-header">
          <p class="p-title">Analitics</p>
          <label class="switch">
            <input type="checkbox" id="checkbox" v-model="checked5" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="card-body">
          <img
            class="img-card"
            src="../../assets/img/lf20_yeu77cph.json].png"
            alt=""
          />
          <div class="container-body">
            <div class="container-bodyT">
              <p class="body-title">Kpi´s sugeridos por la industria</p>
            </div>
            <div class="container-credits">
              <Credits></Credits>
            </div>
          </div>
        </div>
        <div v-if="!checked5" class="card-footer">
          <p class="p-footer">Obtener información</p>
          <i class="fa fa-chevron-right arrow"></i>
        </div>
        <div v-if="checked5" class="card-footer card-footer2">
          <p class=" p-footer p-footer2">Ver más</p>
          <i class="fa fa-chevron-right arrow2"></i>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="card">
        <div class="card-header">
          <p class="p-title">Analitics</p>
          <label class="switch">
            <input type="checkbox" id="checkbox" v-model="checked6" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="card-body">
          <img
            class="img-card"
            src="../../assets/img/lf20_yeu77cph.json].png"
            alt=""
          />
          <div class="container-body">
            <div class="container-bodyT">
              <p class="body-title">Kpi´s sugeridos por la industria</p>
            </div>
            <div class="container-credits">
              <Credits></Credits>
            </div>
          </div>
        </div>
        <div v-if="!checked6" class="card-footer">
          <p class="p-footer">Obtener información</p>
          <i class="fa fa-chevron-right arrow"></i>
        </div>
        <div v-if="checked6" class="card-footer card-footer2">
          <p class=" p-footer p-footer2">Ver más</p>
          <i class="fa fa-chevron-right arrow2"></i>
        </div>
      </div>
    </div> -->
    </div>
  </div>
</template>
<script>
import Credits from "../common/Credits";
import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";
import ModalCard from "../../components/modals/ModalCard";
/*import imgAnalitics from "../../assets/gif/home/Alteralytics.gif";
import imgSms from "../../assets/gif/home/sms.gif";
import imgMail from "../../assets/gif/home/Mail.gif";*/
export default {
  components: {
    Credits,
    ModalCard,
  },

  data() {
    return {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked8: false,
      imgCard:'',
      imgCardBlack:'',
      // imgCard: imgAnalitics,
      title: "",
      subTitle: "",
      text: "",
      items: [
        {
          id: "alteraLytics",
          title: "AlteraLytics",
          image: require("../../assets/gif/home/Alteralytics.gif"),
          imageBlack:require("../../assets/gif/homeBlack/alteralyticsBlack.gif"),
          subtitle: "Kpi´s sugeridos por la industria",
          checked: this.checked1,
          index: 0,
          path: "/SMS",
        },
        {
          id: "sms",
          title: "SMS",
          image: require("../../assets/gif/home/sms.gif"),
           imageBlack:require("../../assets/gif/homeBlack/smsBlack.gif"),
          subtitle: "98% de entregabilidad",
          checked: this.checked2,
          index: 1,
          path: "/sms/nueva-camp-sms",
        },
        {
          id: "mail",
          title: "Mail",
          image: require("../../assets/gif/home/Mail.gif"),
           imageBlack:require("../../assets/gif/homeBlack/mailBlack.gif"),
          subtitle: "Mejor forma de publicar",
          checked: this.checked3,
          index: 2,
          path: "/mail/nueva-camp-mail",
        },
        {
          id: "discador",
          title: "Discador",
          image: require("../../assets/gif/home/VoIP.gif"),
           imageBlack:require("../../assets/gif/homeBlack/discadorBlack.gif"),
          subtitle: "Lorem ipsum dolor sit amet",
          checked: this.checked4,
          index: 3,
          path: "/mail",
        },
        {
          id: "alteraPay",
          title: "AlteraPay",
          image: require("../../assets/gif/home/alterapay.gif"),
             imageBlack:require("../../assets/gif/homeBlack/alterapayBlack.gif"),
          subtitle: "Kpi´s sugeridos por la industria",
          checked: this.checked5,
          index: 4,
          path: "/mail",
        },
        {
          id: "BIAS",
          title: "BIAS",
          image: require("../../assets/gif/home/BIAS.gif"),
           imageBlack:require("../../assets/gif/homeBlack/biasBlack.gif"),
          subtitle: "Kpi´s sugeridos por la industria",
          checked: this.checked7,
          index: 5,
          path: "/mail",
        },
        {
          id: "whatsApp",
          title: "WhatsApp",
          image: require("../../assets/gif/home/whatsapp.gif"),
          imageBlack:require("../../assets/gif/homeBlack/whatsappBlack.gif"),
          subtitle: "Kpi´s sugeridos por la industria",
          checked: this.checked6,
          index: 6,
          path: "/mail",
        },

        {
          id: "journey",
          title: "Journey",
          image: require("../../assets/gif/home/journey.gif"),
          imageBlack:require("../../assets/gif/homeBlack/journeyBlack.gif"),
          subtitle: "Kpi´s sugeridos por la industria",
          checked: this.checked8,
          index: 7,
          path: "/mail",
        },
      ],
    };
  },
  methods: {
    onClick(index) {
      this.imgCard = this.items[index].image;
       this.imgCardBlack = this.items[index].imageBlack;
      this.title = this.items[index].title;
      this.subTitle = this.items[index].subtitle;
      this.text =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit ac metus nibh integer ac volutpat feugiat nunc. In in diam tortor amet, eu adipiscing mi. In nisi eget dolor adipiscing et vivamus ultricies posuere. Sollicitudin pulvinar duis pellentesque est senectus in. In in diam tortor amet, eu adipiscing mi. In nisi eget dolor adipiscing et vivamus ultricies posuere. Sollicitudin pulvinar duis pellentesque est senectus in.";
      this.$modal.show("modalCard");
    },
  },
  mounted() {
    tns({
      items: 1,
      /* gutter: 39, */
      controls: false,
      container: ".responsive",
      navPosition: "bottom",
      loop: false,
      stop: false,
      responsive: {
        768: {
          items: 2,
        },
        
 991: {
          items:3,
        },
     
        1024: {
          items: 3,
        },
        1180: {
          items: 4,
        },
        1440: {
          items: 4,
        },
      },
    });
  },
};
</script>
<style scoped>
.my-slider {
  /* border: 2px solid lightcoral; */
}
.item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 13px;
}
.card {
  background-color: var(--color-01);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  border-radius: var(--borderRadius-2);
}

.card-header {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  /* background-color: aqua; */
}
.p-titleCard {
  font-family: var(--font-family-2);
  font-weight: 700;
  font-size: 1.5em;
  color: var(--text-01);
}
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-card, .img-cardBlack {
  height: 10rem;
}
.body-titleCard {
  font-family: var(--font-family-2);
  font-weight: 400;
  font-size: 1.25em;
  color: var(--color-17);
}
.container-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container-bodyT {
  width: 60%;
  margin: 2rem 0 2rem 0;
}
.container-credits {
  /* width: 75%; */
  display: flex;
  justify-content: center;
}
.card-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: var(--color-05);
  height: 1.9em;
  margin-top: 1.5rem;
  border-radius: 0px 0px 15px 15px;
  padding: 1.5rem 0;
  cursor: pointer;
}
.card-footer2 {
  /*     display:flex;
    justify-content: space-around;
    align-items: center;
    width: 100%; */
  background-color: var(--color-03);
  justify-content: space-evenly;
  /*   height: 1.9em;
    margin-top:1.5rem;
    border-radius:0px 0px 15px 15px;
    padding: 1.5rem 0  */
}
.p-footer {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 0.875em;
  color: var(--color-01);
  letter-spacing: 0.14px;
}
.p-footer2 {
  /*  font-family: var(--font-family-2);
    font-weight: 600;
    font-size: 0.875em; */
  color:  var(--color-16);
  /* letter-spacing: 0.14px; */
  /* padding-right: 5rem; */
}
.fa-chevron-right {
  font-size: 0.899em;
  color: var(--color-01);
}
/* .arrow {
  cursor: pointer;
} */
.arrow2 {
  /* font-size: 0.899em; */
  color: var(--color-05);
  /* cursor: pointer; */
}

a{
    width: 100%;
  }
/* switch */

.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 23px;
  /*  margin-top: 1rem; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderCard {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-18);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderCard:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 1px 3px rgb(101 101 101 / 33%);
}

input:checked + .sliderCard {
  background-color: var(--color-11);
}

input:checked + .sliderCard:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(14px);
}

/* Rounded sliders */
.sliderCard.round {
  border-radius: 34px;
}

.sliderCard.round:before {
  border-radius: 50%;
}
.img-cardBlack{
  display: none
}
.link-router{
  user-select: none;
}
@media  (max-width: 1024px) {
  .card-header {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .card-body {
    flex-direction: row;
    padding: 0 0.5rem;
  }
  .img-card, .img-cardBlack {
    width: 45%;
    height: 7rem;
  }
  .card-footer {
    margin-top: 1rem;
    padding: 0;
  }
  .container-bodyT{
    width: 90%;
  }
  .p-titleCard {
    font-size: 1.25em;
  }
  .body-titleCard {
    font-size: 0.938em;
    text-align: center;
  }
  
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .card-body {
    flex-direction: row;
    padding: 1rem;
  }
  .card-header {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .card-body {
    flex-direction: row;
    padding: 0 1rem;
  }
  .img-card, .img-cardBlack {
    width: 34%;
    height: 5rem;
  }
  .card-footer {
    margin-top: 1rem;
    padding: 0;
  }
  .container-bodyT{
    width: 90%;
  }
  .p-titleCard {
    font-size: 1.25em;
  }
  .body-titleCard {
    font-size: 0.938em;
    text-align: center;
  }
  
}
</style>

<template>
  <div class="container-platform container-dark">
    <div class="body-platform " :class="platCont">
      <p class="title titleDark">{{ service }}</p>
      <div class="d-btn">
        <router-link :to="`/${url}`" class=""> 
        <button :class="dispBtn" class="btnPlatform">{{ paramBtn }}</button>
        </router-link> 
      </div>
    </div>
    <div class="d-img">
      <img :src="img" alt="" class="imgPlatf"/>
       <img :src="imgDark" alt="" class="imgPlatDark"/>
    </div>
  </div>
</template>

<script>
export default {
  props: ["service", "paramBtn", "img", "imgDark", "dispBtn", "platCont","url"],
};
</script>

<style scoped>
.container-platform {
  /* border:2px solid black; */
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
 /*  width: 100%; */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  width: 93%;
  display: flex;
  height: 100%;
  /* padding: .5rem 2rem; */
}
.platCont{
align-items: flex-start !important;
margin-left: 2rem
}

.body-platform {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
      align-items: center;
  /* border: 2px solid salmon; */
  /* margin: 1rem; */
  /* padding-right: 2rem; */
  /* margin: .5rem; */
  /* padding-left: 1.5rem; */
  width: 88%;
  /* margin: 1rem 2rem 0.5rem; */
  /*  align-items: flex-start;
    padding-left: 2rem; */
}
.d-btn {
  /*  border:2px solid black; */
 width: 85%; 
}
.btnPlatform {
  background-color: var(--color-05);
  border-radius: var(--borderRadius-1);
  width: 90%;
  height: 3rem;
  border: none;
  color: var(--color-01);
  font-weight: 600;
  outline: none;
  cursor: pointer;
  font-size: 1.063em;
  letter-spacing: 0.694286px;
 
}
.d-img {
  /*  padding-left: 1rem; */
  /* border:2px solid green; */
  /* padding-left:1rem; */
  /* padding: 1rem; */
  padding: 0.5rem 1rem 0rem 0;
  width: 40%;
}
img, .imgPlatDark {
  width: 100%; 
   /*  border-radius: 50%;  */
}
.title {
  font-family: var(--font-family-2);
  font-weight: 800;
  font-size: 1.563em;
  color: var(--color-17);
  letter-spacing: 2px;
}
.imgPlatDark{
  display:none
} 
.dispBtn{
  display:none
}
@media  (max-width: 992px) {
  .container-platform{
    /* width: 70%; */
   /*  margin-bottom: 2rem */
  }
   .title {
    font-size: 1.25em;
   /*  padding-bottom: 0.5rem; */
  }
 .btnPlatform{
    width:80%;
    font-size: 0.8em
  }
}
@media (min-width: 993px) and (max-width: 1199px) {
  .title {
    font-size: 1.25em;
   /*  padding-bottom: 0.5rem; */
  }

 .btnPlatform{
    width:80%;
    font-size: 0.8em
  }
  .body-platform {
    width: 70%;
    margin: 1rem 0 0.5rem 2rem;
}

.d-img{

    padding: 1.5rem 0.5rem 0rem 0;
    /* width: 40%; */
}
 

}
@media (min-width: 1200px) and (max-width: 1439px) {
   button{
    width:100%;
    font-size: 1.063em
  }
}
</style>

<template>
  <div id="chart">
    <apexchart
      type="radialBar"
      height="200"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <div>
      <p class="perceChartMail">{{ this.series[0] }}%</p>
      <p class="texPerceChartMail">Entregabilidad</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      series: [90],
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
          toolbar: {
            show: false,
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
            },
          },
          hover: {
            filter: {
              type: "none",
            },
          },
          active: {
            allowMultipleDataPointsSelection: true,
            filter: {
              type: "none",
            },
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "70%",
              background: "transparent",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "back",
              dropShadow: {
                enabled: true,
                top: 1,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "70%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -1,
                left: 0,
                blur: 2,
                opacity: 0.5,
              },
            },

            dataLabels: {
              show: false,
            },
          },
        },
        fill: {
          colors: ["#7A40F2"],
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0,
            gradientToColors: ["#e7e1f3"],
            inverseColors: true,
            opacityFrom: 50,
            opacityTo: 50,
            stops: [5, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        /* labels: ["Percent"], */
      },
    };
  },
};
</script>
<style scoped>
.perceChartMail,
.texPerceChartMail {
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 1.25em;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--color-17);
}
.texPerceChartMail {
  font-size: 12px;
}
@media (max-width: 991px) {
  .perceChartMail {
    font-size: 1.025em;
  }
  .texPerceChartMail {
    font-size: 0.683em;
  }
}
</style>

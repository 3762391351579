<template>
  <div class="container">
    <div class="row">
      <div class="main-sidebar">
        <Sidebar></Sidebar>
      </div>
      <div class="col-sm-12  container-all">
        <div class="main">
          <div class="row center-sm center-md center-lg  ">
            <div class=" main-all">
              <div class="row rowOne">
                <div class="col-sm-4 col-md-4 col-lg-5">
                  <Search></Search>
                </div>
                <div class="  col-sm-3 col-md-3 col-lg-3  ">
                  <div class="containerCredits">
                    <Credits
                      text="disponibles"
                      border="container-CreditsTwo"
                      imgWidth="img"
                    ></Credits>
                  </div>
                </div>
                <div class="col-sm-5 col-md-5 col-lg-4">
                  <NotifAndSetti widthRow="widthRow">
                    <template slot="shipping">
                      <img
                        class="imgMenu"
                        src="../assets/img/iconOrange/Shipping.svg"
                        alt=""
                      />
                    </template>
                    <template slot="info">
                      <router-link to="/centro-ayuda" class="">
                        <img
                          class="imgInfo "
                          src="../assets/icons/home/info.svg"
                          alt=""
                        />
                      </router-link>
                    </template>
                    <template slot="config">
                      <router-link to="/perfil" class="">
                        <img
                          class=" "
                          src="../assets/icons/home/herramientas.svg"
                          alt=""
                        />
                      </router-link>
                    </template>
                    <template slot="notif">
                      <img
                        class=""
                        src="../assets/icons/home/campana.svg"
                        alt=""
                      />
                    </template>
                  </NotifAndSetti>
                </div>
              </div>
              <div class="row rowTwo">
                <TableResumenBiasMail></TableResumenBiasMail>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "../components/common/Sidebar";
import Search from "../components/common/Search";
import Credits from "../components/common/Credits";
import NotifAndSetti from "../components/common/NotifAndSetti";
import TableResumenBiasMail from "../components/tables/TableResumenBiasMail";

export default {
  components: {
    Sidebar,
    Search,
    Credits,
    NotifAndSetti,
    TableResumenBiasMail,
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.rowOne {
  /*  border: 2px solid brown; */
  padding-bottom: 2rem;
  align-items: center;
}
.rowTwo {
  /*   border: 2px solid red; */
}
.rowThree {
  /* border: 2px solid red;  */
}

.main-sidebar {
  display: flex;
  position: fixed;
  /* justify-content: center; */
  /* border: 3px solid green; */
  /* width: 8%; */
  height: 87vh;
  z-index: 1000;
  /* padding-top: 3.5%; */
  top: 3rem;
  left: 3.4vh;
  /*   padding-bottom: 4%; */
  /* background-color: aqua; */
}

.container-all {
  /* margin:1rem 5rem ; */
  display: flex;
  justify-content: center;
}
.main {
  background-color: var(--color-16);
  min-height: 100vh;
  width: 92%;
  border-radius: var(--borderRadius-1);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.main-all {
  /* border: 2px solid black; */
  width: 90%;
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 2rem;

  margin-left: 5vh;
}

/* .container-credits {
  width: 95%;
  display: flex;
  justify-content: center;
} */

@media (max-width: 992px) {
  .container-all {
    justify-content: flex-end;
    padding-right: 0.5rem;
  }
  .main {
    width: 95%;
  }
  .col-md-5.col-lg-5 {
    display: flex;
    justify-content: center;
  }
  .rowTwo {
    display: flex;
    flex-direction: column;
  }
  .main-all {
    width: 88%;
  }

  .main-sidebar {
    padding-left: 0;
    height: 88vh;
    /* padding-top: 7%; */
    left: 0;
  }
  .containerCredits {
    width: 75%;
    margin-left: 3rem;
  }
}
@media (max-width: 1024px) {
  .main-all {
    margin-left: 5vh;
  }
  .main-sidebar {
    /* padding-left: 0.5vh; */
/*     height: 88vh;
    left: 0.3em;
    top: 5.5vh; */
    /*  position: fixed; */
    left: 0;
  }
  .col-md-5.col-lg-5 {
    /* display: flex; */
    width: 43%;
  }
  .col-md-7.col-lg-7 {
    width: 57%;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .main-all {
    margin-left: 5vh;
  }
  .main-sidebar {
    /* padding-left: 0.5vh; */
    height: 88vh;
    left: 0.3em;
    top: 5.5vh;
    /*  position: fixed; */
    
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .main-sidebar {
    /* padding-left: 0.5vh; */
    /*     height: 88vh;

    top: 5.5vh; */
    left: 1em;
  }
}
@media (min-width: 1600px) {
  .main-sidebar {
    /* padding-left: 3.5vh; */
    height: 90vh;
    padding-top: 0%;
    left: 1.6em;
  }
}
@media (min-width: 1700px) {
  .main-sidebar {
    /*   padding-left: 3.5vh; */
    /*   height: 90vh;
    padding-top: 0%; */
    left: 1.9em;
  }
}
@media (min-width: 1800px) {
  .main-sidebar {
    /*   padding-left: 3.5vh; */
    /*   height: 90vh;
    padding-top: 0%; */
    left: 2.1em;
  }
  .main-all {
    margin-left: 4vh;
  }
}
@media (min-width: 1900px) {
  .main-sidebar {
    /*   padding-left: 3.5vh; */
    /*   height: 90vh;
    padding-top: 0%; */
    left: 2.5em;
  }
}
</style>

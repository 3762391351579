<template>
  <div class="container-biasMail">
    <div class="allBiasMail">
      <div class="headerCont">
        <div class="titleHeader">
          <p>Mail</p>
        </div>
        <div class="textArrow">
          <router-link to="/bias/resumen-mail" class="">
          <p>Ver más</p>
          <span class="arrLeftMailBias"></span>
          </router-link>
        </div>
      </div>
      <div class="bodyBiasMail">
        <div class="subTitleBody">
          <p>última campaña</p>
        </div>
        <div class="allCont">

        
        <div class="containerLeft">
          <div class="letText01">
            <div class="img01">
              <img src="../../assets/img/iconLila/letterClo.svg" alt="" />
            </div>
            <div class="dateHour">
              <p class="date">8 Marzo</p>
              <p class="hour">17:39hrs</p>
            </div>
          </div>
          <div class="letText02">
            <div class="img02">
              <img src="../../assets/img/iconLila/letterOp.svg" alt="" />
            </div>
            <div class="percText">
              <p class="perc">72%</p>
              <p class="text">Aperturas</p>
            </div>
          </div>
          <div class="letText03">
            <div class="img03">
              <img src="../../assets/img/iconLila/silence.svg" alt="" />
            </div>
            <div class="percText">
              <p class="perc">28%</p>
              <p class="text">Clicks</p>
            </div>
          </div>
        </div>
        <div class="containerRight">
          <chartBiasMail></chartBiasMail>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chartBiasMail from "../../components/charts/chartBiasMail";
export default {
  components: {
    chartBiasMail,
  },
};
</script>
<style scoped>
a {
    display: flex;
}
.container-biasMail {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var( --borderRadius-1);
  width: 90%;
  display: flex;
  justify-content: center;
}
.allBiasMail {
  width: 90%;
  /*   border: 2px solid red; */
}
.headerCont,
.textArrow {
  display: flex;
}
.headerCont {
  justify-content: space-between;
}
.headerCont {
  margin: 1rem 0 0;
}
.titleHeader p {
  font-size:1.563em;
}
.textArrow {
  align-items: center;
}
.titleHeader p,
.textArrow p {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
}
.textArrow p {
  font-size: 0.875em;
  letter-spacing: 0.14px;
  color: var(--color-15);
  margin-bottom: 0.3rem;
  margin-right: 1rem;
}
.arrLeftMailBias {
  content: url(../../assets/img/iconLila/ArrowLiLeft.svg);
}
.subTitleBody p {
  font-size: 0.75em;
  text-align: start;
}

.subTitleBody p,
.dateHour p,
.percText p {
  font-family: var(--font-family-3);
  font-weight: 500;
}
.dateHour,
.percText {
  line-height: 100%;
  text-align: start;
}
.dateHour p,
.subTitleBody p,
.titleHeader p,
.percText p {
  color: var(--color-17);
}
.percText {
  width: 3rem;
}

.date,
.perc {
  font-size: 1.25em;
  letter-spacing: 0.694286px;
}
.hour,
.text {
  font-size: 0.625em;
}
.containerLeft {
  width: 45%;
  margin-top: 2rem;
}
.letText01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #1cc8ee;
  padding-bottom: 1.5rem;
}
.letText02,
.letText03 {
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-around;
}
.letText02 {
  margin: 1.5rem 0 1.5rem;
}
.letText03 {
  margin: 0 0 1rem;
}
.containerRight {
    width: 50%;
}
.allCont {
    display: flex;
    justify-content: space-between;
}
@media (max-width: 992px) {
  .titleHeader p{
    font-size: 1.25em
  }
  .date,.perc {
    font-size: 1.05em;
    
}
.img01 img,.img02 img ,.img03 img{
  width: 70%;
}
.allBiasMail {
    width: 95%;
   
}
.containerLeft {
    width: 44%;
   
}
.containerRight {
    width: 51%;
}
.container-biasMail {

    width: 100%;
  
    margin-right: 1rem;
}
}
@media (max-width: 1024px){
  .container-biasMail {

    width: 100%;
  
    margin-right: 1rem;
}
}
@media (min-width: 1024px) and (max-width: 1119px){
  .titleHeader p{
    font-size: 1.25em;
  }
  .img01 img,.img02 img ,.img03 img{
  width: 70%;
}
.date, .perc {
    font-size: 1.05em;
    
}
.allBiasMail {
    width: 95%;
    /* border: 2px solid red; */
}
.containerLeft{
    width: 40%;
    
}
.containerRight {
    width: 56%;
}
}
@media (min-width: 1020px) and (max-width: 1245px){

.allBiasMail {
    width: 95%;
    /* border: 2px solid red; */
}
.containerLeft{
    width: 40%;
    
}
.containerRight {
    width: 56%;
}
}
</style>

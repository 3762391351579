<template>
  <div class="container-trends" v-if="render">
    <div class="data-container">
      <div class="cardTrends">
        <p class="title-trends textWhite">
          Tendencias • <span>Últimas campañas</span>
        </p>

        <div class="tab-control">
          <p class=" chart-title textWhite">{{ chartTitle }}</p>
          <div class="btns">
            <button
              @click="changeTab(1)"
              class="btn-tab "
              :class="{ active: currentTab == 1 }"
            >
              Entregados
            </button>
            <button
              @click="changeTab(2)"
              class="btn-tab "
              :class="{ active: currentTab == 2 }"
            >
              Rechazados
            </button>
            <button
              @click="changeTab(3)"
              class="btn-tab "
              :class="{ active: currentTab == 3 }"
            >
              Aperturas
            </button>
          </div>
        </div>
        <div class="tab" v-if="currentTab == 1">
          <apexchart
            type="bar"
            height="300"
            :options="entregadosChartOptions"
            :series="entregados"
            class="chartsTre"
          />
        </div>
        <div class="tab" v-if="currentTab == 2">
          <apexchart
            type="bar"
            height="300"
            :options="rechazadosChartOptions"
            :series="rechazados"
            class="chartsTre"
          />
        </div>
        <div class="tab" v-if="currentTab == 3">
          <apexchart
            type="bar"
            height="300"
            :options="aperturasChartOptions"
            :series="aperturas"
            class="chartsTre"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      render: true,
      hidden: true,
      campaigns: [],
      entregados: [
        {
          name: "Porcentaje Entregados",
          data: [
            400,
            430,
            448,
            470,
            540,
            580,
            690,
            470,
            540,
            580,
            690,
            1100,
            1200,
            1380,
          ],
        },
      ],
      rechazados: [
        {
          name: "Porcentaje Rechazados",
          data: [540, 580, 690, 1100, 1200, 1380],
        },
      ],
      aperturas: [
        {
          name: "Porcentaje Aperturas únicas",
          data: [1100, 1200, 1380, 580, 690, 1100, 1200, 1380],
        },
      ],
      chartTitle: "Entregados",
      currentTab: 1,
      entregadosChartOptions: {
        colors: ["#7A40F2"],
        chart: {
          toolbar: {
            show: false,
          },
          height: 300,
          type: "bar",
          events: {},
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: [],
            },
          },
          /* title: {
            text: "Campañas",
            offsetY: 5,
            style: {
              colors: "",
            },
          }, */
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
                text: "Promedio",
              },
            },
          ],
        },
      },
      rechazadosChartOptions: {
        colors: ["#7A40F2"],
        chart: {
          toolbar: {
            show: false,
          },
          height: 300,
          type: "bar",
          events: {},
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: [],
            },
          },
          /* title: {
            text: "Campañas",
            offsetY: 5,
            style: {
              colors: "",
            },
          }, */
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
                text: "Promedio",
              },
            },
          ],
        },
      },
      aperturasChartOptions: {
        colors: ["#7A40F2"],
        chart: {
          toolbar: {
            show: false,
          },
          height: 300,
          type: "bar",
          events: {},
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: [],
            },
          },
          title: {
            text: "",
            offsetY: 5,
            style: {
              colors: "",
            },
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
                text: "Promedio",
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == 1) {
        this.chartTitle = "Entregados";
      } else if (tab == 2) {
        this.chartTitle = "Rechazados";
      } else {
        this.chartTitle = "Aperturas únicas";
      }
    },
  },
};
</script>
<style scoped>
.title-trends {
  font-size: 1.188em;
  letter-spacing: 0.694286px;
  color: var(--text-01);
  margin-bottom: 1rem;
}
.chart-title,
.title-trends {
  font-family: var(--font-family-3);
  font-weight: normal;
  color: var(--text-01);
}
.title-trends span,
.chart-title {
  font-size: 0.875wm;
}
.tab-control {
  display: flex;
  justify-content: space-around;
}
.btn-tab {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size:1.063em;
  text-align: center;
  color: var(--color-05);
  background: var(--color-18);
  border: 1px solid var(--color-18);
  box-sizing: border-box;
  border-radius: var(--borderRadius-1);
  padding: 0 .5rem
}
.btns {
  width: 55%;
  display: flex;
  justify-content: space-around;
}
.active{
    background-color: var(--color-05);
    color:var(--color-18)
}
@media (min-width: 1200px) and (max-width: 1407px){
    .title-trends {
     font-size: 1.063em;
    
}
.btn-tab {
    font-size: 0.938em;
}
.btns {
    width: 65%;
   
}
}
</style>

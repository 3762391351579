<template>
  <div>
    <div class=" containter-contact">
      <router-link to="/" class="linkManagPro">
        <img class="imgArrow"  src="../../assets/icons/others/ArrowLeft.svg" alt="" />
      </router-link>
      <p class="title titleDark">Administración de perfil</p>
    </div>
    <div class="all">
      <div class="img">
        <img
          class="gifCal"
          src="../../assets/gif/panels/festival-calendar.gif"
          alt=""
        />
        <img src="../../assets/gif/panelsBlack/calendarBlack.gif" alt="" class="calendarBlack">
      </div>
      <div class="sendAdd">
        <div class="sendCredits">
          <div class="container-credits container-dark">
            <div class="header-credits">
              <p>Destinar créditos</p>
            </div>
            <div class="body-credits">
              <div class="container-img">
                <div>
                  <img src="../../assets/img/iconGrey/circleGrey.svg" alt="" />
                  <p class="pName titleDark">Pablo</p>
                </div>
                <div>
                  <img src="../../assets/img/iconGrey/circleGrey.svg" alt="" />
                  <p class="pName titleDark ">Sofía</p>
                </div>
                <div>
                  <img src="../../assets/img/iconGrey/circleGrey.svg" alt="" />
                  <p class="pName titleDark">Hector</p>
                </div>
              </div>
              <div>
                <p class="needCred titleDark ">¿Cuántos créditos necesitas?</p>
                <input type="text" placeholder="N° de créditos" class="inputDark" />
              </div>
            </div>
            <div class="footer-credits">
              <button class="btnSendProf">Destinar</button>
            </div>
          </div>
        </div>
        <div class="addProfile">
          <div class="container-profile container-dark">
            <div class="header-profile">
              <p>Agregar perfil</p>
            </div>
            <div class="container-input">
              <input type="text" placeholder="Nombre" class="inputDark"/>
              <input type="email" placeholder="Email" class="inputDark" />
              <input type="tel" placeholder="Teléfono" class="inputDark"/>
              <input type="password" placeholder="Contraseña" class="inputDark" />
              <input type="password" placeholder="Repetir constraseña" class="inputDark" />
            </div>
            <div class="footer-profile">
              <button class="btn">Crear</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
a.linkManagPro{
  width: 0;
}
.containter-contact {
  background-color: var(--color-01);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--borderRadius-1);
  display: flex;
  align-items: center;
  padding: 1rem 0rem 1rem 2.5rem;
  width: 24.5rem;
  margin-bottom: 3.5rem;
}
.title {
  font-family: var(--font-family-2);
  font-weight: 600;
  color: var(--text-01);
  font-size: 1.563rem;
  margin-left: 2rem;
}
.img {
  width: 40%;
  margin-left: 3rem;
}

.all {
  display: flex;
  align-items: center;
      margin-bottom: 3rem;
}
.sendCredits,
.addProfile {
  width: 42%;
  /* border: 2px solid red; */
 /*  display: flex;
  justify-content: flex-end; */
}
.sendCredits {
  margin-left: 2rem;
}
.container-credits,
.container-profile {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  width: 100%;
  /* padding: 2rem; */
  display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

.gifCal, .calendarBlack{
  width: 100%;
}
.container-img {
  display: flex;
  justify-content: space-between;
}
.sendAdd {
  display: flex;
  justify-content: space-around;
}
.header-credits ,
.header-profile{
  margin-top: 2rem
}
.header-credits p,
.header-profile p {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  color: var(--text-01);
  text-align: start;
  padding-bottom: 1.5rem;
}
.body-credits {
    width: 85%;
}
.pName,
.needCred {
  font-family: var(--font-family-2);
  color: var(--text-01);
  font-size: 1.25em;
}
.needCred {
  letter-spacing: 0.694286px;
  text-align: start;
  padding-top: 2rem;
}
.container-input{
  width: 85%;
}
input {
  border: none;
  border-bottom: 2px solid var(--color-03);
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  outline: none;
}
.footer-credits {
  display: flex;
  justify-content: flex-end;
 /*  margin-top: 4rem; */
 padding-bottom: 1rem;
 width: 85%;
}
button, .btnSendProf {
  background-color: var(--color-18);
  border-radius: var(--borderRadius-1);
  cursor: pointer;
  border: none;
  font-family: var(--font-family-2);
  color: var(--color-01);
  font-size: 1.063em;
  text-align: center;
  padding: 0.2rem 1rem;
}
.footer-profile {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem; 
      margin-bottom: 1rem;
width: 85%;

}
.btn {
  padding: 0.2rem 1.8rem;
  background-color: var(--color-03);
}
.calendarBlack{
  display: none
}
@media (max-width: 991px) {
.all {
 
    flex-direction: column;
}
.img{
    width: 35%;
    margin-left: 0;
}
.sendCredits {
     margin-left: 0; 
}
.containter-contact {

    margin-bottom: 1.5rem;
    padding: 1rem 0rem 1rem 2rem;
    width: 21.5rem;
}
.title{
  font-size: 1.25em
}
.imgArrow{
    width: 1rem;
} 


.header-credits p, .header-profile p{
font-size: 1.25em
}
.pName, .needCred{
  font-size: 1.05em
}
}
@media (max-width: 1024px) {
 
.all {
 
    flex-direction: column;
}
.img{
   width: 35%;
    margin-left: 0;
}

.sendCredits {
     margin-left: 0; 
}
.containter-contact {

    margin-bottom: 1.5rem;
    padding: 1rem 0rem 1rem 2rem;
    width: 21.5rem;
}
.title{
  font-size: 1.25em
}
 .imgArrow{
    width: 1rem;
} 


.header-credits p, .header-profile p{
font-size: 1.25em
}
.pName, .needCred{
  font-size: 1.05em
}
.sendCredits, .addProfile {
    width: 39%;

}
}
@media (min-width: 1024px) and (max-width: 1199px) {
 .all {
 
    flex-direction: column;
}
.img{
   width: 35%;
    margin-left: 0;
}
.sendCredits {
     margin-left: 0; 
}
.containter-contact {

    margin-bottom: 1.5rem;
    padding: 1rem 0rem 1rem 2rem;
    width: 21.5rem;
}
.title{
  font-size: 1.25em
}
 .imgArrow{
    width: 1rem;
} 


.header-credits p, .header-profile p{
font-size: 1.25em
}
.pName, .needCred{
  font-size: 1.05em
}
.sendCredits, .addProfile {
    width: 39%;

}
}
@media (min-width: 1200px) and (max-width: 1439px) {
.img {
    width: 45%;
  
}
}
@media (min-width: 1600px) {
.img{
    width: 35%;
  
}
}
@media (min-width: 1700px) {
  .img{
    width: 30%;
  
}
.sendCredits, .addProfile {
    width: 38%;
}
}
</style>

<template>
  <div>
    <div class="sidebar">
      <div class="logo_content">
        <!--  <i class="material-icons icon" >arrow_forward</i> -->
        <i @click="onclick()" class="bx bx-chevron-right-circle" id="btn"></i>
      </div>
      <ul class="nav_list">
        <li>
          <RouterLink to="/home">
            <div class="home">
              <span class="links_name">Home</span>
            </div>
          </RouterLink>
          <span class="tooltip">Home</span>
        </li>
        <li>
          <RouterLink to="/contactos">
            <div class="users">
              <span class="links_name">Contactos</span>
            </div>
          </RouterLink>
          <span class="tooltip">Contactos</span>
        </li>
        <li>
          <RouterLink to="/journey">
            <div class="swap">
              <span class="links_name">Work flow</span>
            </div>
          </RouterLink>
          <span class="tooltip">Work flow</span>
        </li>
        <li>
          <RouterLink to="/sms">
            <div class="chat">
              <span class="links_name">SMS</span>
            </div>
          </RouterLink>
          <span class="tooltip">SMS</span>
        </li>
        <li>
          <RouterLink to="/mail">
            <div class="mail">
              <span class="links_name">Mail</span>
            </div>
          </RouterLink>
          <span class="tooltip">Mail</span>
        </li>
        <li>
          <RouterLink to="/voip">
            <div class="dialer">
              <span class="links_name">Discador</span>
            </div>
          </RouterLink>
          <span class="tooltip">Discador</span>
        </li>
        <li>
          <RouterLink to="/alterapay">
            <div class="alteraPay">
              <span class="links_name">AlteraPay</span>
            </div>
          </RouterLink>
          <span class="tooltip">AlteraPay</span>
        </li>
        <li>
          <RouterLink to="/whatsapp">
            <div class="whatsapp">
              <span class="links_name">WhatsApp</span>
            </div>
          </RouterLink>
          <span class="tooltip">WhatsApp</span>
        </li>
        <li>
          <RouterLink to="/alteralytics">
            <div class="alteralytics">
              <span class="links_name">Alteralytics</span>
            </div>
          </RouterLink>
          <span class="tooltip">Alteralytics</span>
        </li>
        <li>
          <RouterLink to="/bias">
            <div class="bias">
              <span class="links_name">BIAS</span>
            </div>
          </RouterLink>
          <span class="tooltip">BIAS</span>
        </li>
         <li>
          <RouterLink to="/url-corta">
            <div class="shortUrl">
              <span class="links_name">Url Corta</span>
            </div>
          </RouterLink>
          <span class="tooltip">Url Corta</span>
        </li>
      </ul>
      <div @click="openModal" class="dOutLog">
        <div class="outLog"></div>
      </div>
      <div class="div-switch">
        <label class="switch">
          <input
            type="checkbox"
            id="checkbox"
            @click="toggleDarkMode"
            v-model="darkMode"
          />
          <span class="sliderSide round"></span>
        </label>
      </div>
    </div>
    <ModalLogout></ModalLogout>
  </div>
</template>
<script>
import ModalLogout from "../modals/ModalLogout";
export default {
  components: {
    ModalLogout,
  },

  data() {
    return {
      darkMode: false,
    };
  },

  methods: {
    onclick() {
      /*  console.log("entro al click"); */
      document.querySelector(".sidebar").classList.toggle("active");
      if (
        document
          .querySelector("#btn")
          .classList.contains("bx-chevron-right-circle")
      ) {
        document
          .querySelector("#btn")
          .classList.replace(
            "bx-chevron-right-circle",
            "bx-chevron-left-circle"
          );
      } else {
        document
          .querySelector("#btn")
          .classList.replace(
            "bx-chevron-left-circle",
            "bx-chevron-right-circle"
          );
      }
    },

    toggleDarkMode() {
      let app = document.getElementById("app");
      let darkMode = localStorage.getItem("darkmode");
      if (!darkMode || darkMode == "0") {
        localStorage.setItem("darkmode", "1");
        app.classList.add("dark");
      } else {
        localStorage.setItem("darkmode", "0");
        app.classList.remove("dark");
      }
    },
    openModal() {
      console.log("hola");
      this.$modal.show("modalLogout");
    },
  },

  created() {
    let darkMode = localStorage.getItem("darkmode");
    if (darkMode == "1") {
      this.darkMode = true;
    } else {
      this.darkMode = false;
    }
  },
};
</script>
<style scoped>
/* .svg1:hover{
content: url(../assets/img/iconOrange/Home.svg);

} */
/* .svg1:hover,.svg1:focus  {
    
} */

/* .sidebar ul li .router-link-exact-active {
  color: var(--color-03);
} */

i {
  cursor: pointer;
}
.home::before {
  content: url(../../assets/img/iconWhite/HomeWhite.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  position: absolute;
  top: 0.25rem;
}
.home:hover::before {
  content: url(../../assets/img/iconOrange/HomeOrange.svg);
  top: 0.25rem;
}
.users::before {
  content: url(../../assets/img/iconWhite/3UserWhite.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  top: 0.45rem;
  position: absolute;
}
.users:hover::before {
  content: url(../../assets/img/iconOrange/3UserOrange.svg);
  top: 0.45rem;
  position: absolute;
}
.swap::before {
  content: url(../../assets/img/iconWhite/SwapWhite.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  top: 0.35rem;
  position: absolute;
}
.swap:hover::before {
  content: url(../../assets/img/iconOrange/SwapOrange.svg);
  top: 0.35rem;
  position: absolute;
}
.chat::before {
  content: url(../../assets/img/iconWhite/ChatWhite.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  top: 0.35rem;
  position: absolute;
}
.chat:hover::before {
  content: url(../../assets/img/iconOrange/ChatOrange.svg);
  top: 0.35rem;
  position: absolute;
}
.mail::before {
  content: url(../../assets/img/iconWhite/MessageWhite.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  top: 0.4rem;
  position: absolute;
}
.mail:hover::before {
  content: url(../../assets/img/iconOrange/MessageOrange.svg);
  top: 0.4rem;
  position: absolute;
}
.dialer::before {
  content: url(../../assets/img/iconWhite/CallingWhite.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  top: 0.4rem;
  position: absolute;
}
.dialer:hover::before {
  content: url(../../assets/img/iconOrange/CallingOrange.svg);
  top: 0.4rem;
  position: absolute;
}
.alteraPay::before {
  content: url(../../assets/img/iconWhite/AlteraWhite.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  top: 0.35rem;
  position: absolute;
}
.alteraPay:hover::before {
  content: url(../../assets/img/iconOrange/AlteraOrange.svg);
  top: 0.35rem;
  position: absolute;
}
.whatsapp::before {
  content: url(../../assets/img/iconWhite/WhatsappWhite.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  top: 0.4rem;
  position: absolute;
}
.whatsapp:hover::before {
  content: url(../../assets/img/iconOrange/WhatsappOrange.svg);
  top: 0.4rem;
  position: absolute;
}
.alteralytics::before {
  content: url(../../assets/img/iconWhite/ChartWhite.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  top: 0.35rem;
  position: absolute;
}
.alteralytics:hover::before {
  content: url(../../assets/img/iconOrange/ChartOrange.svg);
  top: 0.35rem;
  position: absolute;
}
.bias::before {
  content: url(../../assets/img/iconWhite/DocumentWhite.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  top: 0.3rem;
  position: absolute;
}
.bias:hover::before {
  content: url(../../assets/img/iconOrange/DocumentOrange.svg);
  top: 0.3rem;
  position: absolute;
}
.shortUrl::before {
  content: url(../../assets/img/iconWhite/Activity.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.7);
  top: 0.3rem;
  position: absolute;
}
.shortUrl:hover::before {
  content: url(../../assets/img/iconOrange/ActivityOrange.svg);
  top: 0.3rem;
  position: absolute;
}
/* ::selection {
  color: #fff;
  background: #11101d;
} */

.sidebar {
  /*   position: fixed;
  top: 0;
  left: 0; */
  height: 100%;
  width: 75px;
  background: var(--color-03);
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
  border-radius: 15px;
  padding-left: 15px;
  overflow-x: hidden;
}

.sidebar.active {
  width: 200px;
}

/* .sidebar .logo_content .logo {
  color: #fff;
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
} */
/* .sidebar.active .logo_content .logo {
  opacity: 1;
  pointer-events: none;
}
.logo_content .logo i {
  font-size: 28px;
  margin-right: 5px;
}
.logo_content .logo .logo_name {
  font-size: 20px;
  font-weight: 400;
} */
.sidebar #btn {
  position: absolute;
  color: var(--color-01);
  top: 0.5rem;
  left: 50%;
  font-size: 22px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  transform: translateX(-50%);
}
.sidebar.active #btn {
  left: 90%;
}
.sidebar ul {
  margin-top: 3rem;
}
.sidebar ul li {
  position: relative;
  height: 40px;
  width: 100%;
  margin: 0 5px;
  list-style: none;
  line-height: 50px;
  margin: 0.5rem 0;
}

.sidebar ul li .tooltip {
  font-family: var(--font-family-2);
  font-weight: bold;
  color: var(--color-03);
  /*    position: absolute;
  left: 5rem;
  top: 0;
  transform: translate(-50%, -50%); */
  position: fixed;
  transform: translate(48px, -51px);;
  border-radius: 0 6px 6px 0;
  height: 51px;
  width: 90px;
  background: var(--color-01);
  line-height: 47px;
  text-align: start;
  /* box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.2); */
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
  z-index: 999999;

}
.sidebar.active ul li .tooltip {
  display: none;
}
.sidebar ul li:hover .tooltip {
  /*   transition: all 0.5s ease;
  opacity: 1;
  top: 5rem; */
  visibility: visible;
  opacity: 1;
  margin-left: 0.6rem;
  margin-top: 0;
}
.sidebar ul li input {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 12px;
  outline: none;
  border: none;
  background: #1d1b31;
  padding-left: 50px;
  font-size: 18px;
  color: var(--color-01);
}

.sidebar ul li a {
  color: var(--color-01);
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 12px;
  white-space: nowrap;
  /*  transition: all 0.1s ease; */
}
ul li a div {
  width: 100%;
}
.sidebar ul li a:hover {
  background: var(--color-01);
  color: var(--color-03);
  width: 12rem;
}
.sidebar ul li i {
  font-size: 18px;
  font-weight: 400;
  height: 50px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
}
.sidebar .links_name {
  font-family: var(--font-family-2);
  font-size: 1em;
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s ease;
  margin-left: 4rem;
}

.sidebar.active .links_name {
  transition: 0s;
  opacity: 1;
  pointer-events: auto;
}

.dOutLog {
  display: flex;
  justify-content: center;
  /*       height: 8%;
    align-items: flex-end; */
  position: absolute;
  bottom: 3rem;
}
.outLog {
  background-color: var(--color-01);
  position: relative;
  display: inline-block;
  width: 35px;
  height: 19px;
  /* margin-top: 5rem; */
  left: 0.3rem;
  cursor: pointer;
  border-radius: 34px;
}
.outLog::before {
  content: url(../../assets/img/iconOrange/Logout.svg);
  display: flex;
  justify-content: center;
}
/* switch */
.div-switch {
  position: absolute;
  bottom: 1rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 19px;
  /*  margin-top: 5rem; */
  left: 0.3rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderSide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-01);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderSide:before {
  position: absolute;
  content: url(../../assets/img/iconWhite/lunita.svg);
  height: 15px;
  width: 17px;
  left: 1px;
  bottom: 2px;
  background-color: var(--color-03);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 1px 3px rgb(101 101 101 / 33%);
  display: flex;
  align-items: center;
  justify-content: center;
}

input:checked + .sliderSide {
  background-color: var(--color-01);
}

input:checked + .sliderSide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(15px);
  content: url(/img/sun.064882d9.svg);
  height: 15px;
  background-color: #e9e9e9;
  align-items: stretch;
  bottom: 2px;
}

/* Rounded sliders */
.sliderSide.round {
  border-radius: 34px;
}

.sliderSide.round:before {
  border-radius: 50%;
}

@media (max-width: 1023px) {
  /* .sidebar #btn {
    left: 50%;
  } */
  /*   .home::before,
  .users::before,
  .swap::before,
  .chat::before,
  .mail::before,
  .dialer::before,
  .alteraPay::before,
  .whatsapp::before,
  .alteralytics::before,
  .bias::before {
    transform: scale(0.5);
  } */
  /*   .switch {

    margin-top: 1rem;
    
} */
  .sidebar ul li:hover .tooltip {
    /*   transition: all 0.5s ease;
  opacity: 1;
  top: 5rem; */
    visibility: hidden;
  }
  input:checked + .sliderSide[data-v-68d67528]:before {
    height: 14px;
    width: 15px;
    bottom: 2.4px;
  }
}
@media (min-width: 1024px) and (max-width: 1335px) {
  /* .sidebar ul li {
    margin: 0px 0;
  } */

  /*   .sidebar #btn {
    left: 51%;
  } */
  /*   .home::before,
  .users::before,
  .swap::before,
  .chat::before,
  .mail::before,
  .dialer::before,
  .alteraPay::before,
  .whatsapp::before,
  .alteralytics::before,
  .bias::before {
    transform: scale(0.6);
  } */
  input:checked + .sliderSide[data-v-68d67528]:before {
    height: 14px;
    width: 15px;
    bottom: 2.6px;
  }
}
@media (min-width: 1336px) and (max-width: 1439px) {
  /* .sidebar ul li {
    margin: 0px 0;
  } */

  /*   .sidebar #btn {
    left: 51%;
  } */
  /*   .home::before,
  .users::before,
  .swap::before,
  .chat::before,
  .mail::before,
  .dialer::before,
  .alteraPay::before,
  .whatsapp::before,
  .alteralytics::before,
  .bias::before {
    transform: scale(0.6);
  } */

  /*   .switch {

    margin-top: 4.3rem;
    
} */
  input:checked + .sliderSide[data-v-68d67528]:before {
    height: 14px;
    width: 15px;
    bottom: 2.7px;
  }
}
@media (min-width: 1600px) {
  .sidebar #btn {
    top: 1rem;
    /*  left: 65.5%; */
  }
  /* .home::before,
  .users::before,
  .swap::before,
  .chat::before,
  .mail::before,
  .dialer::before,
  .alteraPay::before,
  .whatsapp::before,
  .alteralytics::before,
  .bias::before {
    transform: scale(0.9);
  } */
  .sidebar ul {
    margin-top: 4rem;
  }
  .sidebar ul li {
    margin: 15px 0;
  }

  .switch {
    width: 37px;
    height: 23px;
  }
  .sliderSide:before {
    bottom: 3.5px;
  }
  input:checked + .sliderSide:before {
    transform: translateX(17px);
    bottom: 4px;
  }
  .sliderSide:before {
    height: 17px;
    width: 17px;
  }
}
</style>

<template>
  <div class="container-url">
    <div class="header-url">
      <p class="titleUrl textWhite">Bienvenido a URL corta</p>
    </div>
    <div class="inpUrl">
      <input
        type="text"
        name=""
        id=""
        class="input-url"
        placeholder="Ingrese una url larga para acortarla"
      />
    </div>
    <div class="body-url">
      <p class="titleBody textWhite">
        Personaliza tu URL
      </p>
      <div class="dropInp">
        <label for="checkUrl" class="labelDrop">
          <div class="dropdownUrl">
            <div class="dd-buttonUrl">
              <input
                id="checkUrl"
                type="checkbox"
                name="menu"
                class="checkUrl"
              />
              <p class="titleDropSms textWhite">
                Url
              </p>
              <div class="spanLa">
                <label for="checkUrl" class="arrowD1 "> </label>
              </div>
              <div class="submenuUrl  dd-menuUrl">
                <p class="textSubmenu">Enlace corto</p>
                <p class="textSubmenu">Página inteligente</p>
                <p class="textSubmenu">Enlace de messenger</p>
              </div>
            </div>
          </div>
        </label>
        <div class="inputAlias">
          <input type="text" name="" id="" placeholder="Alias" class="alias" />
        </div>
      </div>
      <div class="btnUrlShort">
        <button>CREAR LA URL</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container-url {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  width: 90%;
  padding: 1rem 2rem;
  height: 100%;
}
.titleUrl {
  font-weight: 800;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}
.titleBody,
.titleUrl {
  font-family: var(--font-family-2);
  color: var(--color-17);
  font-size: 1.563em;
  text-align: start;
}
.titleBody {
  letter-spacing: 3px;
  font-weight: 600;
  margin-top: 2rem;
}
.labelDrop {
  width: 50%;
}
.input-url {
  width: 100%;
  height: 7vh;
  padding-top: 13px;
  padding-bottom: 10px;
}
.alias,
.input-url {
  border: 1px solid var(--color-05);
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  outline: none;
  padding-left: 2rem;
}
.alias {
  width: 100%;
  height: 7vh;
  padding-top: 13px;
  padding-bottom: 10px;
}
.input-url::placeholder,
.alias::placeholder {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  letter-spacing: 0.694286px;
  color: var(--color-11);
}
.dropdownUrl {
  /* display: inline-block; */
  position: relative;
  /*  width: 50%; */
  cursor: pointer;
  margin-bottom: 2rem;
  border: 1px solid var(--color-05);
  box-sizing: border-box;
  border-radius: var(--borderRadius-1);
  height: 7vh;
}
.dd-buttonUrl {
  display: inline-block;
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  padding: 10px 20px 10px 20px;
  /*   background-color: var(--color-01); */

  white-space: nowrap;
  /* width: 100%; */
  font-size: 0.983em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* z-index: 1000; */
  /* margin-top: 2rem; */
  font-family: var(--font-family-2);
  font-weight: 600;
  color: var(--color-17);
}

label.arrowD1::before {
  content: url(../../assets/img/iconLila/arrowLiDown.svg);
  padding-left: 0.5rem;
  cursor: pointer;
}
input:checked ~ div label::before {
  content: url(../../assets/img/iconLila/arrowLiUp.svg);
  cursor: pointer;
}
.submenu {
  /*  border: 2px solid  red; */
}
input:checked ~ div.submenuUrl {
  display: block;
}
.checkUrl,
div.submenuUrl {
  display: none;
}
.dd-menuUrl {
  position: absolute;
  /* border: 1px solid #ccc; */
  /*     border-radius: 0 15px 15px 15px;
 */ /* padding: 0; */
  /* margin: 173px 0 0 0; */
  /* box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%); */

  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  background-color: var(--color-01);
  /* list-style-type: none; */
  /*     width: 140%;
 */ /* height: 8.5rem; */
  z-index: 10;
  /*     top: 2.2rem;
 */
  top: 3.1rem;
  width: 100%;
  border-radius: 0 0 15px 15px;
  height: auto;
  padding: 2rem 0.5rem;
  right: 0vw;
}
.textSubmenu {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  letter-spacing: 0.694286px;
  color: var(--text-02);
  text-align: start;
}
.dropInp {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
}
.inputAlias {
  width: 48%;
}
.btnUrlShort button {
  width: 100%;
  background: var(--color-05);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--color-01);
  height: 7vh;
  border: none;
}
@media (max-width: 1200px) {
  .titleUrl,
  .titleBody {
    font-size: 1.25em;
  }
  .alias,
  .input-url {
    padding-left: 0.5rem;
  }
  .input-url::placeholder,
  .alias::placeholder,
  .btnUrlShort button {
    font-size: 1em;
  }
  .container-url {
    padding: 1rem 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .input-url::placeholder,
  .alias::placeholder,
  .btnUrlShort button {
    font-size: 1em;
  }
}
</style>

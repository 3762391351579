<template>
  <div
    class="container-login"
    :style="{ backgroundImage: 'url(' + backImages + ')' }"
  >
    <div class="loginForm">
      <LoginForm></LoginForm>
    </div>
    <div class="div-switch">
      <label class="switch">
        <input
          @click="toggleDarkMode"
          type="checkbox"
          id="checkbox"
          v-model="darkMode"
        />
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</template>
<script>
import LoginForm from "../components/panels/LoginForm";
import back01 from "../assets/img/backLogin/back01.png";
import back02 from "../assets/img/backLogin/back02.png";
import back03 from "../assets/img/backLogin/back03.png";
import back04 from "../assets/img/backLogin/back04.png";
import back05 from "../assets/img/backLogin/back05.png";
import backDark from "../assets/img/backLogin/backDark.png";

export default {
  components: {
    LoginForm,
  },
  data() {
    return {
      backImages: back02,
      darkMode: false,
    };
  },
  created() {
    let darkMode = localStorage.getItem("darkmode");
    if (darkMode == "1") {
      this.darkMode = true;
      this.backImages = backDark;
    } else {
      this.darkMode = false;
      this.changeBackImage();
    }
  },

  methods: {
    changeBackImage() {
      let date = new Date();
      let hour = date.getHours();
      /* console.log(hour); */

      /*  hour =10 ; */
      switch (true) {
        case hour > 0 && hour < 8:
          this.backImages = back05;
          break;
        case hour > 7 && hour < 10:
          this.backImages = back01;
          break;
        case hour > 9 && hour < 13:
          this.backImages = back02;
          break;
        case hour > 12 && hour < 16:
          this.backImages = back03;
          break;
        case hour > 15 && hour < 19:
          this.backImages = back04;
          break;
        case hour > 19 && hour <= 24:
          this.backImages = back05;
          break;
        default:
          this.backImages;
      }

      /* if (hour >= 8 && hour <= 9) {
        this.backImages = back01;
      }
      else if (hour >= 10 && hour <= 12) {
        this.backImages = back02;
      }
      else if  (hour >= 13 && hour <= 15) {
        this.backImages = back03;
      }
     else if  (hour >= 16 && hour <= 18) {
        this.backImages = back04;
      }
      else if (hour >= 19 ) {
        this.backImages = back05
      }
      else if  (hour >= 1 && hour <= 7) {
        this.backImages = back05
      }  */
    },

    toggleDarkMode() {
      let app = document.getElementById("app");
      let darkMode = localStorage.getItem("darkmode");
      if (!darkMode || darkMode == "0") {
        localStorage.setItem("darkmode", "1");
        app.classList.add("dark");
        this.backImages = backDark;
      } else {
        localStorage.setItem("darkmode", "0");
        app.classList.remove("dark");
        this.changeBackImage();
      }
    },
  },
};
</script>
<style scoped>
.container-login {
  /* 
  background-image: 'this.backImages'; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /*   background-attachment: fixed; */
  min-height: 100vh;
  /* border: 2px solid rgba(255, 0, 0, 0);*/
}
.loginForm {
  display: flex;
  justify-content: center;
  /*   align-items: center; */
  /*   height: auto; */
  /*   border: 2px solid rgb(56, 5, 150); */
  padding-top: 12rem;
}
/* switch */
.div-switch {
  /*   position: absolute;
  bottom: 1rem; */
  /*   border: 2px solid rgb(37, 218, 61); */
  margin-top: 8rem;
  padding-bottom: 3rem;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  /*  margin-top: 5rem; */
  left: 5rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(253, 253, 255, 0.5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: url(../assets/img/iconWhite/lunita.svg);
  height: 21px;
  width: 21px;
  left: 6px;
  bottom: 5px;
  background-color: var(--color-03);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 1px 3px rgb(101 101 101 / 33%);
  display: flex;
  align-items: center;
  justify-content: center;
}

input:checked + .slider {
  background-color: var(--color-03);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  content: url(../assets/img/iconOrange/sun.svg);
  background: #e9e9e9;
  padding-top: 0.2rem;
  align-items: flex-end;
  height: 18.5px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<template>
    <div class="dBtns">
              <div class="dd ">
                <div class="dd-a dd-aOneTwo"><span>Grupos</span></div>
                <input class="dd-input" type="checkbox" />
                <div class="dd-c dd-cOne">
                  <ul>
                    <li>
                      <a class="a" href="#"><span>Grupo afp</span></a>
                    </li>
                    <li>
                      <a class="a" href="#"><span>Nombres 2</span></a>
                    </li>
                    <li>
                      <a class="a" href="#"><span>Grupo 2</span></a>
                    </li>
                    <li>
                      <a class="a" href="#"><span>AFP Modelo</span></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="dd ">
                <div class="dd-a dd-aOneTwo"><span>Manual</span></div>
                <input class="dd-input" type="checkbox" />
                <div class="dd-c dd-cTwo">
                  <div class="header-ddC">
                    <p class="pTitle titleDark">Seleccionar Contactos</p>
                    <button class="btn-exam" disabled="disabled">
                      Examinar
                    </button>
                  </div>
                  <div class="d-inputSelect">
                    <input
                      class="nameSendOne inputSelect "
                      type="text"
                      placeholder="Seleccionar contactos"
                    />
                  </div>
                  <div class="header-ddC">
                    <p class="pTitle titleDark">Cargar contactos</p>
                    <button class="btn-exam" disabled="disabled">
                      Examinar
                    </button>
                  </div>
                </div>
              </div>
              <div class="dd">
                <div class="dd-a ddBlack"><span>Lista de contacto</span></div>
                <input class="dd-input" type="checkbox" />
                <div class="dd-c dd-cOne">
                  <ul>
                    <li>
                      <a class="a" href="#"><span>Lista</span></a>
                    </li>
                    <li>
                      <a class="a" href="#"><span>nombres 2</span></a>
                    </li>
                    <li>
                      <a class="a" href="#"><span>Lista 8</span></a>
                    </li>
                    <li>
                      <a class="a" href="#"><span>Lista prueba</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.dBtns {
  display: flex;
  justify-content: space-between;
  margin-top:0.5rem;
}
 .dd {
  z-index: 1;
  position: relative;
  display: inline-block;
} 
.dd-a {
  /*  padding: 10px; */
  padding: 0.3rem;
  background: var(--color-01);
  position: relative;
  border-radius: var(--borderRadius-1);
  border: 1px solid var(--color-05);
  width: 9.5rem;
}

.dd .dd-input {
  top: 0;
  opacity: 0;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}
.dd input:hover {
  cursor: pointer;
}

.dd input:checked:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
}
.dd input:checked ~ .dd-c {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}
.dd-a span {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 0.938em;
  color: var(--color-05);
}
.dd-c {
  display: block;
  position: absolute;
  background: var(--color-01);
  height: auto;
  transform: scaleY(0);
  transform-origin: top left;
  transition-duration: 0.2s;
  -webkit-transform: scaleY(0);
  -webkit-transform-origin: top left;
  -webkit-transition-duration: 0.2s;
  width: 100%;
  /* border: 2px solid black; */
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  border-radius: 0 0 15px 15px;
}
.dd-c ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

 .a {
  display: block;
  position: relative;
  text-decoration: none;
  padding-bottom: 0.3rem;
  background: var(--color-01);
  color: var(--text-02);
  font-size: 0.8em;
}
 
.dd-cTwo {
  width: 328%;
  left: -173px;
  padding: 0.5rem 1.5rem 1rem;
}
.header-ddC{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputSelect {
  width: 50%;
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  margin-bottom: 1.5rem;
}
.d-inputSelect {
  display: flex;
}
.dd-cOne {
  padding: 0.5rem 0 0.6rem;
}
.btn-exam {
  width: 8vw;
  background-color: var(--color-01);
  border: 1px solid var(--color-05);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 0.75em;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--color-05);
   padding: 0.2rem 0 ;
  cursor: pointer;
}
.pTitle {
  font-family: var(--font-family-3);
  font-size: 1.375em;
  color: var(--text-01);
  letter-spacing: 0.694286px;
}

.nameSendOne {
  border: none;
  border-bottom: 2px solid var(--color-05);
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  outline: none;
}
.dd-c {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.694286px;
  color: var(--text-02);
}
.btn-exam {
  width: 8vw;
  background-color: var(--color-01);
  border: 1px solid var(--color-05);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 0.75em;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--color-05);
  /* padding: 0.7rem 2.5rem; */
  cursor: pointer;
}
@media (max-width: 991px) {
      .dd-cTwo {
    width: 365%;
    left: -202px;
  }
   .btn-exam {
    width: 13vw;
  }
}
@media (min-width: 992px) and (max-width: 1199px){
      .dd-cTwo {
        left: -263px;
    width: 446%;
  }
  .btn-exam {
    width: 11vw;
  }
}
@media (min-width: 1200px) and (max-width: 1335px){
     .dd-cTwo {
    width: 308%;
    left: -143px;
  }
}
@media (min-width: 1336px) and (max-width: 1439px){
     .dd-cTwo {
   width: 307%;
    left: -158px;
}
  
}
@media (min-width: 1600px) {
  .dd-cTwo {
    width: 343%;
    left: -185px;
  }

}
@media (min-width: 1700px) {
  .dd-cTwo {
    width: 364%;
    left: -201px;
  }
 
}
@media (min-width: 1800px) {
  .dd-cTwo {
    width: 388%;
    left: -218px;
  }

}
@media (min-width: 1880px) {
  .dd-cTwo {
    width: 408%;
    left: -233px;
  }
 
}
</style>

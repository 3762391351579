<template>
  <div class="container-table container-dark">
    <SummaryTable
      btnActive="btn-active"
      data1="59030"
      data2="97.2"
      data3="1"
      data4="1"
    ></SummaryTable>
    <datatable
      title=""
      :columns="tableColumnsShipSms"
      :rows="tableRowsShipSms"
      :sortable="false"
      class="table1 "
    >
      <th class="th" slot="thead-tr">
        <i class="material-icons icon">more_vert</i>
      </th>
      <template slot="tbody-tr">
        <td class="td">
          <label class="dropdownSms">
            <div class="dd-buttonSms">
              <i v-on:click="addClass" class="material-icons iconSms"
                >more_vert</i
              >
            </div>
            <input type="checkbox" class="dd-inputSms" id="test" />

            <div v-on:click="deleteClass" id="minumenu" class="dd-menuSms menuSmsDark ">
              <div class="parr div-bor">Ver Reporte</div>
              <div class="parr div-bor">Reenviar</div>
              <div class="parr">Descargar</div>
            </div>
          </label>
        </td>
      </template>
    </datatable>
  </div>
</template>
<script>
import DataTable from "vue-materialize-datatable";
import SummaryTable from "../common/SummaryTable";
let idElement;
export default {
  components: {
    datatable: DataTable,
    SummaryTable,
  },
  data() {
    return {
      tableColumnsShipSms: [
        {
          label: "ID",
          field: "id",
          numeric: false,
          html: false,
        },
        {
          label: "Fecha envío",
          field: "fechaEnvio",
          numeric: false,
          html: false,
        },
        {
          label: "Nombre",
          field: "nombre",
          numeric: false,
          html: false,
        },
        {
          label: "Mensaje",
          field: "mensaje",
          numeric: false,
          html: false,
        },
        {
          label: "Estado",
          field: "estado",
          numeric: false,
          html: false,
        },
        {
          label: "Créditos",
          field: "creditos",
          numeric: false,
          html: false,
        },
      ],
      tableRowsShipSms: [
        {
          id: "29247177",
          fechaEnvio: "20/03/22 11:22:59",
          nombre: "Nombre cualquier cosa",
          mensaje: "test kajs",
          estado: "Terminado",
          creditos: "2",
        },
        {
          id: "2924717",
          fechaEnvio: "01/03/22 11:22:59",
          nombre:
            "Aunque dijimos que no emplearíamos JavaScript para este experimento o tutorial de igual manera vamos a explicar un extra en la cual podemos ",
          mensaje:
            "a cual recibe como parámetro una cantidad numérica la cual define la cantidad de pixeles que vamos a desplazarnos; es decir, para movernos 200 píxeles:",
          estado: "Terminado",
          creditos: "2",
        },
        {
          id: "2924717",
          fechaEnvio: "01/03/22 11:22:59",
          nombre:
            "Aunque dijimos que no emplearíamos JavaScript para este experimento o tutorial de igual manera vamos a explicar un extra en la cual podemos ",
          mensaje:
            "a cual recibe como parámetro una cantidad numérica la cual define la cantidad de pixeles que vamos a desplazarnos; es decir, para movernos 200 píxeles:",
          estado: "Terminado",
          creditos: "250",
        },
      ],
    };
  },
  methods: {
    addClass(event) {
      // console.log(event.target);
      event.target.classList.toggle("activeSmDark");
      // this.isAddClass = true;
      idElement = event.target;
    },
    deleteClass() {
      idElement.classList.remove("activeSmDark");
      //  this.isAddClass = false;
    },
  },
};
</script>
<style scoped>
.container-table {
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  margin-top: 3rem;
  /*   padding: 2.5rem; */
}
.table1 {
  margin: 0 2.5rem;
  padding-bottom: 2.5rem !important;
}

.icon {
  color: var(--color-01);
}
/* .icon2 {
  text-align: center;
} */
.th {
  width: 4%;
}
.td {
  /* padding-right: none !important */
}
.btn {
  border: none;
  background-color: transparent;
  color: var(--color-15);
  cursor: pointer;
}
/* .btn:active{
	background-color:none
} */
/* Dropdown */

.iconSms {
  /* text-align: center; */
  color: var(--color-15);
}
.activeSmDark {
  z-index: 1000;
  color: var(--color-01);
}
.parr {
  font-family: var(--font-family-2);
  color: var(--text-01);
  letter-spacing: 3px;
  font-size: 0.938em;
  font-weight: 600;
  padding: 1rem 0 0.5rem 1rem;
  /*   line-height: 32px; */
  text-align: start;
}
.div-bor {
  border-bottom: 2px solid var(--color-01);
}
.dropdownSms {
  display: inline-block;
  position: relative;
  /* width: 100%; */
}

.dd-buttonSms {
  display: inline-block;
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  /* padding: 12px 30px 12px 20px; */
  /* background-color: var(--color-01); */
  cursor: pointer;
  /* white-space: nowrap; */
  /* width: 95%; */
  /* font-size: 1.06em; */
  display: flex;
  z-index: 1000;
  /* margin-top: 2rem; */
  /* font-family: var(--font-family-2); */
  /* font-weight: 600;*/
}
.dd-buttonSms::after {
  z-index: 1000;
}

.dd-buttonSms:hover {
  background-color: var(--color-01);
}

.dd-inputSms {
  display: none;
}

.dd-menuSms {
  position: absolute;
  /* top: 100%; */
  /*  border: 1px solid #ccc; */
  border-radius: var(--borderRadius-1);
  /* padding: 0; */
  margin: -33px 0 0 -129px;
  /*  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1); */
  background-color: var(--color-06);
  /* list-style-type: none; */
  width: 10rem;
  /* height: 8.5rem; */
  z-index: 1;
}

.dd-inputSms + .dd-menuSms {
  display: none;
}

.dd-inputSms:checked + .dd-menuSms {
  display: block;
}
@media (max-width: 991px) {
  /* .container-table{
  padding: 1rem
} */
  .table1 {
    margin: 0 0.5rem;
  }
  .container-table {
    overflow-y: hidden;
    overflow-x: auto;
  }
  /* .th {
  width: 5%;
} */
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* .container-table{
  padding: 1rem
} */
  .table1 {
    margin: 0 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
}
</style>

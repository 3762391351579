<template>
  <div class="search">
    <input type="text" class="searchTerm" placeholder="Buscar" />
    <button type="submit" class="searchButton">
     <span class="span-search"></span>
    </button>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.search {
  width: 90%;
  /*  position: relative; */
  display: flex;
  /* padding: 2rem; */
  /*   */
  justify-content: flex-start;
  align-items: center;

  box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
  border-radius: 15px;
}

.searchTerm {
  width: 100%;
  height: 36px;
  border: none;
  border-right: none;
  padding: 1.5rem;

  border-radius: 20px 0 0 20px;
  outline: none;
  background-color: var(--color-01);
  /* border:2px solid red */
}

.searchTerm:focus {
  color: black;
}

.searchButton {
  width: 40px;
  height: 3rem;
  border: none;
  background-color: var(--color-01);
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  font-size: 20px;
  /*  border:2px solid red */
  outline: none;
  display: flex;
    align-items: center;
}
.icon {
  color: var(--color-19);
  outline: none;
}
::placeholder {
  text-align: end;
}
.span-search{
  content: url(../../assets/img/iconGrey/search.svg)
}
@media  (max-width: 991px) {
  .search {
    width: 100%;
  }
}

@media (min-width: 1800px) {
  .search {
    width: 75%;
  }
}
</style>

<template>
  <div class="container-metrics container-dark">
    <div class="all-flex">
      <div class="all">
        <img class="img" :src="img1" alt="" />
        <div class="info">
          <div class="d-number">
            <p class="pNumber titleDark">{{ number1 }}</p>
          </div>
          <div class="d-info">
            <p class="pInfo titleDark">{{ info1 }}</p>
          </div>
        </div>
      </div>
      <div class="all">
        <img class="img" :src="img2" alt="" />
        <div class="info">
          <div>
            <p class="pNumber titleDark">{{ number2 }}</p>
          </div>
          <div>
            <p class="pInfo titleDark">{{ info2 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="all-flex">
      <div class="all">
        <img class="img circle" :src="img3" alt="" />
        <div class="info">
          <div>
            <p class="pNumber titleDark">{{ number3 }}</p>
          </div>
          <div>
            <p class="pInfo titleDark">{{ info3 }}</p>
          </div>
        </div>
      </div>
      <div class="all">
        <img class="img" :src="img4" alt="" />
        <div class="info">
          <div>
            <p class="pNumber titleDark">{{ number4 }}</p>
          </div>
          <div>
            <p class="pInfo titleDark">{{ info4 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "number1",
    "number2",
    "number3",
    "number4",
    "info1",
    "info2",
    "info3",
    "info4",
    "img1",
    "img2",
    "img3",
    "img4",
  ],
};
</script>
<style scoped>
.container-metrics {
  background-color: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  display: flex;
  align-items: center;
  /*   padding: 0 0 0 2.5rem; */
  margin-top: 3rem;
  justify-content: space-evenly;
  height: 61%;
}

.all {
  /*  border: 2px solid rgb(109, 75, 231); */
  width: 8.5vw;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin: 1rem 0 1rem 0;
}
.all-flex {
  display: flex;
  justify-content: space-around;
  width: 45%;
  align-items: center;
}
/* .title {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--text-01);
} */

.info {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  height: 4rem;
}
p {
  text-align: start;
}
.pNumber,
.pInfo {
  font-family: var(--font-family-3);
  font-weight: 500;
  color: rgba(64, 64, 64, 0.9);
}
.pNumber {
  font-size: 1.875em;
  letter-spacing: 0.694286px;
}
.pInfo {
  font-size: 0.625em;
  /* margin-top: -11px; */
}
/* img {
  width: 3.1em;
  height: 3.1em;
} */
@media (max-width: 992px) {
  .container-metrics {
    flex-direction: column;
    padding: 0;
    margin-top: 2rem;
        height: 83.5%;
  }
  .all {
    /* width: 100% */;
    margin: 0;
  }
/*   .img {
    width: 18%;
  } */
  .pNumber {
    font-size: 1.8em;
  }
  .dTitle {
    margin-top: 1.5rem;
    margin-right: 0;
  }
  /* .title{
   font-size: 1.25em
 } */
  /* .all-flex {
    margin-left: 3.9rem;
    margin-right: auto;
  } */
  .all-flex {
 
    width: 100%;
  
}

}
@media (min-width: 993px) and (max-width: 1199px) {
  /* .title {
    font-size: 1.25em;
  }
  */
  .pNumber {
    font-size: 1.8em;
  }
  .pInfo {
    font-size: 0.65em;
  }
  .info {
    /*  display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap; */
    margin-left: 0.5rem;
  }
 /*  .container-metrics {
    padding: 0 0 0 2rem;
  } */

  /*  .img {
    width: 40%;
  } */
  .all-flex {
    
    width: 50%;
    
}
.img {
    width: 2rem;
}
.info {

    margin-left: 1rem;
    
}
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .info {

    margin-left: 1rem;
    
}
}
</style>

<template>
  <modal
    width="65%"
    height="auto"
    class="campaign-summary"
    name="campaign-summary"
    :scrollable="true"
  >
    <div class="header-modal">
      <p class="title-header textWhite">Resumen -{{ nameCamp }}</p>
      <img
        @click="close"
        src="../../assets/icons/others/x.svg"
        alt=""
        class="close"
      />
    </div>

    <div class="modal-body">
      <div class="info-container row">
        <!-- Box 1 -->
        <div class="col-sm-12 col-md-4">
            <div class="text-legen legen-sup">
            <p class="textWhite">Aperturas</p>
          </div>
          <div class="box-chart">
            <apexchart
              type="bar"
              height="200"
              :options="opens.chartOptions"
              :series="opens.series"
            ></apexchart>
          </div>
          <div class="text-legen legen-bot">
            <p class="textWhite">Aperturas</p>
          </div>
        </div>

        <!-- Box 2 -->
        <div class="col-sm-12 col-md-4 box02">
                <div class="text-legen legen-sup">
            <p class="textWhite">Clicks</p>
          </div>
          <div class="box-chart">
           
            <apexchart
              type="bar"
              height="200"
              :options="clicks.chartOptions"
              :series="clicks.series"
            ></apexchart>
          </div>
          <div class="text-legen legen-bot">
            <p class="textWhite">Clicks</p>
          </div>
        </div>

        <!-- Box 3 -->
        <div class="col-sm-12 col-md-4">
             <div class="text-legen legen-sup legen03">
            <p class="textWhite">Dispositivos</p>
          </div>
          <div class="box-chart">

            <apexchart
              type="donut"
              width="250"
              :options="devices.chartOptions"
              :series="devices.series"
            ></apexchart>
          </div>
          <div class="text-legen legen-bot">
            <p class="textWhite">Dispositivos</p>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  data() {
    return {
      nameCamp: "Prueba01",
      opens: {
        series: [
          { name: "Aperturas totales", data: [1366] },
          { name: "Aperturas únicas", data: [560] },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#213b92", "#00cccc"],
          stroke: {
            show: true,
            width: 5,
            colors: ["#fff"],
          },
          xaxis: {
            categories: ["Aperturas totales", "Aperturas únicas"],
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: ["#404040"],
              },
            },
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          legend: {
            show: false,
          },
        },
      },
      clicks: {
        series: [
          { name: "Click totales", data: [1366] },
          { name: "Click únicos", data: [560] },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#213b92", "#00cccc"],
          stroke: {
            show: true,
            width: 5,
            colors: ["#fff"],
          },
          xaxis: {
            categories: ["Click totales", "Click únicos"],
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              style: {
                 colors: ["#404040"],
              },
            },
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          legend: {
            show: false,
          },
        },
      },

      devices: {
        series: [20, 50, 30],
        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          labels: ["iOS", "Android", "Windows"],
          colors: ["#475789", "#00cccc", "#6600cc"],
          legend: {
            position: "top",
            labels: {
              colors:["#404040"],
            },
          },
          stroke: {
            colors: ["#fff"],
          },
        },
      },
    };
  },
  methods: {
    close() {
      this.$modal.hide("campaign-summary");
    },
  },
};
</script>
<style scoped>

.header-modal {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
}
.modal-body {
  padding: 1rem 2rem;

}
.title-header {
  font-family: var(--font-family-3);
  font-weight: normal;
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  color: var(--text-01);
}
.close {
  cursor: pointer;
}
.text-legen p {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 0.875em;
  text-align: center;
  letter-spacing: 0.14px;
  color: #c4c4c4;
}
.box02 {
  border-left: 1px solid var(--color-05);
  border-right: 1px solid var(--color-05);
  padding-left:1.5rem
}
.info-container.row {
    padding-left: 3rem;
}
.col-sm-4.col-md-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.legen-sup{
    display:none
}
.campaign-summary .apexcharts-text tspan  {
  
  color: var(--colorD-02) !important;
}
@media (max-width: 1024px){
.info-container .row{
    flex-direction: column
}
.box02{
     border-left:none ;
    border-right:none; 
     padding-left: 0; 
}
.col-sm-12.col-md-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal-body {
    padding: 1rem 0;
}
.info-container.row {
    padding-left: 0;
}
.legen-bot{
    display: none
}
.legen-sup{
    display: initial
}
.legen03{
    margin-bottom: 2rem
}
}
@media (min-width: 1024px) and (max-width: 1439px){
   .legen-bot{
    display: initial
}
.legen-sup{
    display: none
} 
.info-container.row[data-v-b1abb526] {
    padding-left: 3rem;
}
}

</style>

<template>
  <modal
    class="modalInfoCM"
    name="modalInfoCM"
    :width="1024"
    :height="650"
    :adaptive="true"
  >
    <div class="container-imgBtn">
      <img
        class="imgX"
        src="../../assets/icons/others/x.svg"
        @click="$modal.hide('modalInfoCM')"
      />
    </div>
    <div class="container-modal">
      <div class="parrLeft">
        <p>Menú principal</p>
        <p>
          . Inicio: dashboard con el estado general de la cuenta, para obtener
          un reporte de las campañas ejecutadas en la plataforma tiene un breve
          resumen, para mas detalles debe dirigirse a BIAS
        </p>
        <p>
          . Nueva Campaña: esta opción permita la creación una nueva campaña con
          su descripción, lista de contactos, archivos adjuntos, selección de
          plantillas, pruebas, programación de envíos y envíos inmediatos.
        </p>
        <p>
          . Plantillas: Listado de plantillas que son cargados o construidos en
          la plataforma de mail.
        </p>
        <p>
          . Adjuntos: Esta opción permite selecciona un archivo de carga (JPG,
          GIF, XLS, XLSX, PNG, TXT o PDF) que puede ser adjunto a los mails de
          una campaña.
        </p>
        <p>
          . Eventos: esta opción permite crear Eventos específicos de clientes y
          que el destinatario del mail debe confirmar su asistencia.
        </p>
        <p>
          Las CAMPAÑAS tienen una sola instancia de ejecución una vez creada. Si
          se requiere ejecutar nuevamente una campaña el sistema crea una nueva
          campaña con los mismos datos de la campaña de origen. Es recomendable
          para las campañas elegir nombres asociados a clientes, por ejemplo,
          más alguna secuencia de números de tal forma de mantener un control de
          campañas creadas.
        </p>
      </div>

      <div class="parrRight">
        <p>
          Flujo básico de envío masivo de Mail Para los envíos masivos de mail
          se debe seguir el siguiente flujo de trabajo de forma simple para este
          caso en las opciones de menú desde la pantalla principal de acceso.
          Dashboard-Mail -> Nueva campaña -> rellenar datos de campaña ->
          verificar resumen de envío -> enviar / guardar campaña
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    /*   itemToShow: function () {
        return this.item
      }, */
  },

  methods: {
    /*   beforeOpen (event) {
        this.item = event.params.item;
      } */
  },
};
</script>

<style scoped>
.container-imgBtn {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 2rem 0 0;
}
.imgX {
  cursor: pointer;
}
.container-modal {
  display: flex;
  padding: 0 2rem;
  justify-content: space-around;
}
.parrLeft,
.parrRight {
  width: 45%;
}
.parrLeft p,
.parrRight p {
  text-align: start;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */

  letter-spacing: 0.694286px;

  color: var(--text-02);
}
</style>

<template>
  <div class="container-impCont">
    <div class="title-header">
      <p>Importar Contactos</p>
    </div>
    <div class="container-body">
      <p class="subtitleContact">Selección de campos personalizados</p>
      <div class="container-radiocheck">
        <div class="inpSpan">
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
              @change="changeDisplay('none')"
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkboxContact"
            ></span>
          </label>
          <span class="span-radio">Ignorar la primera fila</span>
        </div>
        <div class="inpSpan">
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
              @click="checkOne"
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkboxContact"
            ></span>
          </label>
          <span class="span-radio">Lleva Pdf</span>
        </div>
        <div class="inpSpan">
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkboxContact"
            ></span>
          </label>
          <span class="span-radio">Quitar duplicados </span>
        </div>
        <div class="inpSpan">
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
              @click="checkTwo"
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkboxContact"
            ></span>
          </label>
          <span class="span-radio">Añadir URL corta</span>
        </div>
      </div>
      <div class="containing-folder" v-if="divOne">
        <p>Carpeta contenedora de pdf (SFTP)</p>
        <input
          class="nameFolder"
          type="text"
          placeholder="Nombre de la carpeta"
        />

        <div class="switchSpan">
          <label class="switch">
            <input type="checkbox" id="checkbox" />
            <span class="sliderCard round"></span>
          </label>
          <span class="textSwitch">Añadir URL corta</span>
        </div>
      </div>
      <div class="container-url" v-if="divTwo">
        <p class="url">URL</p>
        <input class="inputUrl" type="text" placeholder="Pega tu enlace aquí" />
        <div class="container-two">
          <div class="inpSpanTwo">
            <label class="custom-radio-checkbox">
              <input
                class="custom-radio-checkbox__input"
                type="checkbox"
                name=""
                value=""
              />
              <span
                class="custom-radio-checkbox__show custom-radio-checkbox__show--checkboxContact"
              ></span>
            </label>
            <span class="url">Crear URL desde archivo</span>
          </div>
          <div class="inpSpanTwo">
            <label class="custom-radio-checkbox">
              <input
                class="custom-radio-checkbox__input"
                type="checkbox"
                name=""
                value=""
              />
              <span
                class="custom-radio-checkbox__show custom-radio-checkbox__show--checkboxContact"
              ></span>
            </label>
            <span class="url">Incluir (https://)</span>
          </div>
          <div class="inpSpanTwo">
            <label class="custom-radio-checkbox">
              <input
                class="custom-radio-checkbox__input"
                type="checkbox"
                name=""
                value=""
              />
              <span
                class="custom-radio-checkbox__show custom-radio-checkbox__show--checkboxContact"
              ></span>
            </label>
            <span class="url">Crear URL para cada contacto</span>
          </div>
          <div class="inpSpanTwo">
            <label class="custom-radio-checkbox">
              <input
                class="custom-radio-checkbox__input"
                type="checkbox"
                name=""
                value=""
              />
              <span
                class="custom-radio-checkbox__show custom-radio-checkbox__show--checkboxContact"
              ></span>
            </label>
            <span class="url">Incluir datos</span>
          </div>
        </div>
      </div>
      <div class="tableContacts">
       
        <TableImportContacts></TableImportContacts>
      </div>
      <div class="btnImpCont">
        <button
          @click="$emit('change-componentCont', 'ImportContacts')"
          class="btn-importContacts btn-fooOne"
        >
          Atrás
        </button>
        <button @click="onClick01" class="btn-importContacts">Importar</button>
      </div>
    </div>
    <ModalChargeContacts></ModalChargeContacts>
  </div>
</template>
<script>
import TableImportContacts from "../tables/TableImportContacts";
import ModalChargeContacts from "../modals/ModalChargeContacs";

export default {
  components: {
    TableImportContacts,
    ModalChargeContacts,
  },
  data() {
    return {
      divOne: false,
      divTwo: false,
    };
  },
  methods: {
    checkOne() {
      if (this.divOne == false) {
        this.divOne = true;
      } else {
        this.divOne = false;
      }
    },
    checkTwo() {
      if (this.divTwo == false) {
        this.divTwo = true;
      } else {
        this.divTwo = false;
      }
    },
    changeDisplay(display) {
      this.$root.$emit("changeDisplay", display);
    },
    onClick01() {
      this.$modal.show("modalChargeContacts");
    },
    /*  prueba6(){
      console.log('onChangeComponentCont')
      this.$emit('onChangeComponentCont', 'ImportContacts')
    } */
  },
};
</script>
<style scoped>
.container-impCont {
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  margin-top: 3rem;
}
.title-header {
  display: flex;
  padding: 2rem 0 0 2.5rem;
}
.title-header p {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--text-01);
}

.container-radiocheck {
  display: flex;
  width: 80%;
  justify-content: space-between;
}
.container-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2.5rem;
  margin-top: 3rem;
}
.inpSpan,
.inpSpanTwo {
  display: flex;
  align-items: center;
}
.inpSpanTwo {
  padding-right: 4rem;
}
.span-radio,
.containing-folder p,
.url {
  font-family: Helvetica;
  font-size: 0.938em;
  letter-spacing: 0.694286px;
  color: var(--text-02);
}
.span-radio {
  margin-left: 0.5rem;
}
/* checkbox */
.custom-radio-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: arial;
}

/* Modificador para dar la imagen de checkbox */
/* .custom-radio-checkbox__show--checkbox1 {
  background-image: url(../../assets/img/uncheckRadioLila.svg);
} */
/* .custom-radio-checkbox__show--checkbox2{
   background-image: url(../../assets/img/uncheckB.png);
}
 */
.custom-radio-checkbox__show--checkboxContact {
  background-image: url(../../assets/img/uncheckRadioLila.svg);
}

/* Oculta input original */
.custom-radio-checkbox > .custom-radio-checkbox__input {
  display: none;
}

/* Radio personalizado usando <span> */
.custom-radio-checkbox > .custom-radio-checkbox__show {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
  background-size: cover;
}
/* Cambia el checkbox personalizado cuando es pulsado */
.custom-radio-checkbox
  > .custom-radio-checkbox__input:checked
  + .custom-radio-checkbox__show--checkboxContact {
  background-image: url(../../assets/img/checkRadioLila.svg);
}

.containing-folder,
.container-url {
  /*  border: 2px solid black; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 7rem;
  justify-content: space-around;
  margin-top: 1.5rem;
  width: 100%;
}
/* switch */

.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 23px;
  /*  margin-top: 1rem; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderCard {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-18);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderCard:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 1px;
  bottom: 1px;
  background-color: var(--color-01);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 1px 3px rgb(101 101 101 / 33%);
}

input:checked + .sliderCard {
  background-color: var(--color-11);
}

input:checked + .sliderCard:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(14px);
}

/* Rounded sliders */
.sliderCard.round {
  border-radius: 34px;
}

.sliderCard.round:before {
  border-radius: 50%;
}
.nameFolder::placeholder,
.textSwitch {
  font-size: 0.938em;
  letter-spacing: 0.694286px;
}
.nameFolder::placeholder {
  font-family: var(--font-family-3);
  color: var(--color-05);
}
.textSwitch {
  font-family: var(--font-family-2);
  color: var(--text-01);
  margin-left: 1rem
}
.switchSpan {
  display: flex;
  align-items: center;
}
.nameFolder,
.inputUrl {
  border: 1px solid var(--color-11);
  border-radius: 15px;
  width: 45%;
  padding: 0.5rem 0 0.5rem 1.5rem;
}
.tableContacts {
  margin-top: 1.5rem;
}
.container-two {
  display: flex;
  width: 85%;
  justify-content: space-between;
}
/* .container-url {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
} */

.subtitleContact {
  font-family: var(--font-family-3);
  font-weight: 600;
  letter-spacing: 3px;
  color: var(--text-01);
  margin-bottom: 2rem;
}
.btn-importContacts {
  background: var(--color-14);
  border-radius: 15px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  border: none;
  color: var(--color-01);
  cursor: pointer;
  width: 10vw;
  padding: 0.3rem 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.btnImpCont {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.btn-fooOne {
  background: var(--color-18);
  color: var(--text-02);
  margin-right: 2rem;
}
@media (max-width: 991px) {
  .container-impCont {

  margin-top: 0;
}
  .container-radiocheck,
  .container-two {
    width: 100%;
  }
  .span-radio {
    margin-left: 0;
    font-size: 0.8em;
  }
  .inpSpanTwo {
    padding-right: 0rem;
    font-size: 0.8em;
  }
  th,
  td label {
    font-size: 1.05em;
  }
  .btn-importContacts {
    width: 15vw;
  }
}
</style>

<template>
  <div class="container">
    <HeaderNewCamp text="Nueva campaña Mail"  url="mail" url2="mail/borradores-mail" url3="mail"></HeaderNewCamp>
    <div class="body-container container-dark">
      <div class="iconInfo">
        <img
          @click="changeComp"
          src="../../assets/img/iconGrey/infoGrey.svg"
          alt=""
        />
      </div>
      <ModalInfoCM></ModalInfoCM>
      <div class="all">
        <!-- <FormNewCampMail v-if="active1"></FormNewCampMail> -->
       
        <keep-alive>
          <component v-if="active1" :is="currentView" @change-component="onChangeComponent">
          </component>
        </keep-alive>
         <InforNewCampMail v-if="active2"></InforNewCampMail>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNewCamp from "../common/HeaderNewCamp";
import FormNewCampMail from "../panels/FormNewCampMail";
import ModalInfoCM from "../modals/ModalInfoCM";
 import InforNewCampMail from "../panels/InforNewCampMail"; 
import PreviewCampMail from "../panels/PreviewCampMail.vue";
export default {
  components: {
    HeaderNewCamp,
    FormNewCampMail,
    ModalInfoCM,
      InforNewCampMail,
    PreviewCampMail,
  },
  data() {
    return {
      active1: true,
      active2: false,
      currentView: FormNewCampMail,
      show: true,
    };
  },
  methods: {
    modal() {
      this.$modal.show("modalInfoCM");
    },
    changeComp() {
      if (this.active1 == true) {
        this.active1 = false;
        this.active2 = true;
        
      } else {
        this.active1 = true;
        this.active2 = false;
      }
    },
    onChangeComponent(comp) {
      if (comp === "PreviewCampMail") this.currentView = FormNewCampMail;
      if (comp === "FormNewCampMail") this.currentView = PreviewCampMail;
    },
  },
};
</script>
<style scoped>
.body-container {
  background-color: var(--color-01);
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}
.all {
  width: 92%;
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
      justify-content: center;
}

.iconInfo {
  display: flex;
  width: 90%;
  justify-content: flex-end;
  margin-top: 2rem;
  cursor: pointer;
}
</style>

<template>
  <div>
    <datatable
      title=""
      :columns="tableColumns1"
      :clickable="false"
      :rows="tableRows1"
      :sortable="false"
      class=" table2"
    >
      <th class="th" slot="thead-tr">
        <p>Opciones</p>
      </th>
      <template slot="tbody-tr">
        <td class="td">
          <span class="closeCircle"></span>
          <span class="pause" @click="toggle"></span>
          <span class="pen"></span>
        </td>
      </template>
    </datatable>
  </div>
</template>
<script>
import DataTable from "vue-materialize-datatable";
export default {
  components: {
    datatable: DataTable,
  },
  data() {
    return {
      activada: true,
      tableColumns1: [
        {
          label: "Nombre",
          field: "nombre",
          numeric: false,
          html: false,
        },
        {
          label: "Correo",
          field: "correo",
          numeric: false,
          html: false,
        },
        {
          label: "Ruta",
          field: "ruta",
          numeric: false,
          html: false,
        },
        {
          label: "Estado",
          field: "estado",
          numeric: false,
          html: false,
        },
        {
          label: "Usuarios",
          field: "usuarios",
          numeric: true,
          html: false,
        },
        {
          label: "Créditos",
          field: "creditos",
          numeric: false,
          html: false,
        },
      ],
      tableRows1: [
        {
          nombre: "Prueba 1",
          correo: " ariela.tolosa.silva@gmail.cl",
          ruta: "API provida",
          estado: "activo",
          usuarios: 3,
          creditos: "20/03/22 11:30:01",
        },
        {
          nombre: "Prueba 1",
          correo: " pablo@mail.cl",
          ruta: "API provida",
          estado: "activo",
          usuarios: 3,
          creditos: "20/03/22 11:30:01",
        },
        {
          nombre: "Prueba 1",
          correo: " pablo@mail.cl",
          ruta: "API provida",
          estado: "activo",
          usuarios: 3,
          creditos: "20/03/22 11:30:01",
        },
      ],
    };
  },
  methods: {
        toggle: function(event) {
      event.target.classList.toggle("selected");
    },
    /* changeState() {
      this.activada = !this.activada;
    }, */
  },
};
</script>
<style scoped>

.td {
  display: flex;
 justify-content: space-around; 
}
.closeCircle {
  content: url(../../assets/img/iconGrey/closeCircle.svg);
  cursor: pointer;
/*   margin-right: 0.5rem; */
    width: 50px;
  height: 50px;
  transform: scale(0.4);
}
.pause {
  content: url(../../assets/img/iconGrey/pause.svg);
  cursor: pointer;
/*   margin-right: 0.5rem; */
      width: 50px;
  height: 50px;
  transform: scale(0.3);
}
.selected {
  content: url(../../assets/img/iconOrange/play.svg);
  cursor: pointer;
/*   margin-right: 0.5rem; */
      width: 50px;
  height: 50px;
  transform: scale(0.4);
}
.pen {
  content: url(../../assets/img/iconGrey/pen.svg);
  cursor: pointer;
/*   margin-right: 0.5rem; */
      width: 50px;
  height: 50px;
  transform: scale(0.3);
}
@media  (max-width: 991px) {
  .closeCircle {

  transform: scale(0.3);
}
.pause {

  transform: scale(0.2);
}
.selected {

  transform: scale(0.3);
}
.pen {

  transform: scale(0.2);
}
}

</style>

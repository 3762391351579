<template>
  <div class="row">
    <div class="col-sm-6  col-md-6 col-lg-3">
      <div class="container-card">
        <p class="text-subject textWhite">Asunto</p>
        <p class="parr-subject textWhite">
          Cambia tu telefono! Quieres ganar un Huawei P30?
        </p>
      </div>
    </div>
    <div class="col-sm-6  col-md-6 col-lg-3">
      <div class="container-card">
        <p class="text-subject textWhite">Peaks</p>
        <div class="body-cardSinCamM">
          <div class="container01">
            <div class="icon-container">
              <span class="pencil"></span>
            </div>
            <div class="foot-container">
              <p class="text-date textWhite">Lunes 28-10-19</p>
              <p class="text-title textWhite">Interaccíon</p>
            </div>
          </div>
          <div class="container02">
            <div class="icon-container">
              <span class="user"></span>
            </div>
            <div class="foot-container">
              <p class="text-date textWhite ">19:30hrs</p>
              <p class="text-title textWhite ">Actividad</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6  col-md-6 col-lg-3  card-down">
      <div class="container-card">
        <p class="text-subject textWhite">Navegador</p>
        <div class="body-cardSinCamM">
          <div class="container01">
            <div class="icon-container">
              <span class="clicks"></span>
            </div>
            <div class="foot-container">
              <p class="text-date textWhite">-------------</p>
              <p class="text-title textWhite">Navegador</p>
            </div>
          </div>
          <div class="container02">
            <div class="icon-container">
              <span class="user"></span>
            </div>
            <div class="foot-container">
              <p class="text-date textWhite">0</p>
              <p class="text-title textWhite">Actividad</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6  col-md-6 col-lg-3 card-down">
      <div class="container-card">
        <p class="text-subject textWhite">Sistema operativo</p>
        <div class="body-cardSinCamM">
          <div class="container01">
            <div class="icon-container">
              <span class="boxes"></span>
            </div>
            <div class="foot-container">
              <p class="text-date textWhite">-------------</p>
              <p class="text-title textWhite">Sistema</p>
            </div>
          </div>
          <div class="container02">
            <div class="icon-container">
              <span class="user"></span>
            </div>
            <div class="foot-container">
              <p class="text-date textWhite">0</p>
              <p class="text-title textWhite">Usuarios</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container-card {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  height: 40vh;
  width: 93%;
  padding: 1rem;
}
.text-subject,
.parr-subject,
.text-date,
.text-title {
  font-family: var(--font-family-3);
  text-align: start;
  color: var(--color-17);
}
.text-subject {
  font-weight: 500;
  font-size: 1.25em;
  letter-spacing: 0.694286px;
  margin-bottom: 1rem;
}
.parr-subject {
  font-size: 1em;
}
.col-sm-6.col-md-6.col-lg-3 {
    display: flex;
    justify-content: center;
}
.container01 {
  border-right: 2px solid var(--color-11);
}
.container01,
.container02 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.3rem;
}
.body-cardSinCamM {
  display: flex;
  height: 75%;
}
.text-date {
  font-weight: 500;
  font-size: 0.813em;
}
.text-title {
  font-size: 0.875em;
  text-align: center;
}
.icon-container {
  height: 80%;
  display: flex;
}
.foot-container {
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pencil {
  content: url(../../assets/img/iconLila/pencilLila.svg);
}
.user {
  content: url(../../assets/img/iconLila/ProfileLila.svg);
}
.boxes {
  content: url(../../assets/img/iconLila/boxesLila.svg);
}
.clicks {
  content: url(../../assets/img/iconLila/click.svg);
}
@media (max-width: 1199px){
.card-down {
    margin-top: 2rem;
}
}
@media (min-width: 1200px) and (max-width: 1407px) {
  .text-subject {
    font-size: 1.063em;
  }
  .parr-subject {
    font-size: 0.875em;
  }
  .text-date,
  .text-title133 {
    font-size: 0.7em;
  }
  .container-card {
    padding: 1rem 0.5rem;
  }
}
</style>

<template>
  <div id="app" :class="{dark}">
    <router-view/>
  </div>
</template>

<script>
export default {
    computed: {
      dark() {
        let darkMode = localStorage.getItem('darkmode')
        if(!darkMode || darkMode == '0') {
          return false
        } 

        return true
      }
    }
};
</script>


<style>

</style>

<template>
  <div class="container">
    <button @click='prueba'>aca</button>
    <div class="cuadrado1"></div>
    <div v-show='mostrar' class="cuadrado2"></div>
  </div>
</template>
<script>
export default {
  components: {},
  data(){
    return {
      mostrar:false
    }
  },
  methods:{
    prueba(){
      if (!this.mostrar) {
        this.mostrar=true
      } else{
        this.mostrar=false
      }
      
    }
  }
};
</script>
<style scoped>
.cuadrado1 {
  background: red;
  width: 200px;
  height: 200px;
  position: absolute;
}
.cuadrado2 {
  background: linear-gradient(0deg, rgba(5, 5, 15, 0.7), rgba(5, 5, 15, 0.7));
  width: 200px;
  height: 200px;
  position: absolute;
 /*  left: 15px;
  top: 15px; */
}
</style>

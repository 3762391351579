<template>
  <modal
    class="modal2"
    name="modalCard"
    :width="845"
    :height="553"
    :adaptive="true"
  >
    <div class="container-imgBtn">
      <img
        class="imgX"
        src="../../assets/icons/others/x.svg"
        @click="$modal.hide('modalCard')"
      />
    </div>
    <div class="container-modal">
      <div class="container-card">
        <div class="card">
          <p class="titleModalCard">{{ title }}</p>
          <img :src="imgCard" alt="" class="imgCard" />
          <img :src="imgCardBlack" alt="" class="imgCardBlack" />
          <p class="subTitleModalCard">{{ subTitle }}</p>
          <div class="credits">
            <Credits></Credits>
          </div>
        </div>
      </div>
      <div class="container-text">
        <p class="title2ModalCard">¿Qué podemos ofrecerte?</p>
        <p class="textModalCard">{{ text }}</p>
      </div>
    </div>
  </modal>
</template>

<script>
/* import CardModal from '../../components/common/CardModal' */
import Credits from "../../components/common/Credits";
export default {
  components: {
    /*  CardModal */
    Credits,
  },
  props: ["title", "imgCard", "subTitle", "text","imgCardBlack"],
  data() {
    return {};
  },
  computed: {
    /*   itemToShow: function () {
        return this.item
      }, */
  },

  methods: {
    /*   beforeOpen (event) {
        this.item = event.params.item;
      } */
  },
};
</script>

<style scoped>
.modal {
}
.container-imgBtn {
  padding: 1.5rem 2rem 0;
  text-align: end;
}
.container-modal {
  display: flex;
  /* border: 2px solid red; */
  /* margin-top: 2.2rem; */
/*   margin-left: 2rem; */
justify-content: space-around;
}
.imgCard, .imgCardBlack {
  width: 53%;
  padding-bottom: 3rem;
}
.imgCardBlack{
  display: none
}
.container-all {
  display: flex;
}
.container-card {
  width: 50%;
  display: flex;
  justify-content: center;
  /* margin-left: 2rem; */
}
.card {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-2);
 /*  border: 2px solid green; */
  width: 65%;
  height: 29rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
}
.credits {
  width: 50%;
}
.container-text {
/*   border: 2px solid lightcoral; */
  width: 50%;
  margin-left: -2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
p {
  font-family: var(--font-family-2);
  letter-spacing: 0.14px;
}
.titleModalCard,
.title2ModalCard {
  font-weight: bold;
  font-size: 1.5em;
  color: var(--text-01);
}
.subTitleModalCard,
.textModalCard {
  font-size: 1.25em;
  color: var(--color-17);
}
p.titleModalCard {
  padding-bottom: 2rem;
}
p.subTitleModalCard {
  width: 62%;
  padding-bottom: 2.5rem;
}
p.textModalCard {
  text-align: initial;
  width: 90%;
  line-height: 26px;
}
p.title2ModalCard {
  text-align: start;
  padding-bottom: 2rem;
}

@media  (max-width: 1023px) {
  .modal2 {
    margin-left: 1.5rem;
  }
  .container-modal {
    margin-top: 1rem;
    margin-left: 0;
    justify-content: flex-start;
  }
  .container-text {
    margin-left: -0.5rem;
    width: 52%;
  }
  .container-card {
    width: 47%;
  }
  .card {
    width: 85%;
    height: 25rem;
  }
  .titleModalCard,
  .title2ModalCard {
    font-size: 1.25em;
  }
  p.title2ModalCard {
    padding-bottom: 1rem;
    padding-top: 1.8rem;
  }
  .subTitleModalCard,
  .textModalCard {
    font-size: 1em;
  }
  p.textModalCard {
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .container-modal {
    margin-left: 0;
    justify-content: flex-start;
  }
  p.title2ModalCard {
    margin-top: 0.5rem;
    padding-bottom: 1rem;
  }
  p.textModalCard {
    width: 100%;
  }
}
</style>

<template>
  <div class="container-tableUrl">
    <div class="header-tableUrl">
      <p class="titleTableUrl textWhite">Resumen de últimas URL</p>
      <div class="btn-tableUrl">
        <button class=" textWhite">Tus URL</button>
      </div>
    </div>
    <div class="tableUrlShort">
   <datatable
      title=""
      :columns="tableColumns1"
      :clickable="false"
      :rows="tableRows1"
      :sortable="false"
      class="tableUrlShort"
    >
      <th class="th" slot="thead-tr">
        <i class="material-icons icon">more_vert</i>
      </th>
      <template slot="tbody-tr">
        <td class="td">
          <label class="dropdown">
            <div class="dd-button">
              <i v-on:click="addClass" class="material-icons icon2"
                >more_vert</i
              >
            </div>
            <input type="checkbox" class="dd-input" id="test" />

            <div v-on:click="deleteClass" id="minumenu" class="dd-menu">
              <div class="parr div-bor">Ver Reporte</div>
              <div class="parr div-bor">Reenviar</div>
              <div class="parr">Descargar</div>
            </div>
          </label>
        </td>
      </template>
    </datatable>
    </div>
  </div>
</template>
<script>
import DataTable from "vue-materialize-datatable";
let idElement;
export default {
     components: {
    datatable: DataTable,
  },
    data() {
    return {
      //isAddClass: false,
      tableColumns1: [
        {
          label: "Fecha",
          field: "date",
          numeric: false,
          html: false,
        },
        {
          label: "Enlace corto",
          field: "linkShort",
          numeric: false,
          html: false,
        },
        {
          label: "Enlace original",
          field: "linkOriginal",
          numeric: false,
          html: false,
        },
        {
          label: "Clics",
          field: "clics",
          numeric: false,
          html: false,
        },
        {
          label: "Acciones ",
          field: "actions",
          numeric: false,
          html: false,
        },
        
      ],
      tableRows1: [
        {
          date: "20/03/22 11:22:59",
          linkShort: "Tu solicitud de retiro  fué rechazada",
          linkOriginal: "20/03/22 11:22:59",
          clics: "120",
          actions: "AFP habitat (ariela.tolosa.silva@gmail.com)",
         
        },
        {
       date: "Prueba 1",
          linkShort: "Tu solicitud de retiro  fué rechazada",
          linkOriginal: "20/03/22 11:22:59",
          clics: "130",
          actions: "AFP habitat (ariela.tolosa.silva@gmail.com)",
        },
        {
           date: "Prueba 1",
          linkShort: "Tu solicitud de retiro  fué rechazada",
          linkOriginal: "20/03/22 11:22:59",
          clics: "20",
          actions: "AFP habitat (ariela.tolosa.silva@gmail.com)",
        },
      ],
    };
  },
    methods: {
    addClass(event) {
      // console.log(event.target);
      event.target.classList.toggle("active");
      // this.isAddClass = true;
      idElement = event.target;
    },
    deleteClass() {
      idElement.classList.remove("active");
      //  this.isAddClass = false;
    },
  },
};
</script>
<style scoped>
.container-tableUrl {
  background: var(--color-01);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  margin-top: 2rem;
  padding: 1rem 2rem;
  overflow-x: overlay;
}
.header-tableUrl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titleTableUrl {
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--color-17);
}
.btn-tableUrl button,
.titleTableUrl {
  font-family: var(--font-family-2);
  color: var(--color-17);
}
.btn-tableUrl button {
  background: var(--color-01);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--borderRadius-1);
  letter-spacing: 0.14px;
  font-size: 1.125em;
  border: none;
  padding: 1rem 4rem

}

.parr {
  font-family: var(--font-family-2);
  color: var(--text-01);
  letter-spacing: 3px;
  font-size: 0.938em;
  font-weight: 600;
  padding: 1rem 0 0.5rem 1rem;
  /*   line-height: 32px; */
  text-align: start;
}
.div-bor {
  border-bottom: 2px solid var(--color-01);
}
.dropdown {
  display: inline-block;
  position: relative;
  /* width: 100%; */
}

.dd-button {
  display: inline-block;
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  /* padding: 12px 30px 12px 20px; */
  /*   background-color: var(--color-01); */
  cursor: pointer;
  /*  white-space: nowrap; */
  /* width: 95%;  */
  /* font-size: 1.06em; */
  display: flex;
  /* align-items: center; */
  /*   justify-content: space-around; */
  z-index: 1000;
  /*  margin-top:2rem */
  /*   font-family: var(--font-family-2);
  font-weight: 600; */
}
.dd-button::after {
  z-index: 1000;
}

.dd-button:hover {
  background-color: var(--color-01);
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  /* top: 100%; */
  /*  border: 1px solid #ccc; */
  border-radius: var(--borderRadius-1);
  /* padding: 0; */
  margin: -33px 0 0 -129px;
  /*  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1); */
  background-color: var(--color-06);
  /*   list-style-type: none; */
  width: 10rem;
  /* height: 8.5rem; */
  z-index: 1;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}

.icon {
  color: var(--color-01);
}
.icon2 {
  /*   text-align: center; */
  color: var(--color-15);
}
.active {
  z-index: 1000;
  color: var(--color-01);
}

.tableUrlShort {
    margin-top: 2rem;
}
@media (max-width: 1200px){

.titleTableUrl {
    font-size: 1.25em;
}
.btn-tableUrl button{

    font-size: 1.025em;

    padding: 1rem 2rem;
}
}
</style>

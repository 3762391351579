<template>
  <div class="dContainer">
    <div class="head-table">
      <p class="title titleDark">Últimos envíos</p>
      <div class="btns">
        <button class="btn-rep btn-reply btn-repDark" :class="btnActive">
          Respuestas
        </button>
        <button :class="btnRep" class="btn-rep btn-repDark">Tus reportes</button>
      </div>
    </div>
    <div class="container-progress">
      <div class="progress1">
        <p class="pNum">{{ data1 }}</p>
        <div class="pDiv">
          <p class="pText titleDark">Lecturas únicas en los ultimos 30 días</p>
          <p class="pPerc">{{ data2 }}%</p>
        </div>
        <progress-bar
          size="large"
          :bar-border-radius="15"
          bar-color="#FFA34D"
          bg-color="#EDEEF4"
          val="67.2"
        ></progress-bar>
      </div>
      <div class="progress2">
        <p class="pNum">{{ data3 }}</p>
        <div class="pDiv">
          <p class="pText titleDark">Rebotes en los ultimos 30 días</p>
          <p class="pPerc">{{ data4 }}%</p>
        </div>
        <progress-bar
          size="large"
          :bar-border-radius="15"
          bar-color="#FFA34D"
          bg-color="#EDEEF4"
          val="5"
        ></progress-bar>
      </div>
    </div>
  </div>
</template>
<script>
import ProgressBar from "vue-simple-progress";
export default {
  components: {
    ProgressBar,
  },
  props: {
    btnActive: {
      type: String,
      default: "",
    },
    btnRep: {
      type: String,
      default: "",
    },
    data1: {
      type: String,
      default: "",
    },
    data2: {
      type: String,
      default: "",
    },
    data3: {
      type: String,
      default: "",
    },
    data4: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.dContainer {
  padding: 2.5rem 2.5rem 0 2.5rem;
}
.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.title {
  font-weight: 600;
  line-height: 32px;
  font-size: 1.563em;
  letter-spacing: 3px;
}
.title,
.btn-rep {
  font-family: var(--font-family-2);
  color: var(--color-17);
}
.btns {
  width: 35%;
  display: flex;
  justify-content: space-between;
}
.btn-rep {
  background-color: var(--color-01);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--borderRadius-1);
  border: none;
  cursor: pointer;
  letter-spacing: 0.14px;
  font-size: 1.125em;
  padding: 1rem;
  /*margin-left: 1rem; */
  width: 49%;
}
.btnRep {
  margin-left: 8rem;
}
.container-progress {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.progress1 {
  width: 43%;
  /* border: 2px solid red; */
}
.pNum {
  text-align: start;
  font-weight: 500;
  font-size: 2.188em;
  /* line-height: 24px; */
  letter-spacing: 0.694286px;
}
.pNum,
.pPerc {
  font-family: var(--font-family-3);
  color: var(--color-03);
}
.pText {
  font-family: var(--font-family-2);
  font-size: 0.938em;
  color: rgba(64, 64, 64, 0.9);
  letter-spacing: 0.694286px;
}
.pPerc {
  font-size: 0.938em;
  font-weight: 500;
  letter-spacing: 0.694286px;
}

.progress2 {
  width: 43%;
  /*  border: 2px solid; */
}
.pDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.btn-reply {
  display: none;
}
.btn-active {
  display: initial;
}
@media (max-width: 991px) {
  .btns {
    width: 45%;
  }
  .btn-rep {
    width: 49%;
    font-size: 0.85em;
  }

  .title {
    font-size: 1.25em;
  }
  .pNum {
    font-size: 1.8em;
  }
  .pText {
    text-align: start;
    font-size: 1em;
    width: 70%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .title {
    font-size: 1.25em;
  }

  .btn-rep {
    font-size: 0.9em;
  }
  .progress1 {
    width: 45%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
}
</style>

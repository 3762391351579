<template>
  <div class="container-form">
    <div class="container-left">
      <div class="head-contDrop">
        <label for="checkNewCamData">
          <div class="dropdown">
            <div :class="buttonData" class="dd-buttonData dd-buttonBlack">
              <input
                id="checkNewCamData"
                type="checkbox"
                name="menu"
                class="inputChe"
              />
              <p :class="title" class="titleDrop">
                Datos campaña
                <label
                  for="checkNewCamData"
                  class="arrowDNewCamMail "
                  :class="arrowDNewCamMail"
                >
                </label>
                <label
                  for="checkNewCamData"
                  class="arrowDNewCamMailD "
                  :class="arrowDNewCamMailD"
                >
                </label>
              </p>
              <div class="submenu  dd-menuCampMail" :class="menuCampMail">
                <div :class="colorSup" class="colorSup"></div>
                <div class="menu-body">
                  <div class="left-menu">
                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody">Nombre</span>
                    </div>

                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody">Asunto</span>
                    </div>
                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody">Remitente</span>
                    </div>
                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody">Lista de contacto</span>
                    </div>
                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody">Plantilla</span>
                    </div>
                  </div>
                  <div class="right-menu">
                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody titleMenuRi"
                        >*Cargar archivo</span
                      >
                    </div>
                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody titleMenuRi"
                        >*Enlazar evento</span
                      >
                    </div>
                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody titleMenuRi"
                        >*Enlazar encuesta</span
                      >
                    </div>
                    <div class="dBtn">
                      <button class="btn-validate" disabled="disabled">
                        Validar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </label>
      </div>
      <div class="body-contDrop">
        <div class="d-Input">
          <p class="title titleDark">Nombre campaña</p>
          <input
            @input="validarInput()"
            type="text"
            placeholder="Nombre de la campaña"
            class="inputCamp"
            v-model="nameCamp"
            id="nameCamp"
          />
          {{ nameCamp }}
        </div>
        <div class="d-Input">
          <div class="sender">
            <p class="title titleDark">Remitente</p>
            <div class="dd-mail  ">
              <div class="dd-aMail ">
                <span class="addressReply">direccion de respuesta</span>
              </div>
              <input class="dd-input" type="checkbox" />
              <div class="dd-cDirec dd-cMail">
                <input
                  type="email"
                  name=""
                  id="email"
                  placeholder="Correo de respuesta"
                  class="inputCampDi inptCampMail"
                />
                <button class=" btn-save">
                  Guardar
                </button>
              </div>
            </div>
          </div>
          <input
            @input="validarInput()"
            type="text"
            name=""
            id="selectTemp"
            placeholder="Mail"
            class="inputCamp "
            v-model="mail"
          />
        </div>
        <div class="d-Input">
          <div class="titleInp">
            <p class="title titleDark">
              Seleccionar plantilla
            </p>
            <span class="arrowLiDown"></span>
          </div>
          <input
            @input="validarInput()"
            type="text"
            placeholder="Adjunta el archivo"
            class="inputCamp  "
            id="adjArc"
            v-model="selectTemp"
          />
        </div>
      </div>
    </div>
    <div class="container-right">
      <div class="head-contDrop">
        <label for="checkNewCampMail">
          <div class="dropdown">
            <div :class="buttonSend" class="dd-buttonSend dd-buttonBlack">
              <input
                id="checkNewCampMail"
                type="checkbox"
                name="menu"
                class="inputChe"
              />
              <p class="titleDrop" :class="titleDropSend">
                Resumen / Envío<label
                  for="checkNewCampMail"
                  class="arrowDNewCamMail "
                  :class="arrowDNewSend"
                >
                </label>
                <label
                  for="checkNewCampMail"
                  class="arrowDNewCamMailD"
                  :class="arrowDNewSendD"
                >
                </label>
              </p>
              <div class="submenu dd-menuCampMail" :class="menuCampSend">
                <div class="colorSup" :class="colorSupSend"></div>
                <div class="menu-bodySend">
                  <div class="left-menuSend">
                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody">Resumen</span>
                    </div>

                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody titleMenuRi"
                        >*Programar envío</span
                      >
                    </div>
                    <div class="inpLab">
                      <label class="custom-radio-checkbox-mail">
                        <input
                          class="custom-radio-checkbox__input-mail"
                          type="checkbox"
                          name=""
                          value=""
                        />
                        <span
                          class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                        ></span> </label
                      ><span class="titleMenuBody titleMenuRi"
                        >*Enviar prueba</span
                      >
                    </div>
                  </div>
                  <div class="dBtn">
                    <button class="btn-validate" disabled="disabled">
                      Validar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </label>
      </div>
      <div class="body-contDrop">
        <label for="checkCustomize" class="d-Input">
          <div class="sender">
            <p class="title titleDark">Asunto</p>
            <div class="dropdownCampMail">
              <div class="dd-buttonMail dd-btnDark">
                <input
                  id="checkCustomize"
                  type="checkbox"
                  name="menu"
                  class="inputCheck"
                  v-model="asunto"
                />
                <p class="pdata">
                  Personalizar
                  <label for="checkCustomize" class="arrowD "> </label
                  ><label for="checkCustomize" class="arrowBlack "> </label>
                </p>
                <div class="submenuMail  dd-menuMail ">
                  <p class="pData ">agnnombre</p>
                  <p class="pData ">Mail</p>
                  <p class="pData ">txt</p>
                  <p class="pData ">access_link</p>
                  <p class="pData ">poli_link</p>
                </div>
              </div>
            </div>
          </div>
          <input
            type="email"
            name=""
            id="asuntoCamp"
            placeholder="Asunto de la campaña"
            class="inputCamp "
            v-model="asuntoCamp"
            @input="validarInput2"
          />
        </label>
        <div class="d-Input">
          <div class="titleInp">
            <p class="title titleDark">
              Lista de contactos
            </p>
            <span class="arrowLiDown"></span>
          </div>
          <input
            type="text"
            placeholder="Selecciona la lista de contactos"
            class="inputCamp  "
            id="listCont"
            v-model="listCont"
            @input="validarInput2"
          />
        </div>
        <div class="d-Input">
          <div class="sender">
            <p class="title titleDark">*Cargar archivos</p>
            <div class="dd-mail ">
              <div class="dd-aExam">
                <span class="addressReply">Examinar</span>
              </div>
              <!-- <input class="dd-input" type="checkbox" /> -->
              <!-- <div class="dd-c dd-cMail">
                <input
                  type="email"
                  name=""
                  id=""
                  placeholder="Correo de respuesta"
                  class="inputCamp inptCampMail"
                />
                <button @click="saveDate" class=" btn-save">
                  Guardar
                </button>
              </div> -->
            </div>
          </div>
          <input
            type="text"
            name=""
            id="ChargeArch"
            placeholder="Archivo xxxx"
            class="inputCamp "
            v-model="archivo"
            @input="validarInput2"
          />
          <div class="dSwitch">
            <label class="switch">
              <input type="checkbox" id="checkbox" />
              <span class="sliderNewCam round"></span>
            </label>
            <span class="spanText titleDark">Enlazar encuesta</span>
            <label class="switch">
              <input type="checkbox" id="checkbox" />
              <span class="sliderNewCam round"></span>
            </label>
            <span class="spanText titleDark">Enlazar evento</span>
          </div>
        </div>
      </div>
      <div class="foot-btns">
        <button @click="onClick01" class="btnFoot dd-schShipMail   ">
          Guardar borrador
        </button>
        <button @click="checkForm" class="btnFoot next ">
          Siguiente
        </button>
      </div>
      <ModalCommon
        title01="¡ya guardamos tu campaña!"
        subtitle01='La campaña la encontraras en "borradores"'
        textBtn01="Finalizar"
        :img1="img1"
        :img1Dark="img1Dark"
        name01="modal02"
        url="mail/nueva-camp-mail"
      ></ModalCommon>
      <ModalCommon
        title01="Oups!"
        subtitle01="Debes rellenar los campos faltantes"
        textBtn01="Volver"
        :img2="img2"
        name01="modal01"
        :img2Dark="img2Dark"
        url="mail/nueva-camp-mail"
      ></ModalCommon>
    </div>
  </div>
</template>
<script>
/* 
import DropCampData from "../buttons/DropCampData"; */ /* 
import DropCampSend from "../buttons/DropCampSend"; */
import ModalCommon from "../modals/ModalCommon";
import img01 from "../../assets/gif/modals/exit.gif";
import img1Dark from "../../assets/gif/modalsBlack/modalblack1.gif";
import img02 from "../../assets/gif/modals/xWhite.gif";
import img2Dark from "../../assets/gif/modalsBlack/xBlack.gif";

export default {
  components: {
    /*   DropCampData, */

    ModalCommon,
  },
  data() {
    return {
      nameCamp: null,
      mail: null,
      selectTemp: null,
      archivo: null,
      listCont: null,
      asunto: null,
      asuntoCamp: null,
      img1: img01,
      img2: img02,
      img1Dark: img1Dark,
      img2Dark: img2Dark,
      buttonData: "",
      title: "",
      colorSup: "",
      menuCampMail: "",
      arrowDNewCamMail: "",
      arrowDNewCamMailD: "",
      buttonSend: "",
      titleDropSend: "",
      menuCampSend: "",
      colorSupSend: "",
      arrowDNewSend: "",
      arrowDNewSendD: "",
    };
  },

  methods: {
    checkForm() {
      if (this.nameCamp && this.mail && this.selectTemp) {
        this.$root.$emit("name-cam", this.nameCamp);
        this.$emit("change-component", "FormNewCampMail");

        return true;
      } else {
        if (!this.nameCamp || !this.mail || !this.selectTemp) {
          this.send();
        }
      }
    },
    send() {
      this.$modal.show("modal01");
    },
    onClick01() {
      this.$modal.show("modal02");
    },
    /*   sendName(){
      this.$root.$emit("name-cam",this.nameCamp)
    } */
    validarInput() {
      if (this.nameCamp && this.mail && this.selectTemp) {
        this.buttonData = "changeBack";
        this.title = "title2Camp";
        this.colorSup = "colorSup2";
        this.menuCampMail = "dd-menuCamp2";
        this.arrowDNewCamMail = "arrowD2";
        this.arrowDNewCamMailD = "arrowBlack2";

        // alert("1");
      } else {
        this.buttonData = "";
        this.title = "";
        this.colorSup = "";
        this.menuCampMail = "";
        this.arrowDNewCamMail = "";
        this.arrowDNewCamMailD = "";
        // alert("2");
        //}
      }
    },
    validarInput2() {
      if (this.asuntoCamp && this.listCont && this.archivo) {
        this.buttonSend = "changeBack";
        this.titleDropSend = "title2Camp";
        this.colorSupSend = "colorSup2";
        this.menuCampSend = "dd-menuCamp2";
        this.arrowDNewSend = "arrowD2";
        this.arrowDNewCamMailD = "arrowBlack2";

        // alert("1");
      } else {
        this.buttonSend = "";
        this.titleDropSend = "";
        this.colorSupSend = "";
        this.menuCampSend = "";
        this.arrowDNewSend = "";
        this.arrowDNewCamMailD = "";
        // alert("2");
        //}
      }
    },
  },
};
</script>
<style scoped>

.container-form {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.title {
  font-family: var(--font-family-3);
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  color: var(--color-17);
}
.body-contDrop {
  padding-left: 2rem;
}
.d-Input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3rem;
}
.sender,
.titleInp {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.inputCamp,
.inputCampDi {
  border: none;
  border-bottom: 2px solid var(--color-05);
  width: 90%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  outline: none;
}
.inputCamp::placeholder,
.inputCampDi::placeholder {
  font-size: 0.938em;
}
.container-left,
.container-right {
  width: 47%;
}
.arrowLiDown {
  content: url(../../assets/img/iconLila/arrowLiDown.svg);
}

.dd-mail {
  z-index: 1;
  position: relative;
  display: inline-block;
}
.dd-aMail,
.dd-aExam,
.dd-buttonMail {
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
  border: 1px solid var(--color-05);
}
.dd-aMail,
.dd-aExam {
  padding: 0.3rem;
  position: relative;
  width: 12.5rem;
}

.dd-mail .dd-input {
  top: 0;
  opacity: 0;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}
.dd-mail input:hover {
  cursor: pointer;
}

.dd-mail input:checked:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
}
.dd-mail input:checked ~ .dd-c,
.dd-mail input:checked ~ .dd-cDirec {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}
.dd-aMail span,
.dd-aExam span {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 0.938em;
  color: var(--color-05);
}
.dd-c,
.dd-cDirec {
  display: block;
  position: absolute;
  background: var(--color-01);
  height: auto;
  transform: scaleY(0);
  transform-origin: top left;
  transition-duration: 0.2s;
  -webkit-transform: scaleY(0);
  -webkit-transform-origin: top left;
  -webkit-transition-duration: 0.2s;
  width: 100%;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  border-radius: 0 0 15px 15px;
}

.dd-cMail {
  width: 233%;
  left: -263px;
  /* padding: 0.5rem 1.5rem 1rem; */
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 1rem 0;
}
.inptCampMail {
  width: 50%;
}
.btn-save {
  background-color: var(--color-14);
  border: none;
  color: var(--color-01);
  padding: 0.1rem 0;
  width: 8vw;
  font-family: var(--font-family-3);
  border-radius: var(--borderRadius-1);
  font-size: 1.063em;
}
.addressReply {
  font-family: var(--font-family-3);
  font-size: 0.875em;
  letter-spacing: 0.694286px;
  color: var(--color-05);
}

.dropdownCampMail {
  position: relative;
  width: 12.5rem;
  cursor: pointer;
}
.dd-buttonMail {
  padding: 0.3rem 0;
  color: var(--color-05);
  font-size: 0.875em;
  display: flex;
  justify-content: center;
  font-family: var(--font-family-3);
  font-weight: 500;
  letter-spacing: 0.694286px;
}
label.arrowD::before,
.inputCheck:checked + p label::before,
label.arrowBlack::before,
input:checked + p .arrowBlack::before {
  background-repeat: no-repeat;
  background-size: 12px 12px;
  display: inline-block;
  width: 12px;
  height: 12px;
  content: " ";
  cursor: pointer;
}
label.arrowD::before {
  padding-left: 0.5rem;
  background-image: url(../../assets/img/iconLila/arrowLiDown.svg);
}

.inputCheck:checked + p label::before {
  background-image: url(../../assets/img/iconLila/arrowLiUp.svg);
}
label.arrowBlack::before {
  padding-left: 0.5rem;
  background-image: url(../../assets/img/iconBlack/ArrowDB.svg);
  display: none;
}
input:checked + p .arrowBlack::before {
  background-image: url(../../assets/img/iconBlack/arrowUB.svg);
  display: none;
}
input:checked ~ div.submenuMail {
  display: block;
}
p.pdata {
  display: flex;
  align-items: center;
}
label.arrowD {
  margin-left: 3rem;
  display: flex;
  align-items: center;
}
.inputCheck {
  cursor: pointer;
}
.inputCheck,
div.submenuMail {
  display: none;
}
.dd-menuMail {
  position: absolute;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  background-color: var(--color-01);
  width: 100%;
  left: 0;
  top: 2rem;
  padding-top: 0.5rem;
  border-radius: 0 0 15px 15px;
}
.pData,
.dd-c,
.dd-cDirec,
.inputCamp::placeholder,
.inputCampDi::placeholder {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.694286px;
  color: var(--text-02);
}
.pData {
  font-size: 0.938em;
  padding-bottom: 0.3rem;
}
/* switch */

.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 23px;
  /*  margin-top: 1rem; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderNewCam {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-18);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderNewCam:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 1px;
  bottom: 1px;
  background-color: var(--color-01);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 1px 3px rgb(101 101 101 / 33%);
}

input:checked + .sliderNewCam {
  background-color: var(--color-11);
}

input:checked + .sliderNewCam:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(14px);
}

/* Rounded sliders */
.sliderNewCam.round {
  border-radius: 34px;
}

.sliderNewCam.round:before {
  border-radius: 50%;
}
.dSwitch {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}
.spanText {
  font-family: var(--font-family-2);
  font-size: 1.05em;
  letter-spacing: 0.694286px;
  color: var(--text-01);
  margin-right: 1.5rem;
  margin-left: 0.5rem;
}
.foot-btns {
  width: 87%;
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.foot-btns button {
  margin-left: 0.5rem;
}
.btnFoot {
  background-color: var(--color-01);
  border: 1px solid var(--color-05);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 0.75em;
  letter-spacing: 0.694286px;
  color: var(--color-05);
  /* padding: 0.7rem 2.5rem; */
  cursor: pointer;
  width: 10vw;
  padding: 0.3rem 0;
}
.saveDraft {
  padding: 0.3rem 0;
}
.dd-schShipMail {
  background: var(--color-18);
  border: none;
}
.next {
  background: var(--color-14);
  border: none;
  color: var(--color-01);
}

/* dropDAta */
.colorSup {
  background-color: var(--color-08);
  height: 0.5rem;
}
.dropdown {
  /* display: inline-block; */
  position: relative;
  width: 100%;
}
.dd-buttonData,
.dd-buttonSend {
  display: inline-block;
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  padding: 12px 20px 12px 20px;
  background-color: var(--color-08);
  white-space: nowrap;
  /* width: 100%; */
  font-size: 1.06em;
  display: flex;
  /* align-items: center; */
  /* z-index: 1000; */
  /* margin-top: 2rem; */
  font-family: var(--font-family-2);
  font-weight: 600;
  justify-content: center;
}
.arrowDNewCamMail {
  margin-left: 13rem;
}

.arrowDNewCamMail::before {
  content: url(../../assets/img/iconGrey/ArrowDownLi.svg);
  padding-left: 0.5rem;
  cursor: pointer;
}
.arrowDNewCamMailD::before {
  content: url(../../assets/img/arrowDBlue.svg);
  padding-left: 0.5rem;
  cursor: pointer;
  display: none;
}
.inputChe:checked + p .arrowDNewCamMail::before {
  content: url(../../assets/img/iconGrey/ArrowUp.svg);
  cursor: pointer;
}
.inputChe:checked + p .arrowDNewCamMailD::before {
  content: url(../../assets/img/arroUpBlue.svg);
  cursor: pointer;
  display: none;
}
.inputChe:checked ~ div.submenu {
  display: block;
}
.inputChe,
div.submenu {
  display: none;
}
.dd-menuCampMail {
  position: absolute;
  /* border: 1px solid #ccc; */
  border-radius: 0 0 15px 15px;
  /* padding: 0; */
  /* margin: 173px 0 0 0; */
  /* box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%); */
  background-color: var(--color-01);
  /* list-style-type: none; */
  width: 100%;
  height: 12.5rem;
  z-index: 10;
  left: 0;
  top: 2.7rem; /* 
  border: 2px solid red; */
  box-shadow: 0 -21px var(--color-08), 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.titleDrop {
  font-family: var(--font-family-2);
  font-weight: bold;
  font-size: 1.563em;
  letter-spacing: 0.14px;
  color: var(--color-05);
  position: relative;
  z-index: 200;
}
.menu-body,
.menu-bodySend,
.left-menu,
.left-menuSend,
.right-menu {
  display: flex;
}

.menu-bodySend {
  flex-direction: column;
}
.left-menu,
.left-menuSend,
.right-menu {
  width: 50%;
  flex-direction: column;
}
.inpLab {
  display: flex;
  align-items: center;
}
.titleMenuBody {
  font-family: var(--font-family-2);
  font-size: 1.25em;
  letter-spacing: 0.14px;
  color: var(--text-01);
}
/* checkbox */
.custom-radio-checkbox-mail {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: arial;
}
.custom-radio-checkbox__show--checkbox-mail {
  background-image: url(../../assets/img/iconLila/unchecklila.png);
}

/* Oculta input original */
.custom-radio-checkbox-mail > .custom-radio-checkbox__input-mail {
  display: none;
}

/* Radio personalizado usando <span> */
.custom-radio-checkbox-mail > .custom-radio-checkbox__show-mail {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
  background-size: cover;
}
/* Cambia el checkbox personalizado cuando es pulsado */
.custom-radio-checkbox-mail
  > .custom-radio-checkbox__input-mail:checked
  + .custom-radio-checkbox__show--checkbox-mail {
  background-image: url(../../assets/img/iconLila/check.png);
}
.btn-validate {
  background: var(--color-14);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  color: var(--color-01);
  width: 8.5rem;
  margin-top: 2rem;
  padding: 0.1rem 0;
  border: none;
}
.menu-body,
.menu-bodySend {
  margin-top: 1rem;
}
.left-menu {
  height: 10rem;
  justify-content: space-between;
  padding-left: 2.5rem;
}
.left-menuSend {
  height: 6rem;
  justify-content: space-between;
  padding-left: 2.5rem;
}
.right-menu {
  justify-content: space-between;
}
.titleMenuRi {
  color: var(--color-15);
}
.dBtn {
  display: flex;
  justify-content: flex-end;
  margin-right: 3.5rem;
}
.changeBack,
.colorSup2 {
  background: var(--color-05);
}
.title2Camp {
  color: white;
}
.dd-menuCamp2 {
  box-shadow: 0 -21px var(--color-05), 0px 0px 10px rgb(0 0 0 / 15%);
}
.arrowD2::before {
  content: url(../../assets/img/iconWhite/ArrowdWhite.svg);
}
.inputChe:checked + p .arrowD2::before {
  content: url(../../assets/img/iconWhite/ArrowUpWhite.svg);
}
.arrowBlack2::before {
  content: url(../../assets/img/iconBlack/ArrowDB.svg);
}
.inputChe:checked + p .arrowBlack2::before {
  content: url(../../assets/img/iconBlack/arrowUB.svg);
}
@media (max-width: 991px) {
  .container-form {
    flex-direction: column;
    align-items: center;
  }
  /*   .container-left,
  .container-right {
    width: 100%;
  } */
  .container-right {
    margin-top: 2rem;
  }
  .btnFoot {
    width: 18vw;
  }
  .title {
    font-size: 1.25em;
  }
  .container-left,
  .container-right {
    width: 90%;
  }
  .dd-cMail {
    width: 225%;
    left: -248px;
  }
  .titleDrop {
    font-size: 1.375em;
  }
  .titleMenuBody {
    font-size: 1.05em;
  }
}
@media (max-width: 1024px) {
  .container-form {
    flex-direction: column;
    align-items: center;
  }
  /*   .container-left,
  .container-right {
    width: 100%;
  } */
  .container-right {
    margin-top: 2rem;
  }
  .btnFoot {
    width: 18vw;
  }
  .title {
    font-size: 1.25em;
  }
  .container-left,
  .container-right {
    width: 90%;
  }
  .dd-cMail {
    width: 293%;
    left: -384px;
  }
  .titleDrop {
    font-size: 1.375em;
  }
  .titleMenuBody {
    font-size: 1.05em;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .container-form {
    flex-direction: column;
    align-items: center;
  }
  .container-left,
  .container-right {
    width: 90%;
  }
  .dd-aMail,
  .dropdownCampMail {
    width: 12rem;
  }
  .container-right {
    margin-top: 2rem;
  }
  .btnFoot {
    width: 14vw;
  }
  .body-contDrop {
    padding-left: 3rem;
  }
  .title {
    font-size: 1.25em;
  }
  .dd-cMail {
    width: 308%;
    left: -398px;
  }
  .titleDrop {
    font-size: 1.375em;
  }
  .titleMenuBody {
    font-size: 1.05em;
  }
}
@media (min-width: 1200px) and (max-width: 1330px) {
  .title {
    font-size: 1.15em;
  }
  .dd-aExam {
    width: 9.5rem;
  }
  .dd-cMail {
    width: 180%;
    left: -159px;
  }
  .titleDrop {
    font-size: 1.325em;
  }
  .spanText {
    margin-right: 0.5rem;
  }
  .btnFoot {
    width: 12vw;
  }
}
@media (min-width: 1331px) and (max-width: 1439px) {
  .dd-aExam {
    width: 8.5rem;
  }
  .dd-cMail {
    width: 202%;
    left: -203px;
  }
}
</style>

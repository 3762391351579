<template>
  <div class="container-allResumen">
    <backTo text="Volver atrás"></backTo>
    <div class="container-resumenMail">
      <chartsResumenMail></chartsResumenMail>
      <div class="container-table">
        <div class="header-table">
          <p class="title-headerTable">Historial de campañas</p>
          <div class="searchCustField">
            <input type="text" placeholder="buscar" class="inpSeaResBias" />
            <span class="searchResBias"></span>
          </div>
        </div>

        <datatable
          title=""
          :sortable="false"
          :columns="fields"
          :rows="rows"
          locale="es"
          class="table-campaignHistory"
        >
          <th width="75" slot="thead-tr">Estado</th>
          <th slot="thead-tr">Acciones</th>
          <!-- <th width="75" slot="thead-tr">Campañas</th> -->
          <template slot="tbody-tr" scope="props">
            <td width="75" align="center">
              <span
                v-if="props.row.status === 'Realizada'"
                class="indicator green"
              ></span>
              <span
                v-if="props.row.status === 'Pendiente'"
                class="indicator red"
              ></span>
              <span v-if="props.row.status === '---'">---</span>
            </td>
            <td align="center">
              <div class="btns-col">
                <div class="btn-col">
                  <a
                    class=" btn-phone"
                    :class="{ disabled: props.row.status === '---' }"
                    @click="openModal()"
                    ><span class="iconEye"></span>
                  </a>
                </div>
                <div class="btn-col">
                  <router-link
                    to="/bias/campana-mail"
                    class="btn-see"
                    :class="{ disabled: props.row.status === '---' }"
                    ><span>Ver</span></router-link
                  >
                </div>
              </div>
            </td>
            <!--  <td width="75" align="center">
              <a
                href="#"
                class="view-btn"
                :class="{ disabled: props.row.status === '---' }"
                @click.prevent="openModal(props.row.mail)"
              >
                <i class="icon color-2 icon-eye"></i>
              </a>
            </td> -->
          </template>
        </datatable>
      </div>
    </div>
    <modal-resumen-bias-mail></modal-resumen-bias-mail>
  </div>
</template>

<script>
import backTo from "../common/BackTo";
import chartsResumenMail from "../charts/chartsResumenMail";
import DataTable from "vue-materialize-datatable";
import ModalResumenBiasMail from "../modals/ModalResumenBiasMail"
export default {
  components: {
    backTo,
    chartsResumenMail,
    datatable: DataTable,
    ModalResumenBiasMail
  },
  data() {
    return {
      fields: [
        { label: "Fecha envío", field: "dateSend" },
        { label: "Campaña", field: "campaign" },
        { label: "Mensaje", field: "message" },
        { label: "Enviados", field: "sentTo" },
      ],
      rowsRaw: [
        {
          dateSend: "20/03/22 11:22:59",
          campaign: "HabitatLiquiTest",
          message: "[data2][url]",
          sentTo: "12.093",
          status: "Pendiente",
        },
        {
          dateSend: "20/03/22 11:22:59",
          campaign: "AguasTestHabitat",
          message: "Hola [name], te invito a conocer tu cuenta",
          sentTo: "8.720",
          status: "Realizada",
        },
        {
          dateSend: "20/03/22 11:22:59",
          campaign: "HabitatDemoTest",
          message: "Hola [name], te invito a conocer tu cuenta",
          sentTo: "92.392",
          status: "Realizada",
        },
        {
          dateSend: "20/03/22 11:22:59",
          campaign: "HabitatDemoTest",
          message: "Hola [name], te invito a conocer tu cuenta",
          sentTo: "1.938",
          status: "Pendiente",
        },
      ],
    };
  },
  created() {
    this.rowsRaw;
    this.rows = this.rowsRaw;
  },
  methods:{
    openModal(){
       this.$modal.show("campaign-summary");
    }
  }
};
</script>
<style scoped>
.container-allResumen {
  width: 100%;
}
.container-resumenMail,
.container-table {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius:var(--borderRadius-1);
}
.container-resumenMail {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.container-table {
  margin-top: 4rem;
  width: 90%;
  padding: 2rem 3rem;
  margin-bottom: 2rem;
}
.indicator {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  /* margin-left: 1.5rem; */
}

.green {
  background: #28a745;
}

.red {
  background: #dc3545;
}

.btns-col {
  display: flex;
  justify-content: space-evenly;
}

/* .btn-col {
	display: flex;
	align-items: center;
	justify-content: center;
} */
.iconEye {
  content: url(../../assets/img/iconLila/eye.svg);
}
.btn-see {
  background: var(--color-18);
  border: 1px solid var(--color-18);
  box-sizing: border-box;
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  color: var(--color-05);
  padding: 0.1rem 0.5rem;
}
.searchCustField {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  width: 45%;
}
.inpSeaResBias {
  border: none;
  border-bottom: 2px solid var(--color-05);
  width: 90%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  outline: none;
}
.inpSeaResBias::placeholder {
  font-family: var(--font-family-3);
  font-weight: normal;
  font-size: 0.938em;
  letter-spacing: 0.14px;
  color: var(--color-15);
    text-align: end;
  margin-right: 1rem;
}

.searchResBias {
  content: url(../../assets/img/iconGrey/search.svg);
  border-bottom: 2px solid var(--color-05);
  padding-top: 0.5rem;
}
.header-table {
  display: flex;
  align-items: center;
  /* width: 90%; */
  justify-content: space-between;
  margin-bottom: 2rem;
}
.title-headerTable {
  font-family: var(--font-family-3);
  font-weight: normal;
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  color: var(--text-01);
}
.container-table {
    overflow-y: hidden;
    overflow-x: auto;
}
@media (max-width: 992px){
  .container-table {
   
    padding: 2rem 1rem;
    

}
.title-headerTable {
   
    font-size: 1.25em;
 
}

}
</style>

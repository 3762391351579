<template>
  <div class="cont-login">
    <div class="header-login">
      <img class="imgHeader" src="../../assets/img/logo-altera.png" alt="" />
    </div>
    <div class="inputs">
      <input
        class="input-email"
        type="email"
        name=""
        id="i-email"
        placeholder="Ingresa tu mail"
      />
      <input
        type="password"
        name=""
        id="i-pass"
        placeholder="Ingresa tu contraseña"
      />
    </div>
    <div class="btn">
      <router-link to="/Home">
      <button>
        Iniciar Sesión
      </button>
      </router-link>
    </div>
    <span> ¿Olvidaste tu contraseña? </span>
    <div class="footer-login">
      <div class="footLogin">
        <div class="parr d-parr">
          Términos del servicio
        </div>
        <div class="parr d-parr">
          Política de privacidad
        </div>
        <div class="parr">
          Contacto
        </div>
      </div>
    </div>

    <p class="parrYear">{{ new Date().getFullYear() }} Altera</p>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.cont-login {
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 15px;
  width: 35%;
  /* height: 75vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid black; */
  /* justify-content: space-evenly; */
}
.inputs {
  display: flex;
  flex-direction: column;
  width: 53%;
  margin-top: 7rem;
  margin-bottom: 1rem;
}
.footLogin {
  display: flex;
    margin-top: 4rem;
}
input {
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
  border: none;
  padding: 1rem 0;
  outline: none;
  padding-left: 1rem
}
::placeholder {
  line-height: 14px;
  font-size: 0.938em;
color:var(--color-17);
padding-left: 1rem


}
::placeholder,
.parr,
span,
p {
  font-family: var(--font-family-2);
}
span {
  font-size: 0.625em;
}
.parr {
  font-weight: 600;
  line-height: 16px;
  font-size: 0.688em;
  color: var(--color-17);
  padding: 0 .3rem;
  cursor: pointer;
}
.d-parr {
  border-right: 1px solid var(--color-17);
}
.btn {
  width: 53%;
}
button {
  width: 100%;
  border-radius: 15px;
  /*   padding: 12px 20px 12px 20px; */
  font-size: 0.938em;
  cursor: pointer;
  background-color: var(--color-03);
  color: var(--color-01);
  font-family: var(--font-family-2);
  font-weight: 600;
  border: none;
  padding: 0.8rem 0;
}
p {
  font-size: 0.625em;
  color: var(--color-17);
  font-weight: 600;
}
.header-login {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3.5rem;
}
.imgHeader {
  width: 55%;
}
.input-email {
  margin-bottom: 1rem;
}
span {
  margin-top: 0.3rem;
  width: 53%;
  text-align: end;
  cursor: pointer;
}
.parrYear{
  padding: 1rem 0 1.5rem 0
}
@media (max-width: 991px) {
  .cont-login{
  
    width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
   .cont-login{
  
    width: 40%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
    
}
@media (min-width: 1600px) {
   .cont-login{
  
    width: 30%;
  }
}
</style>

<template>
  <div class="prueba">
    <modal
      class="modalSmsTest"
      name="modalSmsTest"
      :width="622"
      :height="512"
      :adaptive="true"
    >
      <div class="container">
        <div class="container-imgBtn">
          <img
            class="imgX"
            src="../../assets/icons/others/x.svg"
            @click="$modal.hide('modalSmsTest')"
          />
        </div>
        <div class="container-body">
          <p class="title">
            ¿Deseas realizar una prueba de SMS?
          </p>
          <input type="text" name="" id="" class="inpDark" />
          <p class="subtitle subOne titleDark">Ingrese número(s) de prueba</p>
          <p class="subtitle titleDark">(Máx 5 números separados por ";")</p>
          <button @click="sendTest">Enviar prueba</button>
        </div>
      </div>
    </modal>
    <ModalCommon
      title01="Envío de prueba exitoso"
      subtitle01="El envío de prueba fue exitoso"
      textBtn01="Finalizar"
      :img1="img1"
      :img2Dark="img2Dark"
      name01="modal03"
      url="sms"
    ></ModalCommon>
  </div>
</template>
<script>
import ModalCommon from "../modals/ModalCommon";
import img01 from "../../assets/gif/modals/starts.gif";
import img2Dark from "../../assets/gif/modalsBlack/modalblack.gif";

export default {
  components: {
    ModalCommon,
  },
  data() {
    return {
      img1: img01,
      img2Dark: img2Dark,
    };
  },
  methods: {
    sendTest() {
      this.$modal.show("modal03");
      this.$modal.hide("modalSmsTest");
    },
  },
};
</script>
<style scoped>
.container {
  width: 90%;
}
.container-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container-imgBtn {
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
}
.title {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 900;
  font-size: 2em;
  text-align: center;
  letter-spacing: 1.15714px;
  color: var(--color-12);
  width: 70%;
}
input {
  border: 1px solid var(--color-1);
  border-radius: var(--borderRadius-1);
  width: 80%;
  outline: none;
  height: 2.5rem;
  margin-top: 5rem;
  padding-left: 0.5rem;
}
.subtitle {
  font-size: 1.125em;
  letter-spacing: 0.694286px;
  color: #242424;
}
.subOne {
  margin-top: 3rem;
}
button {
  background-color: var(--color-12);
  border-radius: var(--borderRadius-1);
  border: none;
  font-family: var(--font-family-2);
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  font-weight: bold;
  color: var(--color-01);
  width: 15vw;
  margin-top: 5rem;
  padding: 0.5rem;
  cursor: pointer;
}

@media (max-width: 1199px) {
  .title {
    font-size: 1.6em;
  }
  input {
    margin-top: 3rem;
  }
  button {
    font-size: 1.25em;
    width: 22vw;
    margin-top: 3rem;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .title {
    font-size: 1.8em;
  }
  input {
    margin-top: 3rem;
  }
  button {
    margin-top: 3rem;
  }
}
</style>

<template>
  <div class="container-tableCalls">
    <backTo text="Volver atrás"></backTo>
    <div class="tableSummaryCalls container-dark">
      <div class="headerTableCalls">
        <p class="titleCalls textWhite">Todas las llamadas</p>
        <div class="tab-control">
          <div class="btns">
            <button
              @click="changeTab(1)"
              class="btn-tab "
              :class="{ active: currentTab == 1 }"
            >
              Entregado
            </button>
            <button
              @click="changeTab(2)"
              class="btn-tab "
              :class="{ active: currentTab == 2 }"
            >
              Rechazado
            </button>
            <button
              @click="changeTab(3)"
              class="btn-tab "
              :class="{ active: currentTab == 3 }"
            >
              Aperturas
            </button>
          </div>
        </div>
      </div>
      <div>
        <div class="tab" v-if="currentTab == 1">
         <TableDeliveredVoip></TableDeliveredVoip>
        </div>
        <div class="tab" v-if="currentTab == 2">
         <TableRejectedVoip></TableRejectedVoip>
        </div>
        <div class="tab" v-if="currentTab == 3">
          <TableOpeningsVoip></TableOpeningsVoip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import backTo from "../common/BackTo";
import TableDeliveredVoip from '../tables/TableDeliveredVoip'
import TableRejectedVoip  from '../tables/TableRejectedVoip'
import TableOpeningsVoip  from '../tables/TableOpeningsVoip'
export default {
  components: {
    backTo,
    TableDeliveredVoip,
    TableRejectedVoip,
    TableOpeningsVoip
  },
  data() {
    return {
      currentTab: 1,
    };
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == 1) {
        return;
      } else if (tab == 2) {
        return;
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
.container-tableCalls {
  width: 100%;
}
.tableSummaryCalls {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  width: 100%;
  padding: 2rem 3rem;
  min-height: 100vh;
}
.titleCalls {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--color-17);
  text-align: start;
}
.headerTableCalls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-tab {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  color: var(--color-05);
  background: var(--color-18);
  border: 1px solid var(--color-18);
  box-sizing: border-box;
  border-radius: var(--borderRadius-1);
  padding: 0 .5rem;
  cursor: pointer;
}
.tab-control {
    width: 30%;
}
.btns {
    display: flex;
    justify-content: space-between;
}
.active{
    background-color: var(--color-05);
    color:var(--color-18)
}
.tab{
    margin-top: 2rem
}
@media (max-width: 1200px){
.titleCalls{
    font-size: 1.25em
}
.tab-control {
    width: 54%;
}
.btn-tab{
    font-size: 0.938em
}
}
@media (min-width: 1200px) and (max-width: 1407px){
    .titleCalls{
    font-size: 1.25em
}
.tab-control {
    width: 40%;
}
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Mail from "../views/Mail.vue";
import SMS from "../views/SMS.vue";
import Pruebas from "../views/Pruebas.vue";
import HelpCenter from "../views/HelpCenter.vue";
import Profile from "../views/Profile.vue";
import other from "../views/other.vue";
import Login from "../views/Login.vue";
import SendSms from "../views/SendSms.vue";
import DraftsSms from "../views/DraftsSms.vue";
import DraftsMail from "../views/DraftsMail.vue";
import SendMail from "../views/SendMail.vue";
import TemplatesMail from "../views/TemplatesMail.vue";
import Contacts from "../views/Contacts.vue";
import CurrentShipments from "../views/CurrentShipments.vue"
import Bias from "../views/Bias.vue"
import ResumenBiasMail from '../views/ResumenBiasMail.vue'
import ResumenBiasSms from '../views/ResumenBiasSms.vue'
import CampaignMail from '../views/CampaignMail.vue'
import CampaignSms from '../views/CampaignSms.vue'
import Voip from '../views/Voip.vue'
import ShortUrl from '../views/ShortUrl.vue'
import SummaryCallsVoip from '../views/SummaryCallsVoip.vue'

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/bias",
    name: "Bias",
    component:Bias,
  },
  {
    path: "/contactos",
    name: "Contactos",
    component:Contacts,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/mail",
    name: "Mail",
    component: Mail,
  },
  {
    path: "/sms",
    name: "SMS",
    component: SMS,
  },
  {
    path: "/centro-ayuda",
    name: "HelpCenter",
    component: HelpCenter,
  },
  {
    path: "/perfil",
    name: "profile",
    component: Profile,
  },
  {
    path: "/pruebas",
    name: "pruebas",
    component: Pruebas,
  },
  {
    path: "/sms/nueva-camp-sms",
    name: "SendSms",
    component: SendSms,
  },
  
  {
    path: "/mail/nueva-camp-mail",
    name: "SendMail",
    component: SendMail,
  },
  {
    path: "/sms/borradores-sms",
    name: "DraftsSms",
    component: DraftsSms,
  },
  {
    path: "/mail/borradores-mail",
    name: "DraftsMail",
    component: DraftsMail,
  },
  {
    path: "/mail/plantillas-mail",
    name: "TemplatesMail",
    component: TemplatesMail,
  },
  {
    path: "/envios-en-curso",
    name: "CurrentShipments",
    component: CurrentShipments,
  },
  {
    path: "/bias/resumen-mail",
    name: "ResumenBiasMail",
    component: ResumenBiasMail,
  },
  {
    path: "/bias/resumen-sms",
    name: "ResumenBiasSms",
    component: ResumenBiasSms,
  },
  {
    path: "/voip",
    name: "Voip",
    component: Voip,
  },
  {
    path: "/voip/resumen-llamadas",
    name: "SummaryCallsVoip",
    component: SummaryCallsVoip,
  },
  {
    path: "/url-corta",
    name: "ShortUrl",
    component: ShortUrl,
  },
  {
    path: "/bias/campaña-sms",
    name: "CampaignSms",
    component: CampaignSms,
  },
  {
    path: "/bias/campana-mail",
    name: "CampaignMail",
    component: CampaignMail,
  },
  {
    path: "/*",
    name: "404",
    component: other,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
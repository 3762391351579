<template>
  <label for="check">
    <div class="dropdown">
      <div class="dd-buttonTemp">
        <input id="check" type="checkbox" name="menu" />
        <p class="text-btn">
          Plantillas predeterminadas<label for="check" class="arrowD1 ">
          </label>
        </p>
        <div class="submenu  dd-menuTemp">
          <div class="container-btnsDropTem">
            <button>Día de la madre</button>
            <button>Día del niño</button>
            <button>Ofertas</button>
            <button>Oportunidades</button>
          </div>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped>
.dropdown {
  /* display: inline-block; */
  position: relative;
  width: 100%;
  cursor: pointer;
}
.dd-buttonTemp {
  display: inline-block;
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  padding: 12px 20px 12px 20px;
  background-color: var(--color-01);

  white-space: nowrap;
  /* width: 100%; */
  font-size: 1.06em;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  /* z-index: 1000; */
  /* margin-top: 2rem; */
  font-family: var(--font-family-2);
  font-weight: 600;
}

label.arrowD1::before {
  content: url(../../assets/img/iconLila/arrowLiDown.svg);
  padding-left: 0.5rem;
  cursor: pointer;
}
input:checked + p label::before {
  content: url(../../assets/img/iconLila/arrowLiUp.svg);
  cursor: pointer;
}

input:checked ~ div.submenu {
  display: block;
}
input,
div.submenu {
  display: none;
}
.dd-menuTemp {
  position: absolute;
  /* border: 1px solid #ccc; */
  border-radius: 0 15px 15px 15px;
  /* padding: 0; */
  /* margin: 173px 0 0 0; */
  /* box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%); */
  background-color: var(--color-01);
  /* list-style-type: none; */
  width: 200%;
  /* height: 8.5rem; */
  /* z-index: 1; */
  left: 0;
  top: 3rem;
}
.text-btn {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--text-01);
}
.container-btnsDropTem {
  display: flex;
  justify-content: flex-start;
  /* padding-right: 2rem; */
  padding-top: 2rem;
}
.container-btnsDropTem button {
  background: var(--color-18);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  letter-spacing: 0.14px;
  color: var(--text-02);
  border: none;
  width: 10vw;
  margin-right: 2rem;
  padding: 1rem 0;
}

@media (max-width: 1024px) {
  .dd-menuTemp {
    width: 77vw;
  }
  .container-btnsDropTem button {
    width: 18vw;
    margin-right: 0;
  }

  .container-btnsDropTem {
    justify-content: space-between;
  }
}
@media (min-width: 1025px) and (max-width: 1335px) {
  .dd-menuTemp {
    width: 77vw;
  }
  .container-btnsDropTem button {
    width: 18vw;
    margin-right: 0;
  }
  .container-btnsDropTem {
    justify-content: space-between;
  }
}
@media (min-width: 1336px) and (max-width: 1439px) {
  .container-btnsDropTem button {
    width: 13vw;
    margin-right: 0;
  }
  .container-btnsDropTem {
    justify-content: space-between;
  }

  .dd-menuTemp {
    width: 57vw;
  }
}
/* @media (min-width: 1600px) {
} */
</style>

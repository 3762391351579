<template>
  <div class="header-container">
    <div class="newCamp">
      <router-link :to="`/${url}`" class="aHeader">
        <img
          class="imgArrow"
          src="../../assets/icons/others/ArrowLeft.svg"
          alt=""
        />
      <p class="title titleDark">{{text}}</p>
      </router-link>
    </div>
    <div class="btnsCamp">
     <router-link :to="`/${url3}`" class="templatesHead"> 
      <button class="btn template btnNewBlack" :class="backColor">Mis plantillas</button>
       </router-link> 
      <router-link :to="`/${url2}`" class="templatesHead">
        <button class="btn btnNewBlack ">Borradores</button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: ["url","url2","url3","text","backColor"],
  data() {
    return {
      to: this.url||this.url2||this.url3
      
    };
  },
};
</script>
<style scoped>
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}
.newCamp {
  display: flex;
}
.imgArrow {
  width: 1rem;
}

.title {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--color-17);
  padding-left: 2rem;
}
.btnsCamp {
  display: flex;
}
.btn {
  background-color: var(--color-01);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--borderRadius-1);
  border: none;
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  letter-spacing: 0.14px;
  color: var(--color-03);
  padding: 0.7rem 0;
  cursor: pointer;
  width: 12vw;
}
.template {
  margin-right: 1rem;
}
.btn-template{
  background-color: var(--color-03);
  color:var(--color-01)
}
@media (max-width: 850px) {
 
  .title {
    font-size: 1.375em;
    padding-left: 1rem;
  }
  .btn {
    width: 21vw;
  }
   .btn {
    padding: 0.7rem 1.5rem;
  }
  }
  @media (min-width: 850px) and (max-width: 1000px) {

  .btn {
    width: 17vw;
  }}
    @media (min-width:1600px)  {

  .btn {
    width: 11vw;
  }}
    @media (min-width:1700px)  {

  .btn {
    width: 10vw;
  }}
   @media (min-width:1800px)  {

  .btn {
    width: 9vw;
  }}

</style>

<template>
  <div class="container-creditsComp  " :class="border">
    <img :class="imgWidth" src="../../assets/img/iconOrange/dollar.svg" alt="" />
    <p class="pCredits">Créditos <span class="span-text">  {{ text }} </span>: </p>
      <span class='span-cred'>{{credits}}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    border: {
      type: String,
      default: "",
    },
    imgWidth: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      credits: 2000,
    };
  },
};
</script>

<style scoped>
.container-creditsComp {
  /* width: 70%;  */
  display: flex;
  height: 2rem;
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
  justify-content: center;
  border: 0.7px solid var(--color-03);
  align-items: center;
  font-size: 0.875em;
  /* border:2px solid red */
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 0 .5rem
}

img {
  width: 1.231em;
  padding-right: 3px;
}
.img {
  width: 1.563em;
}

.pCredits,.span-cred {
  font-family: var(--font-family-2);
  font-weight: 600;
  color: var(--color-17);
}
.span-cred {
  font-weight:normal;
  margin-left:0.5rem
}

.container-CreditsTwo {
  font-size: 1.063em;
  border: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  height: 3rem;
  width: 89%;
}
@media  (max-width: 991px) {
  .container-creditsComp {
    font-size: 0.625em;
/*     width: 98%; */
  }
p{
  font-size: 1.05em
}
.span-text{
  display:none;
}
.span-cred {
  font-weight:normal;
  margin-left:0.2rem
}
.container-CreditsTwo {

  width: 100%;
}
}
@media (min-width: 991px) and (max-width: 1199px){
  .container-creditsComp {
    font-size: 0.7em;
    /* width: 85%; */
  } 


}
@media (min-width: 1200px) and (max-width: 1439px) {
  .container-creditsComp {
    font-size: 0.7em;
  }
    .container-CreditsTwo {
  font-size: 0.8em;

}
}
</style>

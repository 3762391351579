<template>
  <div>
    <div>
    <modal
      class="ModalTestTemplate"
      name="ModalTestTemplate"
      :scrollable="true"
      width="75%"
      height="auto"
    >
      <div class="container">
        <div class="container-imgBtn">
          <img
            class="imgX"
            src="../../assets/icons/others/x.svg"
            @click="$modal.hide('ModalTestTemplate')"
          />
        </div>
        <div class="container-body row">
          <div class="col-sm-3 details">
            <p class="tex-detail">Detalles</p>
            <label for="check">
              <div class="dropdown">
                <div class="dd-button details-options">
                  <input id="check" type="checkbox" name="menu" class="caVar" />
                  <p>
                    Campos Variables
                  </p>
                  <div class="submenu  dd-menu">
                    <div>
                      <div class="variableFields">
                        <span class="close"></span>
                      </div>
                      <div class="fields">
                        <div class="viariables">
                          <p>[:agnnombre]</p>
                          <p>[:agnrut]</p>
                          <p>
                            [:agnestado]
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </label>
            <button @click="chargeLinks" class="details-options">Enlaces</button>
          </div>
          <div class="col-sm-6">
            <p class="text-title">Prueba de {{ title }}</p>
            <img class="img-test" src="../../assets/img/refe.png" alt="" />
            <div @click="chargeLinks" v-show="links" class="sobre">
              <div class="inpOne">
                <input
                  class="inpVar"
                  type="text"
                  value="Https://www.beneficioafp.cl"
                />
              </div>
              <div class="inpTwo">
                <input
                  class="inpVar"
                  type="text"
                  value="Https://www.afppls.cl"
                />
              </div>
              <div class="inpthree">
                <input
                  class="inpVar inpvarShort"
                  type="text"
                  value="[:agnnombre] "
                />
              </div>
              <div class="inpFour">
                <input
                  class="inpVar inpvarShort"
                  type="text"
                  value="[:agnrut]"
                />
              </div>
              <div class="inpFive">
                <input
                  class="inpVar"
                  type="text"
                  value="https://www.afpfacil.cl
"
                />
              </div>
            </div>
            <router-link to="/mail/nueva-camp-mail" class="linkModalTest">
              <button class="seeExample" @click="sendMailPre">
                Ver ejemplo
              </button>
            </router-link>
          </div>

          <div class="col-sm-3 details ">
            <p class="tex-detail">Opciones</p>
            <button class="details-options">Modificar platilla</button>
            <button class="details-options">Prueba contenido</button>
          </div>
        </div>
      </div>
    </modal>
    </div>
    <ModalPrevTempMail></ModalPrevTempMail>
  </div>
</template>
<script>
import ModalPrevTempMail from "../modals/ModalPrevTempMail";
export default {
  components: {
    ModalPrevTempMail,
  },
  data() {
    return {
      title: "mail n1",
      links: false,
    };
  },
  methods: {
    sendMailPre() {
      this.$modal.show("modalPrevMail");
    },
    chargeLinks() {
      if (!this.links) {
        this.links = true;
      } else {
        this.links = false;
      }
    },
  },
};
</script>
<style scoped>
a.linkModalTest{
  width: 100%;
  background: none
}

.col-sm-6 {
  flex-basis: 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
}
.imgX {
  cursor: pointer;
}
.container-imgBtn {
  display: flex;
  margin: 1rem 2rem 0.5rem 0rem;
  justify-content: flex-end;
}
.seeExample {
  background-color: var(--color-12);
  border-radius: var(--borderRadius-1);
  border: none;
  font-family: var(--font-family-2);
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  font-weight: bold;
  color: var(--color-01);
  width: 15vw;
  margin: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  /*   margin-bottom: 5rem */
}
.text-title {
  font-size: 1.563em;
  color: var(--color-12);
  margin-bottom: 2rem;
}
.details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-title,
.tex-detail {
  font-family: var(--font-family-3);
  text-align: center;
  letter-spacing: 0.694286px;
}
.tex-detail {
  font-size: 1.375em;
  color: var(--text-01);
  margin-bottom: 1rem;
}
.details-options,
.dd-button {
  background: var(--color-18);
  border: 1px solid var(--color-18);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 0.938em;
  text-align: center;
  color: var(--text-02);
  width: 73%;
  padding: 0.3rem 0;
  margin-bottom: 1rem;
  cursor: pointer;
}

/* drop */
label {
  width: 100%;
  margin-bottom: 0.8rem;
}
.dropdown {
  /* display: inline-block; */
  position: relative;
  width: 100%;
  cursor: pointer;
  height: 2rem;
}
.dd-button {
  display: inline-block;
  /* border: 1px solid gray; */
}

.submenu {
  /*  border: 2px solid  red; */
}
.caVar:checked ~ div.submenu {
  display: block;
}
.caVar,
div.submenu {
  display: none;
}
.dd-menu {
  position: absolute;
  /* border: 1px solid #ccc; */

  border: 1px solid var(--color-03);
  /* padding: 0; */
  /* margin: 173px 0 0 0; */
  /* box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%); */
  background-color: var(--color-01);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  /* list-style-type: none; */
  width: 74%;
  /* height: 8.5rem; */
  /* z-index: 1; */
  left: 13%;
  top: 2.5rem;
}
.close {
  content: url(../../assets/img/iconGrey/closeCircle.svg);
}
.camp {
  font-size: 0.938em;
}
.variableFields {
  display: flex;
  /* justify-content: flex-start; */
  /* padding: .5rem; */
  padding: 0.5rem 0 0 0.5rem;
}
.fields {
  display: flex;
  justify-content: center;
}
.viariables {
  width: 66%;
  text-align: start;
  margin: 0.5rem 0;
}
.viariables p {
  padding-bottom: 0.5rem;
}
.img-test {
  width: 30.25em;
  height: 31.563em;
  /* position: absolute; */
}
.sobre {
  width: 30.25em;
  height: 31.55em;
  background: linear-gradient(0deg, rgba(5, 5, 15, 0.7), rgba(5, 5, 15, 0.7));
  position: absolute;
  top: 7.3rem;
}
.inpVar {
  text-align: center;
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgb(0 0 0 / 38%);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-3);
  font-size: 1.188em;
  letter-spacing: 0.694286px;
  color: #111111;
  width: 63%;
  border: none;
  padding: 0.3rem 0;
}
.inpvarShort {
  width: 35%;
}
.inpOne {
  display: flex;
  padding: 1rem;
}
.inpTwo {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  margin-top: 3rem;
}
.inpthree {
  display: flex;
  /* padding: .5rem; */
  padding-left: 1rem;
}
.inpFour {
  margin-top: 11rem;
  display: flex;
  /* align-content: stretch; */
  padding-left: 4rem;
}
.inpFive {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}
@media (max-width: 1300px) {
  .seeExample,
  .text-title {
    font-size: 1.2em;
  }
  .details-options {
    font-size: 0.863em;
  }
  .tex-detail {
    font-size: 1.025em;
  }
  .seeExample {
    width: 20vw;
  }
  .img-test {
    width: 18em;
    height: 20em;
  }
  .sobre {
    width: 18em;
    height: 20em;
    top: 6.7rem;
  }
  .details {
    margin-top: 2rem;
  }
  .details-options,
  .dd-menu {
    width: 93%;
  }
  .inpVar {
    font-size: 10px;
    padding: 0;
  }
  .inpOne {
    padding: 0.5rem;
  }
  .inpTwo {
    margin-top: 2rem;
  }
  .inpFour {
    margin-top: 6rem;
  }
  .inpFive {
    margin-top: 1.5rem;
  }
  .dd-menu {
    left: 3.5%;
  }
}
</style>

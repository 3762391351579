<template>
  <div>
    <div class=" containter-contact">
      <router-link to="/" class="linkFreQ">
        <img class="imgArrow"   src="../../assets/icons/others/ArrowLeft.svg" alt="" />
      </router-link>
      <p class="title titleHelpCe">Centro de ayuda</p>
    </div>
    <div class="container-quest">
      <p class="title2 title2HelpCe">Preguntas frecuentes</p>
      <div class="c">
        <input type="checkbox" id="faq-1" />
        <h1 class=" cuesHelpCe">Pregunta #1<label for="faq-1"></label></h1>
        <div class="p">
          <p>This a very very simple accordion.</p>
        </div>
      </div>
      <div class="c">
        <input type="checkbox" id="faq-2" />
        <h1 class=" cuesHelpCe">Pregunta #2<label for="faq-2"></label></h1>
        <div class="p">
          <p>This a very very simple accordion.</p>
        </div>
      </div>
      <div class="c">
        <input type="checkbox" id="faq-3" />
        <h1 class=" cuesHelpCe">Pregunta #3<label for="faq-3"></label></h1>
        <div class="p">
          <p>
            I was inpired by an article on css-tricks.
            <a href="https://css-tricks.com/the-checkbox-hack/"
              >link to article</a
            >
          </p>
        </div>
      </div>
      <div class="c">
        <input type="checkbox" id="faq-4" />
        <h1 class=" cuesHelpCe">Pregunta #4<label for="faq-4"></label></h1>
        <div class="p">
          <p>
             I was inpired by an article on css-tricks.
            <a href="https://css-tricks.com/the-checkbox-hack/"
              >link to article</a
            >
          </p>
        </div>
      </div>
      <div class="c">
        <input type="checkbox" id="faq-5" />
        <h1 class=" cuesHelpCe">Pregunta #5<label for="faq-5"></label></h1>
        <div class="p">
          <p>
            I was inpired by an article on css-tricks.
            <a href="https://css-tricks.com/the-checkbox-hack/"
              >link to article</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.containter-contact {
  background-color: var(--color-01);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--borderRadius-1);
  display: flex;
  align-items: center;
  padding: 1rem 3rem 1rem 2.5rem;
  width: 24.5rem;
  margin-bottom: 3.5rem;
}
.title,
.title2 {
  font-family: var(--font-family-2);
  font-weight: 600;
  color: var(--text-01);
  font-size: 1.563rem;
}
.title {
  margin-left: 2rem;
}
.title2 {
  text-align: start;
  margin-bottom: 2rem;
}
.container-quest {
  border-radius: var(--borderRadius-1);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  background-color: var(--color-1);
  padding: 2rem 2rem 1rem 2rem;
  margin-bottom: 3rem
}

/* acord */
div.c {
  position: relative;
  /*  margin:2em; */
  margin-bottom: 1rem;
}
input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: 0;
}
h1 {
  background: var(--color-01);
  padding: 1em;
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 0.938em;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--text-02);
  letter-spacing: 0.694286px;
  font-weight: normal;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

label::before {
  content: url(../../assets/icons/others/ArrowDown.svg);
  width: 2em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  /* transform: rotate(90deg); */
  color: var(--color-03);
}
label {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}
div.p {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;
  top: 0.5rem;
}

div.p p {
  padding: 2em;
}
input:checked ~ h1 label::before {
  transform: rotate(180deg);
}
input:checked ~ h1 ~ div.p {
  max-height: 100px;
}
/* a {
  color: steelblue;
} */
.linkFreQ{
  width: 0;
  background-attachment: none;
}
@media (max-width: 1024px) {
  .title,.title2{
    font-size: 1.25em
  }
  .containter-contact {
    width: 19.5rem;
  }
  .imgArrow {
    width: 1rem;
}
}

</style>

<template>
  <div class="container-allSingle">
    <backTo text="Volver atrás"></backTo>
    <div class="container-singleMail ">
      <p class="text-header textWhite">Mail</p>
      <div>
        <PeaksMail></PeaksMail>
      </div>
      <div class="row">
        <div class=" col-sm-12 col-md-12 col-lg-6 general-mail">
          <GeneralMail></GeneralMail>
        </div>
         <div class="col-sm-12 col-md-12 col-lg-6 general-mail">
          <ClicksMail></ClicksMail>
        </div>
      </div>
       <div class="row">
        <div class=" col-sm-12 col-md-12 col-lg-6  general-mail ">
          <ReadingsMail></ReadingsMail>
        </div>
         <div class="col-sm-12 col-md-12 col-lg-6  general-mail">
           <OpeningsMail></OpeningsMail>
        </div>
    
      </div>
             <div class="row">
        <div class=" col-sm-12 col-md-12 col-lg-6  general-mail ">
          <DropMailDomains></DropMailDomains>
        </div>
         <div class="col-sm-12 col-md-12 col-lg-6  general-mail">
           <DropDateDetail></DropDateDetail>
        </div>
    
      </div>
    </div>
  </div>
</template>
<script>
import backTo from "../common/BackTo";
import PeaksMail from "../panels/PeaksMail";
import GeneralMail from "../panels/GeneralMail";
import ClicksMail from '../panels/ClicksMail';
import ReadingsMail from '../panels/ReadingsMail';
import OpeningsMail from '../panels/OpeningsMail';
import DropMailDomains from '../buttons/DropMailDomains'
import DropDateDetail from '../buttons/DropDateDetail'
export default {
  components: {
    backTo,
    PeaksMail,
    GeneralMail,
    ClicksMail,
    ReadingsMail,
    OpeningsMail,
    DropMailDomains,
    DropDateDetail
 
  },
};
</script>
<style scoped>
.container-allSingle {
  width: 100%;
}
.container-singleMail {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  padding: 1rem 2rem;
}
.text-header {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--color-17);
  text-align: start;
  padding-left: 2rem;
  padding-bottom: 2rem;
}
.general-mail {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
</style>

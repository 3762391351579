<template>
  <div class="container-readings">
    <div class="readings-header">
      <p class="title-readings textWhite">Lecturas</p>
      <p class="text-readings textWhite">
        Detalle de las aperturas <br />
        únicas y totales del correo
      </p>
    </div>
    <div class="chart-readings">
      <apexchart
      class="charReadings"
        type="line"
        height="200"
        :options="opens.chartOptions"
        :series="opens.series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      opens: {
        series: [
          {
            name: "Aperturas totales",
            data: [750, 1254, 1145, 1000, 1235, 1025, 1073],
          },
          {
            name: "Aperturas únicas",
            data: [500, 625, 589, 550, 601, 450, 600],
          },
        ],
        chartOptions: {
          colors: ["#475789", "#00cccc"],
          chart: {
            toolbar: {
              show: false,
            },
            width: "100%",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
            width: 2,
          },
          markers: {
            size: 3,
          },
          legend: {
            position: "bottom",
            labels: {
              colors: undefined,
            },
          },
          xaxis: {
            categories: [
              "16-11-2019",
              "17-11-2019",
              "18-11-2019",
              "19-11-2019",
              "20-11-2019",
              "21-11-2019",
              "22-11-2019",
            ],
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          grid: {
            padding: {
              left: 0,
              right: 10,
              bottom: 5,
              top: 0,
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.container-readings {
  background: var( --color-01);
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  border-radius: var( --borderRadius-1);
  width: 97%;
  padding: 1rem 1rem 0 1rem;
}

.title-readings,
.text-readings {
  font-family: var(--font-family-3);
  letter-spacing: 0.694286px;
  color: var(--color-17);
  text-align: start;
}
.title-readings {
  font-weight: 500;
  font-size: 1.25em;
}
.text-readings {
  font-size: 1em;
  margin-top: 1rem;
}
@media (min-width: 1200px) and (max-width: 1407px){
   .title-readings {

  font-size: 1.063em
} 
.text-readings{
    font-size: 0.875em
}
}
</style>

<template>
  <div class="container-contacta">
    <div class="title">
      <p class="p-titleContact">Contactabilidad</p>
    </div>
    <div class="container-body">
      <div class="rowOne">
        <div class="container-link">
          <router-link to="/SMS">
         <div class="body-link">
              <div class="all">
                <span class="comment"></span>
                <p class="p-dataContacta">{{ percMail }}%</p>
                <p class="parrafContacta">
                  Efectividad en campañas
                </p>
              </div>
              <div class="footer-link">
                <span class="arrowRight"></span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="container-link">
          <router-link to="/mail">
            <div class="body-link">
              <div class="all">
                <span class="mail"></span>
                <p class="p-dataContacta">{{ percMail }}%</p>
                <p class="parrafContacta">
                  Efectividad en campañas
                </p>
              </div>
              <div class="footer-link">
                <span class="arrowRight"></span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="rowTwo">
        <div class="container-link">
          <router-link to="/mail">
            <div class="body-link">
              <div class="all">
                <span class="calling"></span>
                <p class="p-dataContacta">{{ percPhon }}%</p>
                <p class="parrafContacta">
                  Efectividad en campañas
                </p>
              </div>
              <div class="footer-link">
                <span class="arrowRight"></span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="container-link">
          <router-link to="/mail">
            <div class="body-link">
              <div class="all">
                <span class="alignBottom"></span>
                <p class="p-dataContacta">{{ numerData }}</p>
                <p class="parrafContacta">
                  Datos han sido trackeados
                </p>
              </div>
              <div class="footer-link">
                <span class="arrowRight"></span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-contact">
    <div>
      <p class="title">
        Contactabilidad
      </p>
    </div>
    <div class="body-contact">
      <div class="rowOne">
        <div class="service">
          <router-link to="/SMS">
            <div class="one">
              <i class="fa fa-comment-o icon"></i>
              <p class="pNumb">{{ percSMS }}%</p>
              <p class="parraf">
                Entregabilidad en campañas
              </p>
            </div>
            <div class="two">
              <i class="fa fa-angle-right"></i>
            </div>
          </router-link>
        </div>
        <div class="service">
          <router-link to="/mail">
            <div class="one">
              <i class="fa fa-envelope-o icon"></i>
              <p class="pNumb">{{ percMail }}%</p>
              <p class="parraf">
                Efectividad en campañas
              </p>
            </div>
            <div class="two">
              <i class="fa fa-angle-right"></i>
            </div>
          </router-link>
        </div>
      </div>
      <div class="rowTwo">
        <div class="service">
          <router-link to="/mail">
            <div class="one">
              <i class="fa fa-phone icon"></i>
              <p class="pNumb">{{ percPhon }}%</p>
              <p class="parraf">
                Efectividad en campañas
              </p>
            </div>
            <div class="two">
              <i class="fa fa-angle-right"></i>
            </div>
          </router-link>
        </div>
        <div class="service">
          <router-link to="/mail">
            <div class="one">
              <i class="fa fa-bar-chart icon"></i>
              <p class="pNumb">{{ numerData }}</p>
              <p class="parraf">
                Datos han sido trackeados
              </p>
            </div>
            <div class="two">
              <i class="fa fa-angle-right"></i>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
export default {
  data() {
    return {
      percSMS: 90,
      percMail: 60,
      percPhon: 78,
      numerData: 378,
    };
  },
};
</script>

<style scoped>
.container-contacta {
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  height: 100%;
  /*  padding: 0 0.5rem 0 0.5rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  justify-content: space-around;
}

.title {
  padding-top: 1rem;
  padding-left: 2.5rem;
  width: 100%;
}
.p-titleContact {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  text-align: start;
  color: var(--color-17);
  /* padding: 1rem 0 0 0.5rem; */
}
.container-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*   border: 2px solid teal; */
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.comment {
  content: url("../../assets/img/iconOrange/ChatOrange2.svg");
}
.mail {
  content: url("../../assets/img/iconOrange/MessageOrange2.svg");
}
.calling {
  content: url("../../assets/img/iconOrange/Calling2.svg");
}
.alignBottom {
  content: url("../../assets/img/iconOrange/align-bottom.svg");
}
.arrowRight {
  content: url("../../assets/img/iconGrey/arrowRigth.svg");
}
.rowOne,
.rowTwo {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.container-link {
  background-color: var(--color-01);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: var(--borderRadius-1);
  /*   padding: 1rem 1rem 0.5rem; */
  display: flex;
  align-items: center;
  width: 48%;
}
.rowOne {
  /*  border: 2px solid red; */
  margin-bottom: 0.5rem;
}
.rowTwo {
  /*  border: 2px solid black; */
}
.body-link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}
.p-dataContacta,.parrafContacta {
  font-family: var(--font-family-2);
  
  
}
.p-dataContacta {
  color:  rgba(64, 64, 64, 0.9);
  font-size: 1.875em;
  padding-left: 0.5rem;
}
p.parrafContacta {
  width: 70%;
  font-size: 0.938em;
  color: rgba(64, 64, 64, 0.8);
}
.footer-link {
  display: flex;
  justify-content: flex-end;
  width: 100%;
 /*  padding-top: 0.5rem; */
  margin-bottom: 0.5rem;
  margin-right: 1rem
}
.all {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/*.rowOne {
  display: flex;
  width: 95%;
}
.rowTwo {
  display: flex;
  width: 95%;
}

.service {
  /* border: 2px solid red; */
/*   margin: 0.5rem;
  background-color: var(--color-01);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: var(--borderRadius-1);
  padding: 1rem 1rem 0.5rem;
  width: 100%; */
/* }
.body-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}
.icon {
  font-size: 1.8em;
  color: var(--color-03);
  padding-right: 0.5rem;
}
p {
  font-family: var(--font-family-2);
  color: var(--color-17);
  font-weight: 400;
}
.pNumb {
  font-size: 1.8em;
}
.one {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.two {
  text-align: end;
}
.parraf {
  padding: 0.5rem;
}
.fa-angle-right {
  color: var(--color-15);
  font-size: 1em;
} 
@media (min-width: 768px) and (max-width: 1023px) { */
/*  .service {
    text-align: center;
  }
  .title {
    text-align: start;
  } 
}*/
@media  (max-width: 1024px){
  .rowOne,
  .rowTwo {
    flex-direction: column;
    height: 50%;
    justify-content: space-around
  }
  .rowOne {
    margin-bottom: 0;
  }
  .p-dataContacta {
    font-size: 1.125em;
  }
  p.parrafContacta {
    font-size: 0.863em;
    width: 50%;
  }
  .all {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .comment,
  .mail,
  .calling,
  .alignBottom {
    width: 15%;
  }
  .container-link {
    width: 100%;
    margin-bottom: 0.8rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .body-link{
    padding-top: 0;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  a {
    width: 100%;
  }
  .footer-link {
    width: 18%;
       /*  margin-right: .5rem; */
            justify-content: center;
            padding-top: 0;
            margin-bottom: 0;
            margin-right: 0
  }
  .p-titleContact {
    font-size: 1.25em;
  }
  .title {
    padding-top: 1rem;
    padding-left: 1rem;
    
}
.container-body {
    margin-bottom: 0.3rem;
        height: 100%;
    justify-content: center;
}
.container-contacta {
    margin-left: 0.5rem;
    width: 95%;
}
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .rowOne,
  .rowTwo {
    flex-direction: column;
    height: 50%;
    justify-content: space-around
  }
  .rowOne {
    margin-bottom: 0;
  }
  .p-dataContacta {
    font-size: 1.125em;
  }
  p.parrafContacta {
    font-size: 0.863em;
    width: 50%;
  }
  .all {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .comment,
  .mail,
  .calling,
  .alignBottom {
    width: 15%;
  }
  .container-link {
    width: 100%;
    margin-bottom: 0.8rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .body-link{
    padding-top: 0;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  a {
    width: 100%;
  }
  .footer-link {
    width: 18%;
       /*  margin-right: .5rem; */
            justify-content: center;
            padding-top: 0;
            margin-bottom: 0;
            margin-right: 0
  }
  .p-titleContact {
    font-size: 1.25em;
  }
  .title {
    padding-top: 1rem;
    padding-left: 1rem;
    
}
.container-body {
  
    margin-bottom: 0.3rem;
        height: 100%;
    justify-content: center;
}
.container-contacta {
    margin-left: -1rem;
}
}
@media (min-width: 1600px)  {
.container-body{
      height: 100%;
    justify-content: center;
}
.rowOne {
    margin-bottom: 2rem;
}
}


</style>

<template>
  <div class="wrapper">
    <textarea
      class="regular-input"
      name=""
      id="textArea"
      cols="30"
      rows="10"
      placeholder="Escribe el mensaje que deseas enviar"
      maxlength="160"
      v-on:input="countdown"
      v-model="input"
    ></textarea>
    <div class="iconCont">
      <input id="fileUpload" type="file" hidden />

      <div class="dCont">
        <p class="nCharac">{{ initialCount }} / {{ remainingCount }}</p>
      </div>
    </div>

    <emoji-picker @emoji="append" :search="search">
      <div
        class="emoji-invoker"
        slot="emoji-invoker"
        slot-scope="{ events: { click: clickEvent } }"
        @click.stop="clickEvent"
      >
        <span class="emoji"></span>
      </div>
      <div slot="emoji-picker" slot-scope="{ emojis, insert }">
        <div class="emoji-picker">
          <!--   <div class="emoji-picker__search">
            <input type="text" v-model="search" v-focus />
          </div> -->
          <div>
            <div v-for="(emojiGroup, category) in emojis" :key="category">
              <h5>{{ category }}</h5>
              <div class="emojis">
                <span
                  v-for="(emoji, emojiName) in emojiGroup"
                  :key="emojiName"
                  @click="insert(emoji)"
                  :title="emojiName"
                  >{{ emoji }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </emoji-picker>
  </div>
</template>
<script>
export default {
  data() {
    return {
      maxCount: 160,
      ceroCount: 0,
      initialCount: 0,
      remainingCount: 160,
      input: "",
      search: "",
    };
  },
  methods: {
    append(emoji) {
      if (this.input.length <= 159) {
        this.input += emoji;
      }
    },
    countdown() {
      this.remainingCount = this.maxCount - this.input.length;
      this.initialCount = this.ceroCount + this.input.length;
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>
<style scoped>
.dCont {
  padding-top: 0.1rem;
}
.iconCont {
  display: flex;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  justify-content: flex-end;
}
textarea::placeholder {
  font-size: 0.938em;
}

.nCharac {
  font-size: 0.625em;
  text-align: end;
  /*   padding-right: 1rem;
  padding-bottom: 0.5rem; */
}
textarea::placeholder,
.nCharac {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.694286px;
  color: var(--text-02);
}

.emoji {
  content: url(../../assets/img/iconLila/Emoji.svg);
  margin-right: 0.5rem;
}
.wrapper {
  position: relative;
  display: inline-block;
  border: 1px solid var(--color-05);
  border-radius: var(--borderRadius-1);
  margin-top: 1rem;
  width: 100%;
  outline: none;
}

.regular-input {
  width: 100%;
  resize: none;
  border: none;
  /* margin-top: 1rem; */
  outline: none;
  border-radius: var(--borderRadius-1);
  padding: 1rem 1.8rem;
  height: 4rem;
}

/* .regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66,153,225,.5);
} */

.emoji-invoker {
  position: absolute;
  /* top: 0.5rem; */
  right: 4rem;
  /* width: 1.5rem; */
  /* height: 1.5rem; */
  /* border-radius: 50%; */
  cursor: pointer;
  transition: all 0.2s;
  bottom: 0.3rem;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 2;
  font-family: Montserrat;
  border: 1px solid #ccc;
  /*  width: 15rem; */
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
</style>

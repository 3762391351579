<template>
  <div class="container-wlcBias">
    <div class="allWB">
      <div class="titleWB">
        <p>Bienvenido a BIAS</p>
      </div>
      <div class="tex-img">
        <div class="textBias">
          <p>
            Encontrarás la unificación de reporteria diaria, por campaña, envío
            individual, etc..........
          </p>
        </div>
        <div class="gifBias">
          <img class="biasGif" src="../../assets/gif/panels/BIAS.gif" alt="" />
          <img
            class="biasGifBlack"
            src="../../assets/gif/panelsBlack/biasBlack.gif"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container-wlcBias {
  background: var( --color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var( --borderRadius-1);
  display: flex;
  justify-content: center;
  height: 100%;
}
.allWB {
  width: 90%;
  /* border: 2px solid red; */
}
.titleWB {
  margin: 1rem 0;
}

.titleWB p {
  font-family: var(--font-family-2);
  font-weight: 800;
  font-size: 1.563em;
  letter-spacing: 2px;
  color: var(--color-17);
  text-align: start;
}
.textBias p {
  font-family:var(--font-family-2);
  font-weight: normal;
  font-size: 1.313em;
  letter-spacing: 0.14px;
  color: var(--text-01);
}
.tex-img {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.textBias {
  /* border: 2px solid green; */
  width: 50%;
  text-align: start;
}
.gifBias {
  /*  border: 2px solid rgb(83, 20, 141); */
  width: 50%;
}
.biasGifBlack {
  display: none;
}
img.biasGif,
.biasGifBlack {
  width: 100%;
}

@media (max-width: 992px) {
  .titleWB p {
    font-size: 1.25em;
  }
  .titleWB {
    margin-bottom: 0;
  }
  .tex-img {
    margin-top: 0;
  }
  .textBias p {
    font-size: 1.063em;
  }
 img.biasGif {
    width: 70%;
}

}
@media  (max-width: 1024px) {
.container-wlcBias{
  width: 60%;
}
img.biasGif {
    width: 70%;
}
.titleWB p {
    font-size: 1.25em;
  }
}
@media (min-width: 1024px) and (max-width: 1119px) {
.container-wlcBias{
  width: 95%;
}
.textBias p {
  
    font-size: 1.05em;
   
}
img.biasGif {
    width: 100% !important;
}
.allWB {
    width: 95%;
}

}
</style>

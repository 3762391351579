<template>
  <modal
    class="modal1"
    name="modalCredits"
    :width="500"
    :height="630"
    :adaptive="true"
  >
    <div class="container-imgBtn">
      <img
        class="imgX"
        src="../../assets/icons/others/x.svg"
        @click="$modal.hide('modalCredits')"
      />
    </div>
    <div class="container-modal">
      <div class="main-modal">
        <div class="container-bodyModal">
          <p class="titleMC">Comprar créditos</p>
          <div class="container-imgBody">
            <img
              class="imgModalCre"
              src="../../assets/gif/modals/56150-capital-investment.gif"
              alt=""
            />  <img
              class="imgModalCreBlack"
              src="../../assets/gif/modalsBlack/bulbBlack.gif"
              alt=""
            />
          </div>
          <div class="container-pInput">
            <p class="credNeed">¿Cuántos créditos necesitas?</p>
            <input class="inputMC" type="text" placeholder="N° de créditos" />
          </div>
        </div>
        <div class="container-footer">
          <p class="subTitleMC">Servicios disponibles</p>
          <div class="container-switch row middle-md">
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
            <span class="sMail mailMoCre">Mail</span>

            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
            <span class="sMail">SMS</span>
          </div>
          <div class="row end-sm dTotal">
            <p class="total totCol">Total:</p>
            <span class="total">$ {{ credits }}</span>
          </div>
          <div class="row end-sm btns">
            <button @click="$modal.hide('modalCredits')" class="btn cancel">
              Cancelar
            </button>
            <button class="btn apply">Pagar</button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      credits: "90.000",
    };
  },
  computed: {
    /*   itemToShow: function () {
        return this.item
      }, */
  },

  methods: {
    /*   beforeOpen (event) {
        this.item = event.params.item;
      } */
  },
};
</script>

<style scoped>
.container-imgBtn {
  padding: 1rem 1rem 0;
  text-align: start;
}
.container-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-modal {
  width: 70%;
}
.titleMC {
  text-align: start;
  font-family: var(--font-family-2);
  font-weight: 600;
  color: var(--text-01);
  font-size: 1.5em;
}
.container-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
p, .credNeed {
  text-align: start;
  font-family: var(--font-family-2);
  font-size: 1.25em;
  color: var(--text-01);
}
input {
  border: none;
  border-bottom: 2px solid var(--color-03);
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  outline: none;
}
h3 {
  text-align: start;
}
.sMail {
  /*  margin-top: 0.2rem; */
  font-size: 1.25em;
  font-family: var(--font-family-2);
  color: var(--text-01);
  margin-left: 0.5rem;
}
.mailMoCre, .imgModalCreBlack{
  margin-right: 2rem;
}
.container-footer {
  padding-top: 1rem;
}
.container-switch {
  padding-top: 1rem;
}
.totCol {
  color: var(--color-03);
  padding-right: 0.5rem;
}
.total {
  font-family: var(--font-family-2);
  font-size: 1.25em;
}
.dTotal {
  margin-top: 1rem;
}
.cancel {
  background-color: var(--color-18);
  color: var(--text-02);
  margin-right: 0.5rem;
}
.apply {
  background-color: var(--color-03);
  color: var(--color-01);
}
.btn {
  border-radius: 15px;
  /*  margin:3rem 0.5rem; */
  width: 8rem;
  height: 2rem;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: var(--font-family-2);
  font-size: 1.06em;
}
.subTitleMC {
  font-family: var(--font-family-2);
  font-size: 1.25em;
  color: var(--text-01);
}
.btns {
  margin-top: 1.5rem;
}
.imgModalCre, .imgModalCreBlack {
  width: 80%;
}
.imgX {
  cursor: pointer;
}
/* switch */

.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 23px;
  /*  margin-top: 1rem; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-18);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 1px;
  bottom: 1px;
  background-color: var(--color-01);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 1px 3px rgb(101 101 101 / 33%);
}

input:checked + .slider {
  background-color: var(--color-03);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.imgModalCreBlack{
  display:none
}
@media(max-width: 1023px) {
  .titleMC {
    font-size: 1.25em;
  }
  p,.sMail,.total,.subTitleMC{
    font-size: 1.05em;
  }
  .imgModalCre, .imgModalCreBlack {
    width: 70%;
}
}
</style>

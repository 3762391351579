<template>
  <div class="container-btnContacts">
    <div class="top-btns">
      <div class="btnSup">
        <button class="btnContacts">Mis listas</button>
        <button class="btnContacts">Borradores</button>
      </div>
      <div class="btnDow">
        <button class="btnContacts">Contactos</button>
        <button class="btnContacts">Desuscritos</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container-btnContacts {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.top-btns {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  flex-direction: column;
      height: 100%;
}
.btnSup, .btnDow {
    display: flex;
    justify-content: space-between;
}
.btnSup{
  margin-bottom:1rem
}
button {
  background: var( --color-01);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: var( --borderRadius-1);
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  letter-spacing: 0.14px;
  color: var(--color-20);
  border: none;
  cursor: pointer;
  padding: 1rem 0;
  width: 12.5rem;
}
@media (max-width: 992px) {
  button {
    width: 9.5rem;
  }
  .top-btns {
    margin-top: 2rem;
  }
}
@media (min-width: 993px) and (max-width: 1199px) {
  button {
    width: 8.5rem;
    font-size: 0.875em;
  }
  .top-btns {
    margin-top: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  button {
    width: 11rem;
  }
}
</style>

<template>
  <div class="container-chartUrl">
    <div class="headerChartUrl">
      <div>
        <p class="numberUrl textWhite">8293</p>
        <p class="legendUrl textWhite">Total de clicks</p>
      </div>
      <div>
        <p class="numberUrl textWhite">8293</p>
        <p class="legendUrl textWhite">Nuevos enlaces</p>
      </div>
      <div>
        <p class="numberUrl textWhite">35%</p>
        <p class="legendUrl textWhite">Tráfico</p>
      </div>
      <div>
        <button class="btnChartUrl ">Ultimas 24 horas</button>
      </div>
    </div>
    <div class="container-chartUrlShort">
      <div id="chart">
        <apexchart
          type="bar"
          height="250"
          :options="chartOptions"
          :series="series"
          class="chartShortUrl"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      series: [
        {
          name: "Net Profit",
          data: [0.1, 0.3, 0.3, 0.1, 0.5, 0.5, 0.1],
        },
        {
          name: "Revenue",
          data: [0.5, 0.4, 0.1, 0.6, 0.4, 0.4, 0.5],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 200,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
        },
        yaxis: {
          title: {
            show: false,
          },
          min: 0,

          max: 0.6,
          forceNiceScale: true,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return "$ " + val + " thousands";
            },
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.container-chartUrl {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
}
.headerChartUrl {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid var(--color-06);
  padding-bottom: 1rem;
}
.numberUrl {
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 0.694286px;
  color: var(--color-17);
}
.legendUrl,
.numberUrl {
  font-family: var(--font-family-3);
  text-align: start;
}
.legendUrl {
  font-weight: 500;
  font-size: 0.938em;
  color: var(--text-02);
}
.btnChartUrl {
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 0.938em;
  text-align: center;
  color: var(--text-02);
  background: var(--color-18);
  border: 1px solid var(--color-18);
  box-sizing: border-box;
  border-radius: var(--borderRadius-1);
  padding: 0 0.5rem;
}
@media (max-width: 1024px) {
  .container-chartUrl {
    width: 90%;
    margin-top: 2rem;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .numberUrl {
    font-size: 1.25em;
  }

  .container-chartUrl {
    width: 100%;
    margin-top: 0;
  }
  .legendUrl,
  .btnChartUrl {
    font-size: 0.863em;
  }
}
</style>

<template>
  <label for="check">
    <div class="dropdown">
      <div class="dd-button">
        <img
          class="dollar"
          src="../../assets/img/iconOrange/dollar.svg"
          alt=""
        />
        <input id="check" type="checkbox" name="menu" />
        <p>
          Créditos: <span class="span-credi">{{ credits }} </span> <label for="check" class="arrowD1 "> </label>
        </p>
        <div class="submenu  dd-menu">
          <div class=" row middle-sm   rowLi">
            <div class=" one">
              <div class="row middle-sm  smsDrop">
                <img
                  class="ellipse"
                  src="../../assets/icons/others/EllipseLila.svg"
                  alt=""
                />
                <p class="smsMail">SMS: {{ smsCredits }}</p>
              </div>
              <div class="row middle-sm mailDrop ">
                <img
                  class="ellipse"
                  src="../../assets/icons/others/EllipseGold.svg"
                  alt=""
                />
                <p class="smsMail">Mail: {{ mailCredits }}</p>
              </div>
            </div>
            <div id="chart">
              <apexchart
                type="donut"
                width="150"
                :options="options"
                :series="series"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      credits: 3298,
      smsCredits: 298,
      mailCredits: 3000,
      series: [this.calcPerc(298, 3298), this.calcPerc(3000, 3298)],
      options: {
         
        chart: {
          type: "donut",
        },
        colors: ["#9D8CF1", "#E7AC4F"],
        labels: ["SMS", "Mail"],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
      
      },
    };
  },
  mounted() {},
  methods: {
    calcPerc(numerador, denominador) {
      /* console.log(numerador + "hola"); */
      return Math.round((numerador / denominador) * 100);
    },
  },
};
</script>

<style scoped>
.dropdown {
  /* display: inline-block; */
  position: relative;
  width: 100%;
  cursor: pointer;
}
.dd-button {
  display: inline-block;
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  padding: 12px 20px 12px 20px;
  background-color: var(--color-01);

  white-space: nowrap;
  /* width: 100%; */
  font-size: 15px;;
  display: flex;
  align-items: center; 
  justify-content: space-around;
  /* z-index: 1000; */
  /* margin-top: 2rem; */
  font-family: var(--font-family-2);
  font-weight: 600;
  color: var(--color-17);
}
p {
  margin-left: 0.5rem;
}
.i{


font-weight: normal;
font-size: 15px;

/* identical to box height, or 160% */



}
label.arrowD1::before {
  content: url(../../assets/img/iconGrey/ArrowDownLi.svg);
  padding-left: 0.5rem;
  cursor: pointer;
}
input:checked + p label::before {
  content: url(../../assets/img/iconGrey/ArrowUp.svg);
  cursor: pointer;
}
.submenu {
  /*  border: 2px solid  red; */
}
input:checked ~ div.submenu {
  display: block;
}
input,
div.submenu {
  display: none;
}
.dd-menu {
  position: absolute;
  /* border: 1px solid #ccc; */
  /*     border-radius: 0 15px 15px 15px;
 */ /* padding: 0; */
  /* margin: 173px 0 0 0; */
  /* box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%); */
  background-color: var(--color-01);
  /* list-style-type: none; */
  /*     width: 140%;
 */ /* height: 8.5rem; */
  /* z-index: 1; */
  left: 0;
  /*     top: 2.2rem;
 */
  top: 2.2rem;
  width: 100%;
  border-radius: 0 0 15px 15px;
  height: 320%;
}
.one {
  display: flex;
  /*   flex-direction: column;
 */ /*   padding:3rem;
  padding-left:3rem */
  /*  padding-top: 1rem; */
  /*   padding-left: 1.5rem;
 */
  flex-direction: row;
  padding-left: 0;
  margin-top: 1.5rem;
}

.mailDrop {
  font-weight: 600;
  margin-left: 0.5rem;
}
.row.middle-sm.mailDrop,
.row.middle-sm.smsDrop {
  flex-wrap: nowrap;
}
.smsDrop {
  /*   padding-bottom: 1.5rem;
 */
  font-weight: 600;

  padding-bottom: 0;
}
.smsMail {
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 1em;
}
.rowLi {
  /*   flex-wrap: nowrap;
 */ /* justify-content: space-between; */
  flex-direction: column;
}
.ellipse {
  width: 0.5rem;
}
div#chart {
  margin-left: 0rem;
 /*  width: 8rem;
  height: 7.5rem; */
}

@media (max-width: 1024px) {
   .smsMail {
    font-size: 0.8em;
  }
  .one {

    margin-top: 0.5rem;
}
  .dd-menu {

    height: 270%
}
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .dd-button {
    font-size: 0.938em;
  }
  img.dollar {
    width: 10%;
  }
  .one{
  
    margin-top: 0.5rem;
}
 /*  .dd-menu {
    top: 2rem;
    width: 100%;
    border-radius: 0 0 15px 15px;
    height: 8.9rem;
  } */
  .grafic {
    /* display:none */
  }
 /*  .rowLi {
    /* display: flex; */
    /*flex-direction: column;
  } */
  /* .one {
    flex-direction: row;
    padding-left: 0;
    margin-top: 1rem;
  } */
/* 
  .smsDrop {
    padding-bottom: 0;
  } */
/*   .mailDrop {
    margin-left: 0.5rem;
  } */
  .smsMail {
    font-size: 0.8em;
  }
  .ellipse {
    width: 0.5rem;
  }
/*  */
  .dd-menu {

    height: 285%;
}
}
@media (min-width: 1600px) {
  .dd-menu {
    height: 335%;
  } 
  div#chart {
  margin-top:0.5rem
 /*  width: 8rem;
  height: 7.5rem; */
}
p {
    margin-left: 0; 
}
.dd-button {

    justify-content: space-evenly;
}
.one {

    width: 90%;
    justify-content: space-evenly;
}
}
</style>

<template>
  <div class="container-formBtns">
    <div class="container-form">
      <div class="container-left">
        <div class="head-contDrop">
          <label for="checkNewCamData">
            <div class="dropdown">
              <div class="dd-buttonData dd-buttonBlackPrev">
                <input
                  id="checkNewCamData"
                  type="checkbox"
                  name="menu"
                  class="inputChe"
                />
                <p class="titleDropPre">
                  Datos campaña
                  <label for="checkNewCamData" class="arrowDNewCamMail ">
                  </label>
                  <label for="checkNewCamData" class="arrowDNewCamMailD ">
                  </label>
                </p>
                <div class="submenu  dd-menuCampMail">
                  <div class="colorSupPre"></div>
                  <div class="menu-body">
                    <div class="left-menu">
                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody">Nombre</span>
                      </div>

                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody">Asunto</span>
                      </div>
                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody">Remitente</span>
                      </div>
                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody">Lista de contacto</span>
                      </div>
                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody">Plantilla</span>
                      </div>
                    </div>
                    <div class="right-menu">
                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody titleMenuRi"
                          >*Cargar archivo</span
                        >
                      </div>
                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody titleMenuRi"
                          >*Enlazar evento</span
                        >
                      </div>
                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody titleMenuRi"
                          >*Enlazar encuesta</span
                        >
                      </div>
                      <div class="dBtn">
                        <button class="btn-validate" disabled="disabled">
                          Validar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>
        <div class="body-left">
          <div class="inputs-left">
            <p class="title-input">Nombre de la campaña</p>
            <input class="input-left" type="text" v-model="value01" />
          </div>
          <div class="inputs-left">
            <p class="title-input">Asunto</p>
            <input class="input-left" type="text" />
          </div>
          <div class="inputs-left">
            <p class="title-input">Correo remitente</p>
            <input class="input-left" type="text" />
          </div>
          <div class="inputs-left">
            <p class="title-input">Lista de contacto</p>
            <input class="input-left" type="text" />
          </div>
          <div class="inputs-left">
            <p class="title-input">Archivo adjunto</p>
            <input class="input-left" type="text" />
          </div>
          <div class="foo-btns">
            <div class="dd " @click="activeOne">
              <div v-show="active" class="dd-aPrev ">
                <span>Programar envío</span>
              </div>
              <input v-show="active" class="dd-input" type="checkbox" />
              <div class="dd-cPrev dd-cOne">
                <input
                  class="timeDatePrev dateofPrev dateofbirth2"
                  type="date"
                />
                <input
                  class="timeDatePrev  inpHourNewPrev inpHourNew2"
                  type="time"
                />
                <button class="btnSave ">
                  Guardar
                </button>
              </div>
            </div>
            <div class="dd dd-two" @click="activeTwo">
              <div v-show="active2" class="dd-aPrev ">
                <span>Enviar prueba</span>
              </div>
              <input v-show="active2" class="dd-inputSend" type="checkbox" />
              <div class="dd-cPrev dd-cTwo">
                <p class="textSenMail">
                  Ingrese los correos a los que deseas enviar la prueba
                </p>
                <div class="inputBtn">
                  <input class="input-mail " type="text" />
                  <button class="btnSave btnSend">
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-right">
        <div class="head-contDrop">
          <label for="checkNewCampMail">
            <div class="dropdown">
              <div class="dd-buttonSend dd-buttonBlackPrev">
                <input
                  id="checkNewCampMail"
                  type="checkbox"
                  name="menu"
                  class="inputChe"
                />
                <p class="titleDropPre">
                  Resumen / Envío<label
                    for="checkNewCampMail"
                    class="arrowDNewCamMail "
                  >
                  </label>
                  <label for="checkNewCampMail" class="arrowDNewCamMailD ">
                  </label>
                </p>
                <div class="submenu dd-menuCampMail">
                  <div class="colorSupPre"></div>
                  <div class="menu-bodySend">
                    <div class="left-menuSend">
                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody">Resumen</span>
                      </div>

                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody titleMenuRi"
                          >*Programar envío</span
                        >
                      </div>
                      <div class="inpLab">
                        <label class="custom-radio-checkbox-mail">
                          <input
                            class="custom-radio-checkbox__input-mail"
                            type="checkbox"
                            name=""
                            value=""
                          />
                          <span
                            class="custom-radio-checkbox__show-mail custom-radio-checkbox__show--checkbox-mail"
                          ></span> </label
                        ><span class="titleMenuBody titleMenuRi"
                          >*Enviar prueba</span
                        >
                      </div>
                    </div>
                    <div class="dBtn">
                      <button class="btn-validate" disabled="disabled">
                        Validar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>
        <div class="body-right">
          <div class="divIconExp">
            <span @click="openModal" class="iconExp"></span>
          </div>
          <div>
            <img src="../../assets/img/refe.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <button
        @click="$emit('change-component', 'PreviewCampMail')"
        class="btn-foo btn-fooOne"
      >
        Atrás
      </button>
      <button class="btn-foo btn-fooTwo">Guardar borrador</button>
      <button @click="send" class="btn-foo btn-fooThree">Enviar</button>
    </div>
    <ModalCommon
      title01="Envío de campaña exitoso!"
      subtitle01="Tu campaña se envió correctamente"
      textBtn01="Finalizar"
      :img2="img2"
      name01="modal01"
      :img2Dark="img2Dark"
      url="mail"
    ></ModalCommon>
    <ModalPrevTempMail></ModalPrevTempMail>
    <ModalTestTemplate></ModalTestTemplate>
  </div>
  <!--   <div>
      <h1>Example Two</h1>
      <button @click="$emit('change-component', 'example 1')">Load Example One</button>
    </div> -->
</template>
<script>
import ModalCommon from "../modals/ModalCommon";
import ModalPrevTempMail from "../modals/ModalPrevTempMail";
import ModalTestTemplate from "../modals/ModalTestTemplate";
import img02 from "../../assets/gif/modals/lamp.gif";
import img2Dark from "../../assets/gif/modalsBlack/lampBlack.gif";
export default {
  components: {
    /*   DropCampData, */

    ModalCommon,
    ModalPrevTempMail,
    ModalTestTemplate,
  },
  data() {
    return {
      active: true,
      active2: true,
      img2: img02,
      img2Dark: img2Dark,
      value01: "",
    };
  },
  created() {
    console.log("creado");

    this.$root.$on("name-cam", (msg) => {
      this.value01 = msg;
      console.log(this.value01);
    });
  },
  methods: {
    activeOne() {
      if (this.active == true && this.active2 == true) {
        this.active = true;
        this.active2 = false;
      } else {
        this.active2 = true;
      }
    },
    activeTwo() {
      if (this.active2 == true && this.active == true) {
        this.active2 = true;
        this.active = false;
      } else {
        this.active = true;
      }
    },
    send() {
      this.$modal.show("modal01");
    },
    sendMail() {
      this.$modal.show("modalPrevMail");
    },
    openModal() {
      this.$modal.show("ModalTestTemplate");
    },
  },
};
</script>
<style scoped>
.container-form {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.container-left,
.container-right {
  width: 47%;
}
.colorSupPre {
  background-color: var(--color-05);
  height: 0.5rem;
}
.dropdown {
  /* display: inline-block; */
  position: relative;
  width: 100%;
}
.dd-buttonData,
.dd-buttonSend {
  display: inline-block;
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  padding: 12px 20px 12px 20px;
  background-color: var(--color-05);
  white-space: nowrap;
  /* width: 100%; */
  font-size: 1.06em;
  display: flex;
  /* align-items: center; */
  /* z-index: 1000; */
  /* margin-top: 2rem; */
  font-family: var(--font-family-2);
  font-weight: 600;
  justify-content: center;
}
.arrowDNewCamMail {
  margin-left: 13rem;
}

.arrowDNewCamMail::before {
  content: url(../../assets/img/iconWhite/ArrowdWhite.svg);
  padding-left: 0.5rem;
  cursor: pointer;
}
.arrowDNewCamMailD::before {
  content: url(../../assets/img/iconBlack/ArrowDB.svg);
  padding-left: 0.5rem;
  cursor: pointer;
  display: none;
}
.inputChe:checked + p .arrowDNewCamMail::before {
  content: url(../../assets/img/iconWhite/ArrowUpWhite.svg);
  cursor: pointer;
}
.inputChe:checked + p .arrowDNewCamMailD::before {
  content: url(../../assets/img/iconBlack/arrowUB.svg);
  cursor: pointer;
  display: none;
}
.inputChe:checked ~ div.submenu {
  display: block;
}
.inputChe,
div.submenu {
  display: none;
}
.dd-menuCampMail {
  position: absolute;
  /* border: 1px solid #ccc; */
  border-radius: 0 0 15px 15px;
  /* padding: 0; */
  /* margin: 173px 0 0 0; */
  /* box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%); */
  background-color: var(--color-01);
  /* list-style-type: none; */
  width: 100%;
  height: 12.5rem;
  z-index: 10;
  left: 0;
  top: 2.7rem; /* 
  border: 2px solid red; */
  box-shadow: 0 -21px var(--color-05), 0px 0px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid var(--color-05);
}
.titleDropPre {
  font-family: var(--font-family-2);
  font-weight: bold;
  font-size: 1.563em;
  letter-spacing: 0.14px;
  color: var(--color-01);
  position: relative;
  z-index: 200;
}
.menu-body,
.menu-bodySend,
.left-menu,
.left-menuSend,
.right-menu {
  display: flex;
}

.menu-bodySend {
  flex-direction: column;
}
.left-menu,
.left-menuSend,
.right-menu {
  width: 50%;
  flex-direction: column;
}
.inpLab {
  display: flex;
  align-items: center;
}
.titleMenuBody {
  font-family: var(--font-family-2);
  font-size: 1.25em;
  letter-spacing: 0.14px;
  color: var(--text-01);
}
/* checkbox */
.custom-radio-checkbox-mail {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: arial;
}
.custom-radio-checkbox__show--checkbox-mail {
  background-image: url(../../assets/img/iconLila/unchecklila.png);
}

/* Oculta input original */
.custom-radio-checkbox-mail > .custom-radio-checkbox__input-mail {
  display: none;
}

/* Radio personalizado usando <span> */
.custom-radio-checkbox-mail > .custom-radio-checkbox__show-mail {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
  background-size: cover;
}
/* Cambia el checkbox personalizado cuando es pulsado */
.custom-radio-checkbox-mail
  > .custom-radio-checkbox__input-mail:checked
  + .custom-radio-checkbox__show--checkbox-mail {
  background-image: url(../../assets/img/iconLila/check.png);
}
.btn-validate {
  background: var(--color-14);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  color: var(--color-01);
  width: 8.5rem;
  margin-top: 2rem;
  padding: 0.1rem 0;
  border: none;
}
.menu-body,
.menu-bodySend {
  margin-top: 1rem;
}
.left-menu {
  height: 10rem;
  justify-content: space-between;
  padding-left: 2.5rem;
}
.left-menuSend {
  height: 6rem;
  justify-content: space-between;
  padding-left: 2.5rem;
}
.right-menu {
  justify-content: space-between;
}
.titleMenuRi {
  color: var(--color-15);
}
.dBtn {
  display: flex;
  justify-content: flex-end;
  margin-right: 3.5rem;
}
.body-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 3.5rem;
  /*   justify-content: space-evenly; */

  padding-top: 4rem;
}

.body-left,
.body-right {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: var(--borderRadius-1);
  height: 33rem;
  margin-top: 1.5rem;
}
.body-right {
  display: flex;
  justify-content: space-evenly;
  padding-top: 1rem;
}

.title-input {
  font-family: var(--font-family-2);
  font-weight: bold;
  font-size: 0.938em;
  line-height: 24px;
  letter-spacing: 0.694286px;
  color: var(--color-20);
  text-align: start;
}
.inputs-left {
  margin-bottom: 2rem;
}
.input-left {
  border: none;
  outline: none;
}
.foo-btns {
  display: flex;
  margin-top: 2rem;
  width: 20rem;
  justify-content: space-between;
}
.dd {
  z-index: 100;
  position: relative;
  display: inline-block;
  width: 9.5rem;
  height: 20%;
  /* border: 2px solid red; */
}
.dd-aPrev {
  /*  padding: 10px; */
  padding: 0.3rem;
  background: var(--color-18);
  position: relative;
  border-radius: var(--borderRadius-1);
  width: 9.5rem;
  z-index: 10;
}

.dd .dd-input,
.dd .dd-inputSend {
  top: -26px;
  /* left:20px; */
  opacity: 0;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: relative;
  height: 1.5rem;
  width: 79%;
  /* border: 2px solid red; */
  z-index: 100;
}
.dd .dd-input {
  left: 15px;
}
.dd .dd-inputSend {
  top: -24px;
  left: 16px;
}
.dd input:hover {
  cursor: pointer;
}

.dd input:checked:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
}
.dd input:checked ~ .dd-cPrev {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}
.dd-aPrev span {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 0.938em;
  color: var(--text-02);
}
.dd-cPrev {
  display: block;
  position: absolute;
  background: var(--color-01);
  height: 7rem;
  transform: scaleY(0);
  transform-origin: top left;
  transition-duration: 0.2s;
  -webkit-transform: scaleY(0);
  -webkit-transform-origin: top left;
  -webkit-transition-duration: 0.2s;
  width: 100%;
  /* border: 2px solid black; */
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  border-radius: 15px;
}
.timeDatePrev {
  width: 25%;
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
}
.timeDatePrev,
.input-mail {
  border: none;
  border-bottom: 2px solid var(--color-05);
  outline: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.dateofPrev {
  background: url("../../assets/img/iconLila/calLi.svg") 97% 50% no-repeat;
}
.inpHourNewPrev {
  background: url("../../assets/img/1.svg") 97% 50% no-repeat;
}
.dd-cOne,
.dd-cTwo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 0;
  position: relative;
  z-index: 1;
  top: -120%;
}
.dd-cOne {
  width: 358%;
  left: -55px;
  padding-top: 2rem;
}
.dd-two {
  /* margin-left: -16%; */
}
.dd-cTwo {
  width: 360%;
  left: -225px;
  flex-direction: column;
  padding: 1rem;
}
.btnSave {
  width: 25%;
  background-color: var(--color-14);
  border: none;
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-3);
  font-weight: 600;
  font-size: 0.75em;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--color-01);
  padding: 0.3rem 0;
  cursor: pointer;
}
.btnSend {
  background-color: var(--color-05);
}
.textSenMail {
  font-family: Helvetica;
  font-size: 0.875em;
  letter-spacing: 0.694286px;
  color: var(--text-02);
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.inputBtn {
  width: 81%;
  display: flex;
  justify-content: space-around;
}
.input-mail {
  width: 62%;
}
.iconExp {
  content: url(../../assets/img/iconGrey/export.svg);
  cursor: pointer;
}
.btn-foo {
  cursor: pointer;
  width: 15%;
  padding: 0.3rem 0;
  border-radius: var(--borderRadius-1);
  font-size: 1.063em;
  font-family: var(--font-family-2);
  border: none;
  font-weight: 600;
  margin-left: 1rem;
}
.btn-fooOne {
  background: var(--color-18);
  color: var(--text-02);
}
.btn-fooTwo {
  background: var(--color-18);
  color: var(--color-05);
}
.btn-fooThree {
  background: var(--color-05);
  color: var(--color-01);
}
.btns {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}
.container-formBtns {
  /*   display: flex;
  flex-direction: column;
  align-items: flex-end; */
  width: 100%;
}
@media (max-width: 991px) {
  .container-form {
    flex-direction: column;
    align-items: center;
  }
  .container-left,
  .container-right {
    width: 100%;
  }
  .container-right {
    margin-top: 2rem;
  }

  .dd-two {
    margin-left: -6rem;
  }
  .dd-cOne {
    width: 385%;
    /*  left: -15%; */
    /* padding: 0.5rem 1.5rem 1rem; */
    /* top: -113%; */
    left: -57px;
    top: -446%;
  }
  .dd-cTwo {
    left: -226px;
    width: 385%;
    top: -446%;
  }

  .btns {
    /*  width: 80%; */
    justify-content: center;
  }
  .btn-foo {
    width: 26%;
  }
  /*  .btn-foo {
    width: 31%;
  } */
  .textSenMail[data-v-72ab5290] {
    padding-top: 0;
  }
}
@media (max-width: 1024px) {
  .container-form {
    flex-direction: column;
    align-items: center;
  }

  .btns {
    justify-content: center;
  }
  /*  .btn-foo {
    width: 31%;
  } */
  .container-left,
  .container-right {
    width: 90%;
  }
  .container-right {
    margin-top: 2rem;
  }
  .btn-foo {
    width: 20%;
  }
  /*  .dd-two {
    margin-left: -15%;
  } */
  .dd-cOne {
    width: 450%;
    /*  left: -15%; */
    /* padding: 0.5rem 1.5rem 1rem; */
    /* top: -113%; */
    left: -57px;
    top: -446%;
  }
  .dd-cTwo {
    left: -226px;
    width: 450%;
    top: -446%;
  }
  .textSenMail[data-v-72ab5290] {
    padding-top: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .container-form {
    flex-direction: column;
    align-items: center;
  }
  .container-formBtns {
    align-items: center;
  }
  /*   .btns {
    width: 80%;
  } */
  /*  .btn-foo {
    width: 31%;
  } */
  .container-left,
  .container-right {
    width: 90%;
  }

  .container-right {
    margin-top: 2rem;
  }
  /*  .dd-two {
    margin-left: -20%;
  } */
  .dd-cOne {
    width: 463%;
    /*  left: -15%; */
    /* padding: 0.5rem 1.5rem 1rem; */
    /* top: -113%; */
    left: -57px;
    top: -446%;
  }
  .dd-cTwo {
    left: -226px;
    width: 463%;
    top: -446%;
  }
  .dd-two {
    margin-left: -6rem;
  }
  .btns {
    justify-content: center;
  }
  .btn-foo {
    width: 20%;
  }
  .textSenMail {
    padding-top: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1335px) {
  /*  .dd-two {
    margin-left: -25%;
  } */
  .dd-cOne {
    width: 285%;
    left: -57px;
  }
  .dd-cTwo {
    width: 285%;
    left: -225px;
  }
  .container-formBtns {
    width: 100%;
  }
  img {
    width: 95%;
    height: 95%;
  }
  .divIconExp {
    margin-left: 0.5rem;
  }
  .btns {
    width: 100%;
  }
  /*  .btn-foo {
    width: 13vw;
  } */
  .dd-two {
    margin-left: -6rem;
  }
  .titleDropPre {
    font-size: 1.25em;
  }
  /*  .btn-foo {

    width: 18%;} */
}
@media (min-width: 1336px) and (max-width: 1439px) {
  .dd-cOne {
    width: 315%;
    left: -57px;
    /* padding: 0.5rem 1.5rem 1rem; */
  }
  .dd-cTwo {
    width: 315%;
    left: -225px;
  }
  .btns {
    width: 100%;
  }
  .dd-two {
    margin-left: -6rem;
  }
  .divIconExp {
    margin-left: 0.5rem;
  }
  img {
    width: 95%;
    height: 95%;
  }
}
</style>

<template>
  <modal
    class="modalPrevMail"
    name="modalPrevMail"
    :scrollable="true"
    width="75%"
    height="auto"
  >
    <div class="container ">
      <img src="../../assets/img/capturaPantalla.png" alt="" />
      <router-link to="/mail/nueva-camp-mail" class="linkModalPrev">
        <button @click="$modal.hide('modalPrevMail')">Volver</button>
      </router-link>
    </div>
  </modal>
</template>
<script>
export default {};
</script>
<style scoped>
a.linkModalPrev{
  width: 100%;
  background: none
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.25rem;
  height: 100%;
  justify-content: center;
}
button {
  background-color: var(--color-12);
  border-radius: var(--borderRadius-1);
  border: none;
  font-family: var(--font-family-2);
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  font-weight: bold;
  color: var(--color-01);
  width: 15vw;
  margin: 1rem 0 ;
  padding: 0.5rem;
  cursor: pointer;
}
img {
  width: 75%;
}
</style>

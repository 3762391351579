<template>
  <div class="container">
    <div class="row">
      <div class="main-sidebar">
        <Sidebar></Sidebar>
      </div>
      <div class="col-sm-12  container-all">
        <div class="main">
          <div class="row center-sm center-md center-lg  ">
            <div class=" main-all">
              <div class="row rowOne">
                <div class="col-sm-4 col-md-4 col-lg-5">
                  <Search></Search>
                </div>
                <div class="  col-sm-3 col-md-3 col-lg-3  ">
                  <div class="containerCredits">
                    <Credits
                      text="disponibles"
                      border="container-CreditsTwo"
                      imgWidth="img"
                    ></Credits>
                  </div>
                </div>
                <div class="col-sm-5 col-md-5 col-lg-4">
                  
                  <NotifAndSetti widthRow="widthRow">
                    <template slot="shipping">
                      <img
                        class="imgMenu"
                        src="../assets/img/iconOrange/Shipping.svg"
                        alt=""
                      />
                    </template>
                    <template slot="info">
                      <router-link to="/centro-ayuda" class="">
                        <img
                          class="imgInfo "
                          src="../assets/icons/home/info.svg"
                          alt=""
                        />
                      </router-link>
                    </template>
                    <template slot="config">
                      <router-link to="/perfil" class="">
                        <img
                          class=" "
                          src="../assets/icons/home/herramientas.svg"
                          alt=""
                        />
                      </router-link>
                    </template>
                    <template slot="notif">
                      <img
                        class=""
                        src="../assets/icons/home/campana.svg"
                        alt=""
                      />
                    </template>
                  </NotifAndSetti>
                </div>
              </div>
              <div class="row rowTwo">
                <div class=" col-md-4 col-lg-4 colTwo">
                    <WelcomBias></WelcomBias>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4 biasMail">
                    <BiasMail></BiasMail>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <BiasSms></BiasSms>
                </div>
              </div>
              <div class="row rowThree">
                 <div class="col-sm-6 col-md-4 col-lg-4 biasVoip biasVoip01">
                    <BiasVoip></BiasVoip>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4 biasVoip">
                    <BiasAlterapay></BiasAlterapay>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4 listMail">
                    <BiasListSitesMail></BiasListSitesMail>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/common/Sidebar";
import Search from "../components/common/Search";
import Credits from "../components/common/Credits";
import NotifAndSetti from "../components/common/NotifAndSetti";
import WelcomBias from "../components/panels/WelcomBias";
import BiasMail from "../components/panels/BiasMail";
import BiasSms from "../components/panels/BiasSms";
import BiasVoip from "../components/panels/BiasVoip"
import BiasListSitesMail from "../components/panels/BiasListSitesMail"
import BiasAlterapay from "../components/panels/BiasAlterapay"
export default {
  components: {
    Sidebar,
    Search,
    NotifAndSetti,
    Credits,
    WelcomBias,
    BiasMail,
    BiasSms,
    BiasVoip,
    BiasListSitesMail,
    BiasAlterapay
    /*  ModalLogout, */
  },

  /* methods: {
    openModal() {
      this.$modal.show("modalLogout");
    },
  }, */
};
</script>

<style scoped>
.rowOne {
  /* border: 2px solid brown; */
  padding-bottom: 2rem;
}
.rowTwo {
  /* border: 2px solid red; */
}
/* .col-sm-12.col-md-5.col-lg-5 {
  border:2px solid black
} */
.col-sm-7.col-md-4.col-lg-4 {
  /* border: 2px solid rgb(124, 32, 245); */
}
.col-sm-5.col-md-3.col-lg-3 {
  /* border: 2px solid rgb(14, 231, 130); */
  justify-content: flex-end;

  display: flex;
}
.main-sidebar {
  display: flex;
  justify-content: center;
  position: fixed;
  /* justify-content: center; */
  /*  border: 2px solid green;  */
  /*  width: 8%;  */
  height: 93.5vh;
  z-index: 1000;
  /* padding-top: 3.5%; */
  top: 3.3vh;
  /*  padding-left: 2.5vh; */
  /* padding-bottom: 4%; */
  /* background-color: aqua; */
  left: 3.4vh;
}

.container-all {
  /* margin:1rem 5rem ; */
  display: flex;
  justify-content: center;
}
.main {
  background-color: var(--color-16);
  min-height: 100vh;
  width: 92%;
  border-radius: var(--borderRadius-1);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.main-all {
  /* border: 2px solid black; */
  width: 90%;
  min-height: 100vh;
  margin-top: 2rem;
  /* margin-right: 2rem; */
  margin-left: 2.5vw;
  /* margin-bottom: 2rem; */
}

/* .search {
  width: 95%;
} */

.rowThree {
  /* border:2px solid pink; */
  /* margin-top: 2rem; */
      justify-content: space-between;
      margin-top:2rem;
      margin-bottom:2rem
}
.biasMail{
        display: flex;
    justify-content: center;
}
.col-sm-4.col-md-4.col-lg-4.biasVoip {
    flex-basis: 29%;
    max-width: 29%;
}
.col-sm-4.col-md-4.col-lg-4.listMail {
    flex-basis: 40%;
    max-width: 40%;
}

@media (max-width: 992px) {
 
  .container-all {
    justify-content: flex-end;
    padding-right: 0.5rem;
  }
  .main {
    width: 95%;
  }

  .main-all {
    width: 88%;
  }

  .main-sidebar {
    padding-left: 0;
    height: 88vh;
    /* padding-top: 7%; */
    left: 0;
  }
  .containerCredits {
    width: 75%;
    margin-left: 3rem;
  }
  .col-md-4.col-lg-4.colTwo {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem
}
  .container-wlcBias{
width: 70%;

  }
  .rowThree {

    justify-content: center;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}

.biasVoip01{
  padding-right: 1rem
}

}
 @media (max-width: 1024px) {
  .main-all {
    margin-left: 5vh;
  }
   .main-sidebar {
   /*  height: 88vh;
    left: 0.3em;
    top: 5.5vh; */
    left: 0;
  } 
    .col-md-4.col-lg-4.colTwo {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem
}
  .rowThree {

    justify-content: center;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2rem
}

} 
@media (min-width: 1024px) and (max-width: 1199px) {
  .main-all {
    margin-left: 5vh;
  }
  .main-sidebar {
    /* padding-left: 0.5vh; */
    height: 88vh;
    left: 0.3em;
    top: 5.5vh;
    /*  position: fixed; */
  }
      .col-md-4.col-lg-4.colTwo {

    margin-bottom: 0;
        justify-content: flex-start;
}
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .main-sidebar {
    /* padding-left: 0.5vh; */
    /*     height: 88vh;

    top: 5.5vh; */
    left: 1em;
  }
}

@media (min-width: 1600px) {
  .main-sidebar {
    /* padding-left: 3.5vh; */
    height: 90vh;
    padding-top: 0%;
    left: 1.6em;
  }
}
@media (min-width: 1700px) {
  .main-sidebar {
    /*   padding-left: 3.5vh; */
    /*   height: 90vh;
    padding-top: 0%; */
    left: 1.9em;
  }
}
@media (min-width: 1800px) {
  .main-sidebar {
    /*   padding-left: 3.5vh; */
    /*   height: 90vh;
    padding-top: 0%; */
    left: 2.1em;
  }
  .main-all {
    margin-left: 4vh;
  }
}
@media (min-width: 1900px) {
  .main-sidebar {
    /*   padding-left: 3.5vh; */
    /*   height: 90vh;
    padding-top: 0%; */
    left: 2.5em;
  }
}
</style>

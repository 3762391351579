<template>
  <div class="container-openings">
    <div class="openings-header">
      <div>
        <p class="title-openings textWhite">Detalle de aperturas</p>
        <p class="text-openings textWhite">
          Detalle de los dispositivos <br />
          y navegadores donde fue <br />
          abierto el correo
        </p>
      </div>
      <div class="tabs">
        <button
          @click="chageTab(1)"
          class="btn-tab inner-tab"
          :class="{ active: currentTab == 1 }"
        >
          Dispositivos
        </button>
        <button
          @click="chageTab(2)"
          class="btn-tab inner-tab margin-fix"
          :class="{ active: currentTab == 2 }"
        >
          Navegacíon
        </button>
      </div>
    </div>
    <div class="chart">
      <apexchart
        class="chart-openings01"
        v-if="currentTab == 1"
        type="donut"
        width="350"
        :options="devices.chartOptions"
        :series="devices.series"
      ></apexchart>
      <apexchart
        class="chart-openings02"
        v-if="currentTab == 2"
        type="donut"
        width="350"
        :options="browsers.chartOptions"
        :series="browsers.series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentTab: 1,

      devices: {
        series: [30, 50, 10],
        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          labels: ["iOS", "Android", "Windows"],
          colors: ["#475789", "#00cccc", "#6600cc", "#213b92"],
          legend: {
            position: "left",
            labels: {
              colors: undefined,
            },
          },
          stroke: {
            colors: ["#fff"],
          },
        },
      },

      browsers: {
        series: [30, 50],
        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          labels: ["Gmail", "Outlook"],
          colors: ["#475789", "#00cccc", "#6600cc", "#213b92"],
          legend: {
            position: "left",
            labels: {
              colors: undefined,
            },
          },
          stroke: {
            colors: ["#fff"],
          },
        },
      },
    };
  },

  methods: {
    chageTab(index) {
      this.currentTab = index;
    },
  },
};
</script>
<style scoped>
.container-openings {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  border-radius: var(--borderRadius-1);
  width: 97%;
  /*  padding: 1rem; */
  padding: 1rem 1rem 0 1rem;
}
.title-openings,
.text-openings {
  font-family: var(--font-family-3);
  text-align: start;
  color: var(--color-17);
}
.title-openings {
  font-weight: 500;
  font-size: 1.25em;
  letter-spacing: 0.694286px;
}
.text-openings {
  font-size: 1em;
  margin-top: 1rem;
}
.openings-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
button {
  background: var(--color-18);
  border: 1px solid var(--color-18);
  box-sizing: border-box;
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  padding: 0 0.5rem;
  color: var(--color-05);
}
.tabs {
  width: 17vw;
  display: flex;
  justify-content: space-evenly;
}
.active {
  background-color: var(--color-05);
  color: var(--color-01);
}
.chart {
  display: flex;
  justify-content: center;
}
@media (max-width: 1200px) {
  .tabs {
    width: 33vw;
  }
}
@media (min-width: 1200px) and (max-width: 1407px) {
  .tabs {
    width: 23vw;
  }
  .title-openings {
    font-size: 1.063em;
  }
  .text-openings {
    font-size: 0.875em;
  }
}
</style>

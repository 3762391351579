<template>
  <div class="container-table container-dark">
    <SummaryTable
      btnRep="btnRep"
      data1="59030"
      data2="67.2"
      data3="1209"
      data4="5"
    ></SummaryTable>
    <div class="cont-table">
    <datatable
      title=""
      :columns="tableColumns1"
      :clickable="false"
      :rows="tableRows1"
      :sortable="false"
      class="tableMail"
    >
      <th class="th" slot="thead-tr">
        <i class="material-icons icon">more_vert</i>
      </th>
      <template slot="tbody-tr">
        <td class="td">
          <label class="dropdown">
            <div class="dd-button">
              <i v-on:click="addClass" class="material-icons icon2"
                >more_vert</i
              >
            </div>
            <input type="checkbox" class="dd-input" id="test" />

            <div v-on:click="deleteClass" id="minumenu" class="dd-menu">
              <div class="parr div-bor">Ver Reporte</div>
              <div class="parr div-bor">Reenviar</div>
              <div class="parr">Descargar</div>
            </div>
          </label>
        </td>
      </template>
    </datatable>
     </div>
  </div>
</template>
<script>
import DataTable from "vue-materialize-datatable";
import SummaryTable from "../common/SummaryTable";
let idElement;
export default {
  components: {
    datatable: DataTable,
    SummaryTable,
  },
  
  data() {
    return {
      //isAddClass: false,
      tableColumns1: [
        {
          label: "Nombre de campaña",
          field: "nomCamp",
          numeric: false,
          html: false,
        },
        {
          label: "Asunto",
          field: "asunto",
          numeric: false,
          html: false,
        },
        {
          label: "Creación",
          field: "creacion",
          numeric: false,
          html: false,
        },
        {
          label: "Fecha programada",
          field: "fechaProg",
          numeric: false,
          html: false,
        },
        {
          label: "Remitente",
          field: "remitente",
          numeric: false,
          html: false,
        },
        {
          label: "Estado",
          field: "estado",
          numeric: false,
          html: false,
        },
        {
          label: "Envíado",
          field: "enviado",
          numeric: true,
          html: false,
        },
        {
          label: "Cantidad de contactos",
          field: "cantCont",
          numeric: true,
          html: false,
        },
        {
          label: "Créditos",
          field: "creditos",
          numeric: true,
          html: false,
        },
      ],
      tableRows1: [
        {
          nomCamp: "Prueba 1",
          asunto: "Tu solicitud de retiro  fué rechazada",
          creacion: "20/03/22 11:22:59",
          fechaProg: "20/03/22 11:30:01",
          remitente: "AFP habitat (ariela.tolosa.silva@gmail.com)",
          estado: "Terminado",
          enviado: 4,
          cantCont: 10,
          creditos: 10,
        },
        {
          nomCamp: "Prueba 1",
          asunto: "Tu solicitud de retiro  fué rechazada",
          creacion: "20/03/22 11:22:59",
          fechaProg: "20/03/22 11:30:01",
          remitente: "AFP habitat (news@noseque.com)",
          estado: "Terminado",
          enviado: 4,
          cantCont: 10,
          creditos: 10,
        },
        {
          nomCamp: "Prueba 1",
          asunto: "Tu solicitud de retiro  fué rechazada",
          creacion: "20/03/22 11:22:59",
          fechaProg: "20/03/22 11:30:01",
          remitente: "AFP habitat (news@noseque.com)",
          estado: "Terminado",
          enviado: 4,
          cantCont: 10,
          creditos: 10,
        },
      ],
    };
  },
  methods: {
    addClass(event) {
      // console.log(event.target);
      event.target.classList.toggle("active");
      // this.isAddClass = true;
      idElement = event.target;
    },
    deleteClass() {
      idElement.classList.remove("active");
      //  this.isAddClass = false;
    },
  },
};
</script>
<style scoped>
.container-table {
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  margin-top: 3rem;
  /*  padding: 2.5rem; */
}
.tableMail {
  margin: 0 2.5rem;
  padding-bottom: 2.5rem !important;
}

.icon {
  color: var(--color-01);
}
.icon2 {
  /*   text-align: center; */
  color: var(--color-15);
}
.active {
  z-index: 1000;
  color: var(--color-01);
}

.th {
  width: 4%;
}
.td {
  /* padding-right: none !important */
}
.btn {
  border: none;
  background-color: transparent;
  color: var(--color-15);
  cursor: pointer;
}
/* .btn:active{
	background-color:none
} */
/* Dropdown */

.parr {
  font-family: var(--font-family-2);
  color: var(--text-01);
  letter-spacing: 3px;
  font-size: 0.938em;
  font-weight: 600;
  padding: 1rem 0 0.5rem 1rem;
  /*   line-height: 32px; */
  text-align: start;
}
.div-bor {
  border-bottom: 2px solid var(--color-01);
}
.dropdown {
  display: inline-block;
  position: relative;
  /* width: 100%; */
}

.dd-button {
  display: inline-block;
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  /* padding: 12px 30px 12px 20px; */
  /*   background-color: var(--color-01); */
  cursor: pointer;
  /*  white-space: nowrap; */
  /* width: 95%;  */
  /* font-size: 1.06em; */
  display: flex;
  /* align-items: center; */
  /*   justify-content: space-around; */
  z-index: 1000;
  /*  margin-top:2rem */
  /*   font-family: var(--font-family-2);
  font-weight: 600; */
}
.dd-button::after {
  z-index: 1000;
}

.dd-button:hover {
  background-color: var(--color-01);
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  /* top: 100%; */
  /*  border: 1px solid #ccc; */
  border-radius: var(--borderRadius-1);
  /* padding: 0; */
  margin: -33px 0 0 -129px;
  /*  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1); */
  background-color: var(--color-06);
  /*   list-style-type: none; */
  width: 10rem;
  /* height: 8.5rem; */
  z-index: 1;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}
@media (max-width: 991px) {
  /* .container-table{
  padding: 1rem
} */
 
  .tableMail {
    margin: 0 0.5rem;
  }
  .cont-table {
    overflow-y: hidden;
    overflow-x: auto;
  }
  /* .th {
  width: 5%;
} */

}
@media (min-width: 992px) and (max-width: 1199px) {
/* .container-table{
  padding: 1rem
} */
.tableMail{
   
    margin: 0 0.5rem;
}
.cont-table{
  overflow-y: hidden;
    overflow-x: auto;
}
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .cont-table{
  overflow-y: hidden;
    overflow-x: auto;
}
/* .tableMail{
   
    margin: 0 0.5rem;
} */
}
</style>

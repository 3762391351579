<template>
  <div class="body-container01 container-dark">
    <div class="all">
      <div class="form-send">
        <div>
          <div class="parrSpan">
            <p class="pTitle titleDark">Nombre del envío</p>
            <span class="infoLi"></span>
          </div>
          <input
            type="text"
            name=""
            id="nameSend"
            placeholder="Nombre de envío"
            class="nameSend"
          />
        </div>
        <div class="pDrop">
          <p class="pTitle titleDark">Mensaje a enviar</p>
          <div class="dropdownSms">
            <div class="dd-buttonSms dd-btnDark">
              <input
                id="check"
                type="checkbox"
                name="menu"
                class="inputCheck"
              />
              <p class="pdata">
                Datos <label for="check" class="arrowD "> </label
                ><label for="check" class="arrowBlack "> </label>
              </p>
              <div class="submenuSms  dd-menuSms dd-menuBlack">
                <p class="pData pDataBlack">Email</p>
                <p class="pData pDataBlack">Nombre</p>
                <p class="pData pDataBlack">Apellido</p>
                <p class="pData pDataBlack">Teléfono</p>
                <p class="pData pDataBlack">Fecha</p>
                <p class="pData pDataBlack">Ciudad</p>
                <p class="pData pDataBlack">Monto</p>
                <p class="pData pDataBlack">Rut</p>
                <p class="pData pDataBlack">URL</p>
                <p class="pData pDataBlack">Dato 1</p>
                <p class="pData pDataBlack">Dato 2</p>
                <p class="pData pDataBlack">Dato 3</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <TextAreaNewCampSms></TextAreaNewCampSms>
        </div>
        <div class="url">
          <p class="pTitle pUrl titleDark">URL corta</p>
          <button class="btnUrl urlOne" @click="showMessage">Crear</button>
          <button class="btnUrl">URL por contacto</button>
        </div>
        <div class="urlMessage" v-if="urlMessage === true">
          <p>URL corta creada con éxito</p>
        </div>
        <div class="inputLink">
          <input
            type="text"
            name=""
            id=""
            placeholder="Nombre de envío"
            class="nameSend"
          />
          <div class="dSwitch">
            <label class="switch">
              <input type="checkbox" id="checkbox" v-model="this.checked1" />
              <span class="sliderNewCam round"></span>
            </label>
            <span class="spanText titleDark">Incluir datos</span>
            <label class="switch">
              <input type="checkbox" id="checkbox" v-model="this.checked2" />
              <span class="sliderNewCam round"></span>
            </label>
            <span class="spanText titleDark">Incluir BIAS</span>
          </div>
        </div>
      </div>
      <div class="selection-cont">
        <div>
          <div class="parrSpan">
            <p class="pTitle titleDark">Selecciona los contactos</p>
            <span class="infoLi"></span>
          </div>
          <DropNewCampSms></DropNewCampSms>
          <div class="tableSms">
            <TableNewCampSms></TableNewCampSms>
          </div>
          <div class="container-date">
            <div class="p-date">
              <p class="pTitle titleDark">Fecha y hora de envío</p>
              <div class="dd ">
                <div class="dd-a dd-schShip">
                  <span class="schShip">Programar envío</span>
                </div>
                <input class="dd-input" type="checkbox" />
                <div class="dd-c dd-cDate">
                  <input
                    class="timeDate2  dateofbirth dateofbirth2"
                    type="date"
                    v-model="date"
                  />
                  <input
                    class="timeDate2  inpHourNew inpHourNew2"
                    type="time"
                    v-model="hour"
                  />
                  <button @click="saveDate" class="btnTime btn-save">
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="inputDate">
            <input class="timeDate " type="text" v-model="dateCop" />
            <input class="timeDate" type="text" v-model="hourCop" />
          </div>
        </div>
      </div>
    </div>
    <div class="foot-btns">
      <button @click="onClick" class="btnTime dd-schShip test ">
        Realizar prueba
      </button>
      <button @click="onClick01" class="btnTime dd-schShip saveDraft">
        Guardar borrador
      </button>
      <button @click="send" class="btnTime starShipp">Iniciar envío</button>
      <ModalTestSms></ModalTestSms>
      <ModalCommon
        title01="¡ya guardamos tu campaña!"
        subtitle01='La campaña la encontraras en "borradores"'
        textBtn01="Finalizar"
        :img1="img1"
        :img1Dark="img1Dark"
        name01="modal02"
        url="sms/nueva-camp-sms"
      ></ModalCommon>
      <ModalCommon
        title01="¡Envío de SMS exitoso!"
        subtitle01="Los mensaje se mandaron correctamente"
        textBtn01="Finalizar"
        :img2="img2"
        name01="modal01"
        :img2Dark="img2Dark"
        url="sms"
      ></ModalCommon>
    </div>
  </div>
</template>
<script>
import TableNewCampSms from "../tables/TableNewCampSms";
import TextAreaNewCampSms from "../panels/TextAreaNewCampSms";
import DropNewCampSms from "../buttons/DropNewCampSms";
import ModalTestSms from "../modals/ModalTestSms";
import ModalCommon from "../modals/ModalCommon";
import img01 from "../../assets/gif/modals/exit.gif";
import img02 from "../../assets/gif/modals/starts.gif";
import img1Dark from "../../assets/gif/modalsBlack/modalblack1.gif";
import img2Dark from "../../assets/gif/modalsBlack/modalblack.gif";
export default {
  components: {
    ModalTestSms,
    ModalCommon,
    TableNewCampSms,
    TextAreaNewCampSms,

    DropNewCampSms,
  },
  data() {
    return {
      urlMessage: false,
      checked1: false,
      checked2: false,
      hour: "",
      date: "",
      dateCop: "xx-xx-xxxx",
      hourCop: "xx:xx",
      img1: img01,
      img2: img02,
      img1Dark: img1Dark,
      img2Dark: img2Dark,
    };
  },
  methods: {
    showMessage() {
      /* 
        alert(this.urlMessage) */
      /* if(this.urlMessage === true) {
          this.urlMessage = false;
      }else if (this.urlMessage === false){
          this.urlMessage = true;
      }else{
          this.urlMessage = false;
      }  */
      this.urlMessage = true;
      setTimeout(() => {
        this.urlMessage = false;
      }, 2000);
    },
    saveDate() {
      this.dateCop = this.date;
      this.hourCop = this.hour;
    },
    onClick() {
      this.$modal.show("modalSmsTest");
    },
    onClick01() {
      this.$modal.show("modal02");
    },
    send() {
      this.$modal.show("modal01");
    },
  },
};
</script>
<style scoped>
.body-container01 {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}
.all {
  width: 87%;
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.form-send,
.selection-cont {
  width: 45%;
  /*   border: 2px solid green; */
}

.dropdownSms {
  /* display: inline-block; */
  position: relative;
  width: 40%;
  cursor: pointer;
}
.dd-buttonSms {
  /*  display: inline-block;  */
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  /* padding: 12px 20px 12px 20px; */
  padding: 0.5rem 0;

  background-color: var(--color-01);
  border: 1px solid var(--color-05);
  color: var(--color-05);
  /*  white-space: nowrap; */
  /* width: 100%; */
  font-size: 0.875em;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* z-index: 1000;  */
  /* margin-top: 2rem; */
  font-family: var(--font-family-3);
  font-weight: 500;
  letter-spacing: 0.694286px;
}
label.arrowD::before,
input:checked + p label::before,
label.arrowBlack::before,
input:checked + p .arrowBlack::before {
  background-repeat: no-repeat;
  background-size: 12px 12px;
  display: inline-block;
  width: 12px;
  height: 12px;
  content: " ";
}
label.arrowD::before {
  padding-left: 0.5rem;
  background-image: url(../../assets/img/iconLila/arrowLiDown.svg);
}

input:checked + p label::before {
  /*   content: url(../../assets/img/iconLila/arrowLiUp.svg) */
  background-image: url(../../assets/img/iconLila/arrowLiUp.svg);
}
label.arrowBlack::before {
  /*   content: url(../../assets/img/iconBlack/ArrowDB.svg); */
  padding-left: 0.5rem;
  background-image: url(../../assets/img/iconBlack/ArrowDB.svg);
  display: none;
}
input:checked + p .arrowBlack::before {
  /*   content: url(../../assets/img/iconBlack/arrowUB.svg); */
  background-image: url(../../assets/img/iconBlack/arrowUB.svg);
  display: none;
}
input:checked ~ div.submenuSms {
  display: block;
}
.inputCheck {
  cursor: pointer;
}
.inputCheck,
div.submenuSms {
  display: none;
}
.dd-menuSms {
  position: absolute;
  /* border: 1px solid #ccc; */
  /*   border-radius: 0 15px 15px 15px; */
  /* padding: 0; */
  /* margin: 173px 0 0 0; */
  /* box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%); */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  background-color: var(--color-01);
  /* list-style-type: none; */
  width: 100%;
  /* height: 8.5rem; */
  /*   z-index: 1;  */
  left: 0;
  top: 2.5rem;
  /*   border:2px solid gray */
  padding-top: 0.5rem;
  border-radius: 0 0 15px 15px;
}
.pTitle {
  font-family: var(--font-family-3);
  font-size: 1.375em;
  color: var(--text-01);
  letter-spacing: 0.694286px;
}
.nameSend {
  border: none;
  border-bottom: 2px solid var(--color-05);
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  outline: none;
}
.nameSend::placeholder {
  font-size: 0.938em;
}
.infoLi {
  content: url(../../assets/img/iconLila/infoLi.svg);
}
.parrSpan {
  display: flex;
  justify-content: space-between;
}
.pDrop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}
label.arrowD {
  margin-left: 3rem;
  display: flex;
  align-items: center;
}
p.pdata {
  display: flex;
  align-items: center;
}
.pData,
.dd-c,
.inputCamp::placeholder {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.694286px;
  color: var(--text-02);
}
.pData {
  font-size: 0.938em;
  padding-bottom: 0.3rem;
}

.url {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}
.urlMessage {
  background-color: var(--color-05);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-3);
  font-size: 1.25em;
  letter-spacing: 0.694286px;
  color: var(--color-01);
  margin-top: 0.5rem;
}
.urlOne {
  margin-left: 4rem;
}
.btnUrl,
.btnTime {
  width: 8vw;
  background-color: var(--color-01);
  border: 1px solid var(--color-05);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 0.75em;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--color-05);
  /* padding: 0.7rem 2.5rem; */
  cursor: pointer;
}
.pUrl {
  font-size: 1.25em;
}
.inputLink {
  margin-bottom: 1rem;
}
/* switch */

.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 23px;
  /*  margin-top: 1rem; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderNewCam {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-18);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderNewCam:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 1px;
  bottom: 1px;
  background-color: var(--color-01);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 1px 3px rgb(101 101 101 / 33%);
}

input:checked + .sliderNewCam {
  background-color: var(--color-11);
}

input:checked + .sliderNewCam:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(14px);
}

/* Rounded sliders */
.sliderNewCam.round {
  border-radius: 34px;
}

.sliderNewCam.round:before {
  border-radius: 50%;
}
.dSwitch {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}
.spanText {
  font-family: var(--font-family-2);
  font-size: 1.05em;
  letter-spacing: 0.694286px;
  color: var(--text-01);
  margin-right: 1.5rem;
  margin-left: 0.5rem;
}

/* .dBtns {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
} */
/* DROP2 AQUI*/
.dd {
  z-index: 1;
  position: relative;
  display: inline-block;
}
.dd-a {
  padding: 0.3rem;
  background: var(--color-01);
  position: relative;
  border-radius: var(--borderRadius-1);
  border: 1px solid var(--color-05);
  width: 9.5rem;
}

.dd .dd-input,
.inputCheck {
  top: 0;
  opacity: 0;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}
.dd input:hover {
  cursor: pointer;
}

.dd input:checked:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
}
.dd input:checked ~ .dd-c {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}
.dd-a span {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 0.938em;
  color: var(--color-05);
}
.dd-c {
  display: block;
  position: absolute;
  background: var(--color-01);
  height: auto;
  transform: scaleY(0);
  transform-origin: top left;
  transition-duration: 0.2s;
  -webkit-transform: scaleY(0);
  -webkit-transform-origin: top left;
  -webkit-transition-duration: 0.2s;
  width: 100%;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  border-radius: 0 0 15px 15px;
}

.p-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/*  */
.tableSms {
  margin-top: 1.5rem;
}
/*  */
.dd-cDate {
  width: 322%;
  left: -341px;
  /* padding: 0.5rem 1.5rem 1rem; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 0;
}
.timeDate,
.timeDate2 {
  border: none;
  border-bottom: 2px solid var(--color-05);
  width: 25%;
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
  outline: none;
  padding-bottom: 0.1rem;
}
.btnTime {
  width: 10vw;
}
.container-date {
  margin-top: 1.5rem;
}
.inputDate {
  display: flex;
  margin-top: 1.5rem;
}
.dd-schShip {
  background: var(--color-18);
  border: none;
}
.schShip,
.test {
  color: var(--text-02) !important;
}
.test,
.starShipp,
.saveDraft {
  padding: 0.3rem 0;
}
.btn-save {
  padding: 0.3rem 0;
  width: 8vw;
}
.starShipp,
.btn-save {
  background: var(--color-14);
  border: none;
  color: var(--color-01);
}
.foot-btns {
  width: 87%;
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.foot-btns button {
  margin-left: 0.5rem;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.dateofbirth {
  background: url("../../assets/img/iconLila/calLi.svg") 97% 50% no-repeat;
}
.inpHourNew {
  background: url("../../assets/img/1.svg") 97% 50% no-repeat;
}
@media (max-width: 991px) {
  .all {
    flex-direction: column;
    padding-top: 2rem;
  }
  .form-send,
  .selection-cont {
    width: 100%;
  }
  .pTitle {
    font-size: 1.25em;
  }
  .btnUrl {
    width: 17vw;
  }
  .urlOne {
    margin-left: 8rem;
  }
  .spanText {
    font-size: 1.05em;
  }

  .urlMessage {
    font-size: 1.05em;
  }

  .selection-cont {
    margin-top: 2rem;
  }
  .dd-cDate {
    width: 362%;
    left: -401px;
  }

  .test,
  .starShipp,
  .saveDraft {
    width: 16vw;
  }
  .btn-save {
    width: 15vw;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .all {
    width: 80%;
    flex-direction: column;
    padding-top: 2rem;
  }
  .form-send,
  .selection-cont {
    width: 100%;
  }
  .spanText {
    font-size: 1.05em;
  }
  .pTitle {
    font-size: 1.25em;
  }
  .btnUrl {
    width: 15vw;
  }
  .urlMessage {
    font-size: 1.05em;
  }
  .urlOne {
    margin-left: 13rem;
  }
  .dd-a {
    width: 11.5rem;
  }

  .selection-cont {
    margin-top: 2rem;
  }

  .dropdownSms {
    width: 30%;
  }
  .dd-cDate {
    width: 368%;
    left: -494px;
  }
  .test,
  .starShipp,
  .saveDraft {
    width: 13vw;
  }
}
@media (min-width: 1201px) and (max-width: 1335px) {
  .btnUrl {
    width: 10vw;
  }
  .urlOne {
    margin-left: 2rem;
  }
  .dd-a {
    width: 8.5rem;
  }

  .form-send,
  .selection-cont {
    width: 48%;
  }
  .dd-cDate {
    width: 306%;
    left: -280px;
  }
  .timeDate,
  .timeDate2 {
    width: 30%;
  }
}
@media (min-width: 1336px) and (max-width: 1439px) {
  .btnUrl {
    width: 10vw;
  }
  .urlOne {
    margin-left: 2rem;
  }
  .dd-a {
    width: 8.5rem;
  }

  .form-send,
  .selection-cont {
    width: 48%;
  }
  .dd-cDate {
    width: 343%;
    left: -329px;
  }
  .timeDate,
  .timeDate2 {
    width: 28%;
  }
}
@media (min-width: 1600px) {
  .dd-cDate {
    width: 342%;
    left: -368px;
  }
}
@media (min-width: 1700px) {
  .dd-cDate {
    width: 364%;
    left: -400px;
  }
}
@media (min-width: 1800px) {
  .dd-cDate {
    width: 385%;
    left: -432px;
  }
}
@media (min-width: 1880px) {
  .dd-cDate {
    width: 405%;
    left: -465px;
  }
}
</style>

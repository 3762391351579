<template>
  <modal
    class="modalCommon"
    :name="name01"
    :width="622"
    :height="512"
    :adaptive="true"
  >
    <div class="container ">
      <p class="title">{{ title01 }}</p>
      <img :class='imgWB' class="img1" :src="img1" alt="" />
      <img class="imgComm" :src="img2" alt="" />
      <img :class='imgWB' class="img1Dark" :src="img1Dark" alt="" />
      <img class="img2Dark" :src="img2Dark" alt="" />
      <p class="subtitle titleDark">{{ subtitle01 }}</p>
      <router-link :to="`/${url}`" class="linkModalComm">
        <button @click="close">{{ textBtn01 }}</button>
      </router-link>
    </div>
  </modal>
</template>
<script>
export default {
  props: [
    "title01",
    "img1",
    "img2",
    "img1Dark",
    "img2Dark",
    "subtitle01",
    "textBtn01",
    "name01",
    "url",
    "imgWB"
  ],
  data() {
    return {
      name: this.name01,
      to:this.url 
    };
  },
    methods :{
     close(){

      this.$modal.hide(this.name)
    },
  }
};
</script>
<style scoped>
a.linkModalComm{
  width: 100%;
  background: none
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-family: var(--font-family-2);
  font-weight: 900;
  font-size: 2em;
  letter-spacing: 1.15714px;
  color: var(--color-12);
  margin-top: 2rem;
  width: 60%;
}
.subtitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.125em;
  letter-spacing: 0.694286px;
  color: #242424;
}
button {
  background-color: var(--color-12);
  border-radius: var(--borderRadius-1);
  border: none;
  font-family: var(--font-family-2);
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  font-weight: bold;
  color: var(--color-01);
  width: 15vw;
  margin-top: 2rem;
  padding: 0.5rem;
  cursor: pointer;
}
img{
  width: 45%;
}
.imgWB{
   margin-top: 1rem
}
.img1Dark,.img2Dark{
  display:none
}

@media  (max-width: 1199px) {
.title {
    font-size: 1.6em;
  }


    }
       @media (min-width:  1200px) and (max-width: 1439px){
.title {
    font-size: 1.8em;
  }
  }
</style>

<template>
  <div class="all-container">
    <div @click="$router.go(-1)" class="currHead">
      <img
        class="imgArrow"
        src="../../assets/icons/others/ArrowLeft.svg"
        alt=""
      />
      <p class="title titleDark">Volver al menú</p>
    </div>
    <div class="container-table">
      <datatable
        title=""
        :columns="tableColumnsCurrent"
        :clickable="false"
        :rows="tableRowsCurrent"
        :sortable="false"
        class="tableCurrent"
      >
        <th class="th" slot="thead-tr">
          <p>Servicio</p>
        </th>
      
        <template class="rowIcon" slot="tbody-tr">
      <!--     <td v-for="category in tableRowsCurrent" :key="category.idServ" class="td">
             <template v-if="category.idServ == 'sms'">
           
            </template>
            <template v-else>
           
            </template>
              
          </td> -->
          <td class="td"> 
            <span></span>
          </td>
        </template>
      
      </datatable>
    </div>
  </div>
</template>
<script>
import DataTable from "vue-materialize-datatable";
export default {
  components: {
    datatable: DataTable,
  },

  props: ["url", "url2", "url3", "text", "backColor"],
  data() {
    return {
      to: this.url || this.url2,
      prevRoute: null,
      //spanIcon: this.idServ,
      //mail1:"",
      tableColumnsCurrent: [
        {
          label: "Nombre de campaña",
          field: "nombreCampaña",
          numeric: false,
          html: false,
        },
        {
          label: "Asunto/Mensaje",
          field: "asunMens",
          numeric: false,
          html: false,
        },
        {
          label: "Día de creacíon",
          field: "diaCra",
          numeric: false,
          html: false,
        },
        {
          label: "Remitente",
          field: "remitente",
          numeric: false,
          html: false,
        },
        {
          label: "Cantidad de contactos",
          field: "cantCont",
          numeric: true,
          html: false,
        },
      ],
      tableRowsCurrent: [
        {
          nombreCampaña: "Prueba 1",
          asunMens: " Tu solicitu de retiro fué rechazada",
          diaCra: "20/03/22 11:22:59",
          remitente: "AFP habitat (ariela.tolosa.silva@gmail.com)",
          cantCont: 10,
          idServ: "sms",
        },
        {
          nombreCampaña: "Prueba 1",
          asunMens: " Tu solicitu de retiro fué rechazada",
          diaCra: "20/03/22 11:22:59",
          remitente: "AFP habitat (ariela.tolosa.silva@gmail.com)",
          cantCont: 33,
          idServ: "sms",
        },
        {
          nombreCampaña: "Prueba 1",
          asunMens: " Tu solicitu de retiro fué rechazada",
          diaCra: "20/03/22 11:22:59",
          remitente: "AFP habitat (ariela.tolosa.silva@gmail.com)",
          cantCont: 3,
          idServ: "mail",
        },
      ],
    };
  },
  mounted() {
    this.prueba();
  },
  methods: {
    
    prueba() {


 
console.log(document.getElementsByClassName("container-table").children)
 /*
  let cont = 0;
   let a;  
      document.getElementById("rowIcon").map((item) => {

item.idServ;
 a = document.getElementsByClassName("rowIcon").[0].children[0];
 a.id = 'hola'+cont
  cont++;   
      });
*/
    
    },
    /* dd(v) {
      if (v == "sms") {
    console.log(document.getElementsByClassName("rowIcon").[0])
      } else {
     
      }

    },*/
  },
};
</script>
<style scoped>
.mail1 {
    content: url(/img/mail.35703b6e.svg);
}
.all-container {
  width: 100%;
}
.currHead {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  cursor: pointer;
}
a {
  display: flex;
}
.imgArrow {
  width: 1rem;
}
.title {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 3px;
  color: var(--color-17);
  margin-left: 2rem;
}

.sms1 {
  content: url(../../assets/img/iconOrange/comment.svg);
}
.tableDrafts {
  margin-top: 4rem;
}

@media (max-width: 991px) {
  .container-table {
    overflow-y: hidden;
    overflow-x: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* .container-table{
  padding: 1rem
} */

  .container-table {
    overflow-y: hidden;
    overflow-x: auto;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  /*   .container-table{
  overflow-y: hidden;
    overflow-x: auto;
} */
  /* .tableMail{
   
    margin: 0 0.5rem;
} */
}
</style>

<template>
  <div class="container-form">
    <div class="header-form">
      <p class="title titleContForm">¿No lograste resolver tus dudas?</p>
      <img src="../../assets/img/logo-altera-2021 2.svg" alt="" />
    </div>
    <div class="body-form">
      <div class="dCol">
        <p class="title2 titleContForm">Contactate con nosotros</p>
        <input type="text" name="" id="" placeholder="Nombre" class="inputContactForm"/>
        <input type="email" name="" id="" placeholder="E-mail" class="inputContactForm" />
      </div>
      <div class="dText">
        <textarea
          placeholder="Escribe tu pregunta"
          name=""
          id=""
          cols="30"
          rows="10"
          class="inputContactForm"
        ></textarea>
      </div>
    </div>
      <div class="container-btn">
          <button >Enviar</button>
        </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container-form {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  padding: 2rem 2rem 1rem 2rem;
}
.header-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-family: var(--font-family-2);
  font-weight: 600;
  color: var(--text-01);
  font-size: 1.563rem;
}
.title2 {
  font-family: var(--font-family-2);
  letter-spacing: 0.694286px;
  color: var(--text-01);
  font-size: 1.25rem;
  padding-block-end: 2rem;
}
.body-form {
  display: flex;
}
.dCol {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
}
.dText {
  width: 50%;
}
input {
  background: var(--color-01);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  border: none;
  padding: 1.3rem;
  margin-bottom: 1rem;
  width: 96%;
}
textarea {
  background: var(--color-01);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  margin-top: 3.5rem;
  padding: 1rem;
  border: none;
  width: 91%;
  height: 55%;
  outline: none;
  resize: none;
}

button {
   width:20%; 
  border-radius: var(--borderRadius-1);
  padding: 12px 20px 12px 20px;
  font-size: 1.25em;
  cursor: pointer;
  background-color: var(--color-03);
  border: 1px solid var(--color-12);
  color: var(--color-01);
  font-family: var(--font-family-2);
  font-weight: 600;
}
</style>

<template>
  <modal
    class="modal3"
    name="modalLogout"
    :width="540"
    :height="230"
    :adaptive="true"
  >
    <div class="container-imgX">
      <img
        class="imgX"
        src="../../assets/icons/others/x.svg"
        @click="$modal.hide('modalLogout')"
      />
    </div>
    <div class="modalBody">
      <p class="titleModLog">Nos Vemos!</p>
      <p class="subTitleModLog">Esperamos que vuelvas pronto</p>
      <button>Salir</button>
    </div>
  </modal>
</template>
<script>
export default {};
</script>
<style scoped>
.container-imgX {
     padding: 1.5rem 1.5rem 0 0;
    text-align: end;
}
.imgX {
  cursor: pointer;
}

p {
  font-family: var(--font-family-2);
  color: var(--text-01);
}
.titleModLog {
  font-weight: 600;
  font-size: 1.563em;
  padding-bottom: 1.5rem;
}
.subTitleModLog {
  font-size: 1.25em;
  letter-spacing: 0.694286px;
  padding-bottom: 2rem;
}
button {
  width: 20%;
  /* height: 1.875; */
  border-radius: var(--borderRadius-1);
  background-color: var(--color-03);
  border: none;
  cursor: pointer;
  font-family: var(--font-family-2);
  color: var(--color-01);
  font-size: 1.063em;
  padding: 0.3rem 0 0.3rem 0;
}
.modalBody {
  margin-top: 0.5rem;
      display: flex;
    flex-direction: column;
    align-items: center;
}
@media  (max-width: 1023px){
.container-imgX {
    padding: 1rem 0 0 1rem;
    
}
.modalBody {
    margin-top: 0; 
        display: flex;
    flex-direction: column;
    align-items: center;
}
.titleModLog{
  
    font-size: 1.4em;
 
}
.subTitleModLog {
    font-size: 1.05em;
   
    
}
}
</style>

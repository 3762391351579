<template>
  <table class="bordered">
    <thead>
      <tr>
        <th class="thNewCamp"><label>Grupo</label></th>
        <th class="thNewCamp"><label>Contactos</label></th>
        <th>
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox1"
            ></span>
          </label>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><label>Value</label></td>
        <td><label>Value</label></td>
        <td>
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"
            ></span>
          </label>
        </td>
      </tr>
      <tr>
        <td><label>Value</label></td>
        <td><label>Value</label></td>
        <td>
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"
            ></span>
          </label>
        </td>
      </tr>
      <tr>
        <td><label>Value</label></td>
        <td><label>Value</label></td>
        <td>
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"
            ></span>
          </label>
        </td>
      </tr>
      <tr>
        <td><label>Value</label></td>
        <td><label>Value</label></td>
        <td>
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"
            ></span>
          </label>
        </td>
      </tr>
      <tr>
        <td><label>Value</label></td>
        <td><label>Value</label></td>
        <td>
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"
            ></span>
          </label>
        </td>
      </tr>
      <tr>
        <td><label>Value</label></td>
        <td><label>Value</label></td>
        <td>
          <label class="custom-radio-checkbox">
            <input
              class="custom-radio-checkbox__input"
              type="checkbox"
              name=""
              value=""
            />
            <span
              class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"
            ></span>
          </label>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {};
</script>
<style scoped>
/* tabla */

table {
  border-collapse: separate;
  border-spacing: 0;
  /*     width: 600px;
    margin: 30px; */
}
.bordered {
  border: 1px solid var(--color-05);
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

th {
  font-family: var(--font-family-3);
  font-size: 1.25em;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--color-01);
}
label {
  font-weight: normal;
}
.bordered td,
.bordered th {
  border-left: 2px solid var(--color-01);
  padding: 0.2rem 0;
  text-align: center;
  width: 39%;
}
.bordered th {
  background-color: var(--color-05);
}
.bordered td:first-child,
.bordered th:first-child {
  border-left: none;
}
.bordered th:first-child {
  -moz-border-radius: 15px 0 0 0;
  -webkit-border-radius: 15px 0 0 0;
  border-radius: 15px 0 0 0;
}
.bordered th:last-child {
  -moz-border-radius: 0 15px 0 0;
  -webkit-border-radius: 0 15px 0 0;
  border-radius: 0 15px 0 0;
}
.bordered th:only-child {
  -moz-border-radius: 15px 15px 0 0;
  -webkit-border-radius: 15px 15px 0 0;
  border-radius: 15px 15px 0 0;
}
.bordered tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 15px;
  -webkit-border-radius: 0 0 0 15px;
  border-radius: 0 0 0 15px;
}
.bordered tr:last-child td:last-child {
  -moz-border-radius: 0 0 15px 0;
  -webkit-border-radius: 0 0 15px 0;
  border-radius: 0 0 15px 0;
}
.headCheck {
  border: 2px solid var(--color-01);
  background-color: var(--color-05);
}

/* checkbox */
.custom-radio-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: arial;
}

/* Modificador para dar la imagen de checkbox */
.custom-radio-checkbox__show--checkbox1 {
  background-image: url(../../assets/img/iconLila/uncheckwhite.png);
}
/* .custom-radio-checkbox__show--checkbox2{
   background-image: url(../../assets/img/uncheckB.png);
}
 */
.custom-radio-checkbox__show--checkbox {
  background-image: url(../../assets/img/iconLila/unchecklila.png);
}

/* Oculta input original */
.custom-radio-checkbox > .custom-radio-checkbox__input {
  display: none;
}

/* Radio personalizado usando <span> */
.custom-radio-checkbox > .custom-radio-checkbox__show {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
  background-size: cover;
}
/* Cambia el checkbox personalizado cuando es pulsado */
.custom-radio-checkbox
  > .custom-radio-checkbox__input:checked
  + .custom-radio-checkbox__show--checkbox {
  background-image: url(../../assets/img/iconLila/check.png);
}
td label{
  
font-family: Helvetica;
font-size: 0.938em;
letter-spacing: 0.694286px;
color: var(--text-02);
}
</style>

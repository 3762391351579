<template>
  <div class="container-form">
    <div class="container-left">
      <p class="title titleDark ">Menú principal</p>
      <p class="paragraphInfo ">
        . Inicio: dashboard con el estado general de la cuenta, para obtener un
        reporte de las campañas ejecutadas en la plataforma tiene un breve
        resumen, para mas detalles debe dirigirse a BIAS
      </p>
      <p class="paragraphInfo ">
        . Nueva Campaña: esta opción permita la creación una nueva campaña con
        su descripción, lista de contactos, archivos adjuntos, selección de
        plantillas, pruebas, programación de envíos y envíos inmediatos.
      </p>
      <p class="paragraphInfo">
        . Plantillas: Listado de plantillas que son cargados o construidos en la
        plataforma de mail.
      </p>
      <p class="paragraphInfo">
        . Adjuntos: Esta opción permite selecciona un archivo de carga (JPG,
        GIF, XLS, XLSX, PNG, TXT o PDF) que puede ser adjunto a los mails de una
        campaña.
      </p>
      <p class="paragraphInfo">
        . Eventos: esta opción permite crear Eventos específicos de clientes y
        que el destinatario del mail debe confirmar su asistencia.
      </p>
      <p class="paragraphInfo">
        Las CAMPAÑAS tienen una sola instancia de ejecución una vez creada. Si
        se requiere ejecutar nuevamente una campaña el sistema crea una nueva
        campaña con los mismos datos de la campaña de origen. Es recomendable
        para las campañas elegir nombres asociados a clientes, por ejemplo, más
        alguna secuencia de números de tal forma de mantener un control de
        campañas creadas.
      </p>
    </div>
    <div class="container-right">
      <p class="subtitle titleDark">
        Flujo básico de envío masivo de Mail
      </p>
      <p class="paragraphInfo">
        Para los envíos masivos de mail se debe seguir el siguiente flujo de
        trabajo de forma simple para este caso en las opciones de menú desde la
        pantalla principal de acceso.
      </p>
      <p class="paragraphInfo parrfFinal">
        Dashboard-Mail -> Nueva campaña -> rellenar datos de campaña ->
        verificar resumen de envío -> enviar / guardar campaña
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container-form {
  display: flex;
  width: 87%;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.container-left,
.container-right {
  width: 47%;
}
.paragraphInfo,
.subtitle {
  text-align: start;
  font-family: Helvetica;
  font-size: 0.938em;
  letter-spacing: 0.694286px;
  line-height: 24px;

  color: var(--text-02);
}
.title {
  text-align: start;
  font-family: var(--font-family-3);
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  color: var(--text-01);
}
.subtitle {
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.container-left {
  display: flex;
  flex-direction: column;
  height: 39rem;
  justify-content: space-around;
}
.container-right {
  padding-top: 3.5rem;
}
.parrfFinal{
    padding-top:2rem
}
@media (max-width: 1023px) {
    .container-form{
        flex-direction: column;
    }
    .container-left, .container-right {
    width: 100%;
}
}
@media (min-width: 1024px) and (max-width: 1200px){
    .container-left{
   
    height: 50rem;
   
}

}
@media (min-width: 1201px) and (max-width: 1439px){
.container-left {
    display: flex;
    flex-direction: column;
    height: 43rem;
    justify-content: space-around;
}
}

</style>

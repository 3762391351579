<template>
  <div class="all-container">
    <HeaderNewCamp
      text="Volver"
      url="mail"
      url2="mail/borradores-mail"
      url3="mail/plantillas-mail"
      backColor="btn-template"
    ></HeaderNewCamp>
    <div class="body-container container-dark">
      <div class="container-btns">
        <button class="btn-head">Crear plantilla</button>
        <button class="btn-head">Cargar desde zip</button>
        <button class="btn-head">Cargar desde URL</button>
      </div>
      <div class="container-body">
        <div class="container-title">
          <p>Mis plantillas</p>
        </div>
        <div class="container-cards">
          <div class="card-template" v-for="item in items" :key="item.id">
            <div class="body-card">
              <img class="img-cardTemp" :src="item.image" alt="" />
              <p class="text-cardMini">{{ item.title }}</p>
            </div>
            <div class="foot-card">
              <div>
                <p class="text-cardMini">Usar Plantilla</p>
              </div>
              <div class="container-span">
                <span class="vec01"></span>
                <span class="vec02"></span>
                <span class="vec03"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-dropTemplates">
          <DropTemplates></DropTemplates>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNewCamp from "../common/HeaderNewCamp";
import DropTemplates from "../buttons/DropTemplates";
export default {
  components: {
    HeaderNewCamp,
    DropTemplates,
  },
  data() {
    return {
      title: "",
      items: [
        {
          id: "plantilla01",
          title: "Beneficios AFP",
          image: require("../../assets/img/templateMini.png"),
        },
        {
          id: "plantilla02",
          title: "Nuevo Servicio",
          image: require("../../assets/img/templateMini.png"),
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped>
.body-container {
  background-color: var(--color-01);
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.container-btns {
  width: 93%;
  display: flex;
  justify-content: flex-end;
  align-content: space-between;
  margin-top: 2rem;
}
.btn-head {
  background: var(--color-18);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--borderRadius-1);
  border: none;
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  letter-spacing: 0.14px;
  color: var(--text-02);
  margin-left: 1.5rem;
  width: 13vw;
  padding: 0.8rem 0;
}

.container-body {
  width: 93%;
}
.container-title {
  display: flex;
  margin: 2rem 0;
}
.container-title p {
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
}
.container-title p,
.text-cardMini {
  font-family: var(--font-family-2);
  color: var(--text-01);
}

.container-cards {
  display: flex;
}
.card-template {
  background: var(--color-01);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--borderRadius-1);
  margin-right: 2rem;
}
.body-card {
  padding: 0.5rem;
}
.foot-card {
  border: 1px solid var(--color-05);
  border-radius: var(--borderRadius-1);
}
.text-cardMini {
  font-size: 1.063em;
  letter-spacing: 0.14px;
  margin: 1rem 0;
}
.container-span {
  background-color: var(--color-05);
  display: flex;
  border-radius: 0 0 var(--borderRadius-1) var(--borderRadius-1);
  padding: 0.5rem 0;
  justify-content: space-evenly;
}
.vec01 {
  content: url(../../assets/img/iconWhite/vec3.svg);
}
.vec02 {
  content: url(../../assets/img/iconWhite/vec2.svg);
}
.vec03 {
  content: url(../../assets/img/iconWhite/vec1.svg);
}
.container-dropTemplates {
  display: flex;
  margin-top: 5rem;
  margin-bottom: 6rem;
}

@media (max-width: 1200px) {
  .container-btns {
    width: 93%;
    display: flex;
    justify-content: space-around;
  }
  .btn-head {
    width: 23vw;
  }
}
/* @media (max-width: 991px) {
}
@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 1200px) and (max-width: 1335px) {
}
@media (min-width: 1336px) and (max-width: 1439px) {
}
@media (min-width: 1600px) {
}
@media (min-width: 1700px) {
}
@media (min-width: 1800px) {
}
@media (min-width: 1880px) {
} */
</style>

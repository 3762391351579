<template>
  <div class="container-clicks">
    <div class="click-header">
      <p class="title-general textWhite">Clicks</p>
    </div>
    <div class="charts-radial">
      <div class="chartRad01">
        <apexchart
          type="radialBar"
          width="150"
          height="200"
          :options="total.chartOptions"
          :series="total.series"
        ></apexchart>
        <div class="legends">
          <p class="legend01 textWhite">Totales</p>
          <p class="legend02 textWhite">{{ this.total.series[0] }}</p>
        </div>
      </div>
      <div class="chartRad02">
        <apexchart
          type="radialBar"
          width="150"
          height="200"
          :options="only.chartOptions"
          :series="only.series"
        ></apexchart>
        <div class="legends">
          <p class="legend01 textWhite">Únicos</p>
          <p class="legend02 textWhite">{{ this.only.series[0] }}</p>
        </div>
      </div>
    </div>
    <div class="unsubscriptions">
      <div>
        <p class="text-unsub textWhite">Desuscripciones</p>
      </div>
      <div class="user-number">
        <span class="user"></span>
        <span class="number textWhite"> 36</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: {
        series: [8223],
        chartOptions: {
          chart: {
            height: 350,
            type: "radialBar",
            toolbar: {
              show: false,
            },
          },
          states: {
            normal: {
              filter: {
                type: "none",
              },
            },
            hover: {
              filter: {
                type: "none",
              },
            },
            active: {
              allowMultipleDataPointsSelection: true,
              filter: {
                type: "none",
              },
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%",
                background: "transparent",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "back",
                dropShadow: {
                  enabled: true,
                  top: 1,
                  left: 0,
                  blur: 4,
                  opacity: 0.24,
                },
              },
              track: {
                background: "#fff",
                strokeWidth: "70%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -1,
                  left: 0,
                  blur: 2,
                  opacity: 0.5,
                },
              },

              dataLabels: {
                show: false,
              },
            },
          },
          fill: {
            colors: ["#7A40F2"],
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0,
              gradientToColors: ["#e7e1f3"],
              inverseColors: true,
              opacityFrom: 50,
              opacityTo: 50,
              stops: [5, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          /* labels: ["Percent"], */
        },
      },
      only: {
        series: [8223],
        chartOptions: {
          chart: {
            height: 350,
            type: "radialBar",
            toolbar: {
              show: false,
            },
          },
          states: {
            normal: {
              filter: {
                type: "none",
              },
            },
            hover: {
              filter: {
                type: "none",
              },
            },
            active: {
              allowMultipleDataPointsSelection: true,
              filter: {
                type: "none",
              },
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "70%",
                background: "transparent",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "back",
                dropShadow: {
                  enabled: true,
                  top: 1,
                  left: 0,
                  blur: 4,
                  opacity: 0.24,
                },
              },
              track: {
                background: "#fff",
                strokeWidth: "70%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -1,
                  left: 0,
                  blur: 2,
                  opacity: 0.5,
                },
              },

              dataLabels: {
                show: false,
              },
            },
          },
          fill: {
            colors: ["#7A40F2"],
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0,
              gradientToColors: ["#e7e1f3"],
              inverseColors: true,
              opacityFrom: 50,
              opacityTo: 50,
              stops: [5, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.container-clicks {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  border-radius: var(--borderRadius-1);
  width: 97%;
  /*  padding: 1rem; */
  padding: 1rem 1rem 0 1rem;
}
.title-general,
.legend01,
.legend02,
.text-unsub,
.number {
  font-family: var(--font-family-3);
  font-weight: 500;
  color: var(--color-17);
  text-align: start;
}
.legend01 {
  font-size: 0.938em;
}
.legend02 {
  font-size: 1.563em;
}
.title-general {
  font-size: 1.25em;
  letter-spacing: 0.694286px;
}
.number {
  letter-spacing: 0.694286px;
  font-size: 1.536em;
  margin-left: 6rem;
}
.charts-radial {
  height: 50%;
  border-bottom: 2px solid var(--color-11);
  display: flex;
}
.chartRad01,
.chartRad02 {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-around;
  /*   justify-content: space-between; */
}
.legends {
  /* margin-right: 13rem; */
  /* padding-right: 5rem; */
  margin-left: -2rem;
  margin-top: 5rem;
}
.user {
  content: url(../../assets/img/iconLila/ProfileLila.svg);
}
.unsubscriptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
}
.user-number {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-left: 2rem;
}
@media (max-width: 1200px) {
  .chartRad01,
  .chartRad02 {
    justify-content: space-evenly;
  }
}
@media (min-width: 1200px) and (max-width: 1407px) {
  .legend01 {
    font-size: 0.813em;
  }
  .legend02,
  .number {
    font-size: 1.438em;
  }
  .title-general {
    font-size: 1.063em;
  }
  .chartRad01,
  .chartRad02 {
    justify-content: space-between;
  }
}
</style>

<template>
  <div class="container-impCont">
    <div class="title-header">
      <p>Importar Contactos</p>
    </div>
    <div class="container-bodyContacts">
      <div class="body-left">
        <div class="selectContact">
          <p>Seleccionar contactos</p>
          <div class="exam">
            <input
              type="text"
              class="inputCampCont inputExam"
              placeholder="Seleccionar contactos"
            />
            <button class="btn-exam">Examinar</button>
          </div>
        </div>
        <div class="nameCharge">
          <p>Nombre de carga</p>
          <input
            @input="validarInput('btnNext', 'btnFoot2')"
            type="text"
            class="inputCampCont inputCharge"
            v-model="nameCharge"
            placeholder="Nombre de carga"
          />
        </div>
        <div class="newGroup">
          <p>*Nuevo grupo</p>
          <label class="switch">
            <input type="checkbox" id="checkbox" />
            <span class="sliderCardCont round"></span>
          </label>
        </div>
      </div>
      <div class="body-rightCont">
        <div class="searchCustField">
          
          <input type="text" placeholder="buscar" class="inpSeaCus">
          <span class="searchCus"></span>
        </div>
        <div class="row">
          <div class="col-sm-3 " v-for="item in items" :key="item.id">
            <div :id="item.id" class="cardList" @click="toggle($event)">
              <div class="body-cardCont">
                <img class="img-cardTemp" :src="item.image" alt="" />
                <p class="text-cardMiniCont">{{ item.title }}</p>
              </div>
              <div class="container-span">
                <span class="vec01"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-foot">
      <button :disabled="disabled" id="btnNext" class="btnFootCont" @click="changeComp">
        Siguiente
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nameCharge: null,
      disabled: true,

      items: [
        {
          id: "plantilla01",
          title: "TestJaime.CSV",
          image: require("../../assets/img/Rectangle 624.png"),
        },
        {
          id: "plantilla02",
          title: "Prueba 1",
          image: require("../../assets/img/Rectangle 624.png"),
        },
        {
          id: "plantilla03",
          title: "TestJaime.CSV",
          image: require("../../assets/img/Rectangle 624.png"),
        },
        {
          id: "plantilla04",
          title: "Prueba 1",
          image: require("../../assets/img/Rectangle 624.png"),
        },
        {
          id: "plantilla05",
          title: "Prueba 1",
          image: require("../../assets/img/Rectangle 624.png"),
        },
      ],
    };
  },
  methods: {
    toggle(event) {
      console.log();
      if (
        document
          .getElementById(event.currentTarget.id)
          .classList.contains("activeCont")
      ) {
        document
          .getElementById(event.currentTarget.id)
          .classList.remove("activeCont");
      } else {
        document.getElementById(event.currentTarget.id).classList.add("activeCont");
      }
    },
    validarInput(idbtn, classBtn) {
      if (this.nameCharge) {
        document.getElementById(idbtn).classList.add(classBtn);

        this.disabled = false;
      } else {
        if (!this.nameCharge) {
          document.getElementById(idbtn).classList.remove(classBtn);
          this.disabled = true;
        }
      }
    },
    changeComp() {
      /* alert("hola"); */
      this.$emit("change-componentCont", "SelectCustFields");
    },
  },
};
</script>
<style scoped>

.container-impCont {
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  margin-top: 3rem;
}
.title-header {
  display: flex;
  padding: 2rem 0 0 2.5rem;
}
.title-header p {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--text-01);
}
/* switch */

.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 23px;
  /*  margin-top: 1rem; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderCardCont{
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-18);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderCardCont:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 1px;
  bottom: 1px;
  background-color: var(--color-01);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 1px 3px rgb(101 101 101 / 33%);
}

input:checked + .sliderCardCont {
  background-color: var(--color-11);
}

input:checked + .sliderCardCont:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(14px);
}

/* Rounded sliders */
.sliderCardCont.round {
  border-radius: 34px;
}

.sliderCardCont.round:before {
  border-radius: 50%;
}
/* fin switch */

.container-bodyContacts {
  padding: 2.5rem 2.5rem 0 2.5rem;
  /* border: 2px solid yellow; */
  display: flex;
  justify-content: space-between;
}
.body-left {
  width: 40%;
  /* border: 2px solid red; */
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.selectContact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.selectContact p,
.nameCharge p,
.newGroup p {
  font-family: var(--font-family-3);
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  color: var(--text-01);
}
.inputCampCont,.inpSeaCus{
  border: none;
  border-bottom: 2px solid var(--color-05);
  width: 90%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  outline: none;
}
.inputCampCont,
.inputCampCont::placeholder {
  font-family: Helvetica;
  letter-spacing: 0.694286px;
  font-size: 0.938em;
  color: var(--text-02);
}
.searchCus{
  content: url(../../assets/img/iconGrey/search.svg);
  border-bottom: 2px solid var(--color-05);
  padding-top: .5rem;
}
.btn-exam {
  background-color: var(--color-01);
  border: 1px solid var(--color-05);
  border-radius: 0.938em;
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 0.875em;
  letter-spacing: 0.694286px;
  color: var(--color-05);
  width: 35%;
  padding: 0.4rem 0;
  cursor: pointer;
}
.exam {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 1rem;
  
}
.inputExam {
  width: 50%;
}
.nameCharge {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}
.inputCharge {
  width: 100%;
  margin-top: 1rem;
}
.inpSeaCus,.inpSeaCus::placeholder{
font-family: var(--font-family-3);
font-weight: normal;
font-size: 15px;
line-height: 17px;
/* identical to box height, or 113% */

letter-spacing: 0.14px;

color: var(--color-15);
}
.inpSeaCus::placeholder{
text-align: end;
margin-right: 1rem;

}
.newGroup {
  display: flex;
  justify-content: space-between;
}
.body-rightCont {
  border: 1px solid var(--color-11);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  width: 50%;
  /*  padding: 1rem 1rem 1rem 3rem; */
}
.body-rightCont,
.cardList {
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
}
.cardList {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  /*  margin-right: 1.5rem;  */
  cursor: pointer;
}
.body-cardCont {
  padding: 0.5rem;
}
.container-span {
  background-color: var(--color-05);
  display: flex;
  border-radius: 0 0 var(--borderRadius-1) var(--borderRadius-1);
  padding: 0.5rem 0;
  justify-content: space-evenly;
}
.col-sm-3 {
  flex-basis: 25%;
  max-width: 25%;
  margin: 1rem 0.7rem 1rem 0.7rem;
}
.row {
  justify-content: space-between;
}
.img-cardTemp {
  width: 90%;
}
.vec01 {
  content: url(../../assets/img/iconWhite/vec3.svg);
  cursor: pointer;
}
.text-cardMiniCont {
  font-family: Helvetica;
  font-size: 0.75em;
  letter-spacing: 0.694286px;
  color: var(--text-02);
  margin: 0.5rem 0;
}
.activeCont {
  border: 1px solid var(--color-11);
}
.btn-foot {
  display: flex;
  justify-content: flex-end;
  padding-right: 2.5rem;
}
.btnFootCont{
  background: var(--color-14);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  color: var(--color-01);
  border: none;
  cursor: pointer;
  width: 10vw;
  padding: 0.3rem 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.btnFoot2 {
  background: var( --color-05);
}
.searchCustField{
  margin-bottom: 1rem;
  display:flex;
  justify-content: center;

}
@media  (max-width: 992px){
  .container-bodyContacts{
        flex-direction: column;
  }
  .body-left,.body-rightCont{
    width: 100%;
  }
  .body-right{
    margin-top: 3rem
  }
  .btnFootCont {

    width: 15vw;
  }
  .title-header p{
    font-size:1.375em
  }
  .selectContact p, .nameCharge p, .newGroup p {

    font-size: 1.275em;
  
}
 .body-rightCont{

    margin-top: 1rem;
}
.container-impCont {
   
     margin-top: 0;
}
}
@media (min-width: 993px) and (max-width: 1199px) {
  .body-rightCont {

    width: 55%;
    /* padding: 1rem 1rem 1rem 3rem; */
}
.btnFootCont{
   
    width: 12vw;
    
}
  .title-header p{
    font-size:1.375em
  }
  .selectContact p, .nameCharge p, .newGroup p {

    font-size: 1.275em;
  
}
.container-impCont {
   
     margin-top: 2rem;
}
}

</style>

<template>
  <div>
    <modal
      class="modalChargeContacts"
      name="modalChargeContacts"
      :width="622"
      :height="525"
      :adaptive="true"
    >
      <div class="container ">
        <div class="container-imgBtn">
          <img
            class="imgX"
            src="../../assets/icons/others/x.svg"
            @click="$modal.hide('modalChargeContacts')"
          />
        </div>
        <p class="title">¡Confirmar carga de contactos!</p>
        <img
          class="img1"
          src="../../assets/gif/modals/settingsWhite.gif"
          alt=""
        />
        <img
          class="img1Dark"
          src="../../assets/gif/modalsBlack/settingsBlack.gif"
          alt=""
        />
        <p class="subtitle titleDark">
          Revise bien los datos a cargar, estos no podrán ser modificados
          masivamente posteriormente
        </p>

        <button @click="chargeCont">Confirmar</button>
      </div>
    </modal>
    <ModalCommon
      title01="¡Exito!"
      subtitle01="Cargando contactos..."
      textBtn01="Listo "
      :img1="img1"
      :img2Dark="img2Dark"
      name01="modalCommContact"
      url="contactos"
      imgWB="imgWB"
    ></ModalCommon>
  </div>
</template>
<script>
import ModalCommon from "../modals/ModalCommon";
import img01 from "../../assets/gif/modals/settingsWhite.gif";
import img2Dark from "../../assets/gif/modalsBlack/settingsBlack.gif";
export default {
    components:{
  ModalCommon
},
  data() {
    return {
      img1: img01,
      img2Dark: img2Dark,
    };
  },
  methods: {
       chargeCont(){
      this.$modal.show("modalCommContact");
      this.$modal.hide('modalChargeContacts')
    },
  },
};
</script>
<style scoped>
.container-imgBtn {
  padding: 1rem 1rem 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-family: var(--font-family-2);
  font-weight: 900;
  font-size: 2em;
  letter-spacing: 1.15714px;
  color: var(--color-12);
  width: 60%;
}
.subtitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.125em;
  letter-spacing: 0.694286px;
  color: #242424;
}
button {
  background-color: var(--color-12);
  border-radius: var(--borderRadius-1);
  border: none;
  font-family: var(--font-family-2);
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  font-weight: bold;
  color: var(--color-01);
  width: 15vw;
  margin-top: 2rem;
  padding: 0.5rem;
  cursor: pointer;
}
img {
  width: 45%;
}
.img1Dark,
.img2Dark {
  display: none;
}
.imgX {
  width: 4%;
}

@media (max-width: 1199px) {
  .title {
    font-size: 1.6em;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .title {
    font-size: 1.8em;
  }
}
</style>

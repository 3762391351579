<template>
  <div class="container-chartResVoip">
    <div class="row">
      <div class="col-sm-8 col-md-6 col-lg-5">
        <Platform
          service=" Bienvenido a Voip"
          paramBtn=""
          :img="img"
          :imgDark="imgDark"
          dispBtn="dispBtn"
          platCont="platCont"
        ></Platform>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 ">
        <div class="cardResumVoip container-dark">
          <h3 class="card-titleResVoip textWhite">Conectividad últimos 12 meses</h3>
          <div class="chart">
            <apexchart
              v-if="render"
              type="area"
              height="200"
              :options="connectivitychartOptions"
              :series="connectivity.series"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <div class="row rowTwo">
      <div class=" col-sm-6 col-lg-3">
        <div class="cardVoip container-dark">
          <p class="title-cardVoip textWhite">Llamadas Exitosas</p>
          <p class="subtitle-cardVoip textWhite">Mes vigente</p>
          <div class="body-cardVoip">
            <spa class="phoneVoip"></spa>
            <p class="dataVoip textWhite">1.552</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 cardFlex">
        <div class="cardVoip container-dark">
          <p class="title-cardVoip textWhite">Contactabilidad</p>
          <p class="subtitle-cardVoip textWhite">Mes vigente</p>
          <div class="card-content">
            <div class="minutes">
              <apexchart
                type="radialBar"
                height="250"
                :options="contactability.chartOptions"
                :series="contactability.series"
              ></apexchart>
              <div class="legend">
                <p class="text-legend textWhite">94%</p>
                <p class="text-2 textWhite">Contactabilidad</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 cardFlex ">
        <div class="cardVoip cardVoip03 container-dark">
          <div class="header-card03">
            <p class="title-cardVoip textWhite">Conciliacíon</p>
            <div class="textArrow">
              <RouterLink to="/voip/resumen-llamadas" class="aVoip">
                <p>Ver más</p>
                <span class="arrLeftMailBias"></span>
              </RouterLink>
            </div>
          </div>
          <div class="body-card03">
            <div class="prog01">
              <div class="progBar">
                <progress-bar
                  size="large"
                  :bar-border-radius="15"
                  bar-color="#F77800"
                  bg-color="#ACAFB5"
                  val="94"
                ></progress-bar>
              </div>
              <div class="percText">
                <p class="perc textWhite">94%</p>
                <p class="text textWhite">Llamadas efectivas</p>
              </div>
            </div>
            <div class="prog01">
              <div class="progBar">
                <progress-bar
                  size="large"
                  :bar-border-radius="15"
                  bar-color="#F77800"
                  bg-color="#ACAFB5"
                  val="74"
                ></progress-bar>
              </div>
              <div class="percText">
                <p class="perc textWhite">74%</p>
                <p class="text textWhite">Llamadas exitosas</p>
              </div>
            </div>
            <div class="prog01">
              <div class="progBar">
                <progress-bar
                  size="large"
                  :bar-border-radius="15"
                  bar-color="#F77800"
                  bg-color="#ACAFB5"
                  val="6"
                ></progress-bar>
              </div>
              <div class="percText">
                <p class="perc textWhite">6%</p>
                <p class="text textWhite">Llamadas rechazadas</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Platform from "../../components/panels/Platform";
import infoContact from "../../assets/gif/home/Alteralytics.gif";
import imgDarkMail from "../../assets/gif/homeBlack/alterapayBlack.gif";
import ProgressBar from "vue-simple-progress";
export default {
  components: {
    Platform,
    ProgressBar,
  },
  data() {
    return {
      img: infoContact,
      imgDark: imgDarkMail,
      render: true,
      connectivity: {
        series: [
          {
            name: "Llamadas exitosas",
            data: [
              750,
              1250,
              1145,
              1000,
              1235,
              1025,
              1073,
              980,
              1254,
              1145,
              1000,
              1235,
            ],
          },
        ],
      },
      connectivitychartOptions: {
        colors: ["#6600cc"],
        chart: {
          width: "100%",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        fill: {
          colors: ["#B990ED", "#E01AFF"],
          opacity: 1,
          type: "gradient",
          gradient: {
            type: "horizontal",

            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 70],
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        markers: {
          size: 4,
          strokeColors: "#fff",
        },
        xaxis: {
          categories: [
            "Dic",
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
          ],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          min: 0,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          padding: {
            left: -10,
            right: 0,
            bottom: 5,
            top: 0,
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      contactability: {
        series: [94],
        chartOptions: {
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                size: "60%",
              },
              track: {
                background: "#ACAFB5",
              },
              dataLabels: {
                show: false,
              },
            },
          },
          states: {
            normal: {
              filter: {
                type: "none",
              },
            },
            hover: {
              filter: {
                type: "none",
              },
            },
            active: {
              allowMultipleDataPointsSelection: true,
              filter: {
                type: "none",
              },
            },
          },
          fill: {
            colors: ["#F77800"],
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0,
              gradientToColors: ["#FBBE85"],
              inverseColors: true,
              opacityFrom: 50,
              opacityTo: 50,
              stops: [5, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.cardResumVoip {
  padding: 2rem 2rem 0 2rem;
  margin-top: 2rem;
}
.cardVoip,
.cardResumVoip {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
}
.cardVoip {
  width: 98%;
  padding: 1.5rem 2rem;
  height: 100%;
}
.card-titleResVoip {
  letter-spacing: 3px;

  margin-bottom: 2rem;
}
.cardFlex {
  display: flex;
  justify-content: flex-end;
}
.card-titleResVoip,
.title-cardVoip {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 25px;
  text-align: start;
  color: var(--color-17);
}

.subtitle-cardVoip {
  font-size: 15px;
  text-align: start;
}
.dataVoip,
.subtitle-cardVoip,
.text-legend,
.text-2,
.perc,
.text {
  font-family: var(--font-family-3);
  font-weight: 500;
  color: var(--color-17);
}
.perc {
  font-size: 1.25em;
  width: 34%;
}
.text {
  font-size: 0.75em;
  width: 66%;
  text-align: start;
}
.text-legend {
  font-size: 1.25em;
  letter-spacing: 0.694286px;
}
.text-2 {
  font-size: 0.625em;
}
.dataVoip {
  font-size: 1.875em;
  margin-left: 1rem;
}
.phoneVoip {
  content: url(../../assets/img/iconOrange/phoneOrange.svg);
}
.body-cardVoip {
  display: flex;
  justify-content: center;
  align-items: center;
  /*  margin-top: 1.5rem */
  height: 77%;
}
.legend {
  text-align: center;
  margin-top: -72px;
  /*  margin-bottom: 2rem; */
}
.arrLeftMailBias {
  content: url(../../assets/img/iconOrange/ArrowRig.svg);
}
.header-card03 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
a {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.textArrow p {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 0.875em;
  letter-spacing: 0.14px;
  color: var(--color-15);
  margin-right: 1rem;
}
.progBar {
  width: 60%;
}
.prog01 {
  display: flex;
  align-items: center;
}
.percText {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-evenly;
}
.rowTwo {
  margin-top: 2rem;
}
.body-card03 {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 2rem;
}
@media (max-width: 1200px) {
  .cardVoip03 {
    width: 100%;
    margin-top: 2rem;
  }
  .progBar {
    width: 70%;
  }
  .card-titleResVoip,
  .title-cardVoip {
    font-size: 1.25em;
  }
}
@media (min-width: 1200px) and (max-width: 1407px) {
  .cardVoip03 {
    width: 98%;
    margin-top: 0;
    
  }
  .cardVoip{
padding: 1.5rem 1rem;
  }
  .card-titleResVoip,
  .title-cardVoip {
    font-size: 1.25em;
  }
  .text {
    width: 100%;
  }
  .progBar {
    width: 58%;
}
.perc {
    
    width: 33%;
}
.prog01 {
   
    justify-content: space-between;
}
}
</style>

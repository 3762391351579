<template>
  <div class="container-tableDelVoip">
    <datatable
      title=""
      :columns="tableColumnsDelVoip"
      :rows="tableRowsDelVoip"
      :sortable="false"
      class="tableDelVoip"
    >
    </datatable>
  </div>
</template>
<script>
import DataTable from "vue-materialize-datatable";
export default {
  components: {
    datatable: DataTable,
  },
  data() {
    return {
      tableColumnsDelVoip: [
        {
          label: "Número de contacto",
          field: "numeroCont",
          numeric: false,
          html: false,
        },
        {
          label: "Fecha ",
          field: "fecha",
          numeric: false,
          html: false,
        },
        {
          label: "Hora",
          field: "hora",
          numeric: false,
          html: false,
        },
        {
          label: "Compañia",
          field: "compañia",
          numeric: false,
          html: false,
        },
      ],
      tableRowsDelVoip: [
        {
          numeroCont: "56962262798",
          fecha: "13-11-2019",
          hora: "19:58:13",
          compañia: "movistar",
        },
        {
          numeroCont: "56962262798",
          fecha: "13-11-2019",
          hora: "19:58:13",
          compañia: "movistar",
        },
        {
          numeroCont: "56962262798",
          fecha: "13-11-2019",
          hora: "19:58:13",
          compañia: "movistar",
        },
      ],
    };
  },
};
</script>
<style scoped>


</style>

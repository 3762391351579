<template>
  <div class="container-calendar">
    <div class="month">
      <ul>
        <li class="li-month">
          {{ this.monthNames[new Date().getMonth()] }}
          {{ new Date().getFullYear() }}
        </li>
        <li>
          <i class="fa fa-angle-left i-Cal"></i
          ><i class="fa fa-angle-right i-Cal"></i>
        </li>
      </ul>
    </div>

    <ul class="weekdays">
      <li>Lu</li>
      <li>Ma</li>
      <li>Mie</li>
      <li>Jue</li>
      <li>Vie</li>
      <li>Sab</li>
      <li>Dom</li>
    </ul>

    <ul class="days">
      <li>
        <span @click="$event.target.classList.toggle('activeOne')">1</span>
      </li>
      <li>
        <span @click="$event.target.classList.toggle('activeOne')">2</span>
      </li>
      <li>
        <span @click="$event.target.classList.toggle('activeOne')">3</span>
      </li>
      <li>
        <span @click="$event.target.classList.toggle('activeOne')">4</span>
      </li>
      <li>
        <span @click="$event.target.classList.toggle('activeOne')">5</span>
      </li>
      <li>
        <span @click="$event.target.classList.toggle('activeOne')">6</span>
      </li>
      <li>
        <span @click="$event.target.classList.toggle('activeOne')">7</span>
      </li>
      <li>
        <span @click="$event.target.classList.toggle('activeOne')">8</span>
      </li>
      <li>
        <span @click="$event.target.classList.toggle('activeOne')">9</span>
      </li>
      <li><span @click="$event.target.classList.toggle('active')">10</span></li>
      <li><span @click="$event.target.classList.toggle('active')">11</span></li>
      <li><span @click="$event.target.classList.toggle('active')">12</span></li>
      <li><span @click="$event.target.classList.toggle('active')">13</span></li>
      <li><span @click="$event.target.classList.toggle('active')">14</span></li>
      <li><span @click="$event.target.classList.toggle('active')">15</span></li>
      <li><span @click="$event.target.classList.toggle('active')">16</span></li>
      <li><span @click="$event.target.classList.toggle('active')">17</span></li>
      <li><span @click="$event.target.classList.toggle('active')">18</span></li>
      <li><span @click="$event.target.classList.toggle('active')">19</span></li>
      <li><span @click="$event.target.classList.toggle('active')">21</span></li>
      <li><span @click="$event.target.classList.toggle('active')">22</span></li>
      <li><span @click="$event.target.classList.toggle('active')">23</span></li>
      <li><span @click="$event.target.classList.toggle('active')">24</span></li>
      <li><span @click="$event.target.classList.toggle('active')">25</span></li>
      <li><span @click="$event.target.classList.toggle('active')">26</span></li>
      <li><span @click="$event.target.classList.toggle('active')">27</span></li>
      <li><span @click="$event.target.classList.toggle('active')">28</span></li>
      <li><span @click="$event.target.classList.toggle('active')">29</span></li>
      <li><span @click="$event.target.classList.toggle('active')">30</span></li>
      <li><span @click="$event.target.classList.toggle('active')">31</span></li>
      <div class="d-img">
        <span @click="onClick" class="imgCalendar"></span>
      </div>
    </ul>
    <ModalCalendar></ModalCalendar>
  </div>
</template>

<script>
import ModalCalendar from "../modals/ModalCalendar";
export default {
  components: {
    ModalCalendar,
  },
  data() {
    return {
      isActive: false,
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  },
  methods: {
    onClick() {
      this.$modal.show("modalCalendar");
    },
    activate() {
      this.isActive = true;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  text-align: start;
}
.container-calendar {
  width: 100%;
}
.month {
  /* padding: ; */
  width: 95%;
  text-align: center;
  font-weight: 700;
  margin-top:1rem
}

.month ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0.5rem;
}
.i-Cal {
  padding-left: 1rem;
}

.month ul li {
  color: var(--text-01);
  font-size: 1.5em;
}

.weekdays {
  margin: 0;
  padding-bottom: 1rem;
}

.weekdays li {
  display: inline-block;
  width: 12.6%;
  color: var(--text-01);
  text-align: center;
  font-family: var(--font-family-1);
  font-weight: 500;
  font-size: 0.625em;
}

.days {
  margin: 0;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: 12.6%;
  text-align: center;
  margin-bottom: 2vw;

  color: var(--text-02);

  font-family: var(--font-family-1);
  font-weight: 500;
  font-size: 0.938em;
}
.activeOne {
  background: var(--color-07);
  color: white;
  padding: 5px 10px;
  border-radius: 50%;
}
.days li .active {
  padding: 5px;
  background: var(--color-07);
  color: var(--color-01);
  border-radius: 50%;

  /*  background-image: url("../../assets/img/EllipseLilaCal.svg");
  background-repeat: no-repeat; */
}
.d-img {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  margin-top: -1rem;
  cursor: pointer;
}

.li-month {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.5em;
}
.i-Cal {
  color: var(--text-01);
}
.imgCalendar {
  content: url(../../assets/img/elipLila.png);
}
@media (max-width: 1023px) {
  .month {
    margin-bottom: 1rem;
    margin-top:0.5rem
  }
  .weekdays {
    padding-bottom: 2rem;
  }
  .container-calendar {
    margin-left: 1rem;
  }
  .month ul li {

  font-size: 1.25em;
}
}


@media (min-width: 1024px) and (max-width: 1443px) {
  .month {
    margin-bottom: 1rem;
    margin-top:0.5rem
  }
  .weekdays {
    padding-bottom: 2rem;
  }
    .month ul li {

  font-size: 1.25em;
}
}
</style>

<template>
  <div class="container-biasVoip">
    <div class="allBiasVoip">
      <div class="headerCont">
        <div class="titleHeader">
          <p>VOIP</p>
        </div>
        <div class="textArrow">
          <RouterLink to="voip">
            <p>Ver más</p>
            <span class="arrLeftMailBias"></span>
          </RouterLink>
        </div>
      </div>
      <div class="bodyBiasVoip">
        <div class="subTitleBody">
          <p>Mes vigente</p>
        </div>
        <div class="card-content">
          <div class="minutes">
            <apexchart
              type="radialBar"
              height="250"
              :options="minutes.chartOptions"
              :series="minutes.series"
            ></apexchart>
            <div class="legend">
              <p class="text-legend">1.409</p>
              <p class="text-2">Minutos gastados</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <p class="text-1 border-color">Conectividad última semana</p>
      <div class="chart-container">
        <apexchart
          type="area"
          height="200"
          :options="contact.chartOptions"
          :series="contact.series"
        ></apexchart>
        <!-- <div class="fix"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      minutes: {
        series: [80],
        chartOptions: {
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                size: "60%",
              },
              track: {
                background: "#ACAFB5",
              },
              dataLabels: {
                show: false,
              },
            },
          },
          states: {
            normal: {
              filter: {
                type: "none",
              },
            },
            hover: {
              filter: {
                type: "none",
              },
            },
            active: {
              allowMultipleDataPointsSelection: true,
              filter: {
                type: "none",
              },
            },
          },
          fill: {
            colors: ["#7A40F2"],
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0,
              gradientToColors: ["#C2A7F9"],
              inverseColors: true,
              opacityFrom: 50,
              opacityTo: 50,
              stops: [5, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },

      contact: {
        series: [
          {
            name: "Conectividad",
            data: [750, 1254, 1145, 1000, 1235, 1025, 1073],
          },
        ],
        chartOptions: {
          colors: ["#6600cc"],
          chart: {
            width: "100%",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          fill: {
            colors: ["#7A40F2"],
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0,
              gradientToColors: ["#C2A7F9"],
              inverseColors: true,
              opacityFrom: 50,
              opacityTo: 50,
              stops: [5, 100],
            },
          },
          markers: {
            size: 4,
            strokeColors: "#fff",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 1,
          },
          xaxis: {
            categories: [
              "15-08-2021",
              "16-08-2021",
              "17-08-2021",
              "18-08-2021",
              "19-08-2021",
              "20-08-2021",
              "21-08-2021",
            ],
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            min: 0,
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          grid: {
            padding: {
              left: -10,
              right: 0,
              bottom: 5,
              top: 0,
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
        },
      },
    };
  },
};
</script>
<style scoped>
a {
  display: flex;
}
.container-biasVoip {
  background-color: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.arrLeftMailBias {
  content: url(../../assets/img/iconLila/ArrowLiLeft.svg);
}
.headerCont,
.textArrow {
  display: flex;
}
.headerCont {
  justify-content: space-between;
}
.headerCont {
  margin: 1rem 0 0;
}
.titleHeader p {
  font-size: 1.563em;
}
.titleHeader p,
.textArrow p {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
}

.titleHeader p,
.subTitleBody p {
  color: var(--color-17);
}
.textArrow p {
  font-size: 0.875em;
  letter-spacing: 0.14px;
  color: var(--color-15);
  margin-bottom: 0.3rem;
  margin-right: 1rem;
}
.textArrow {
  align-items: center;
}
.subTitleBody p {
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 0.75em;
  text-align: start;
}
.allBiasVoip {
  width: 90%;
}
.allBiasVoip {
  width: 90%;
  border-bottom: 2px solid #1cc8ee;
}
/* .fix {
  width: 100%;
  display: block;
  height: 43px;
  background: linear-gradient(
    229.59deg,
    #7a40f2 3.12%,
    #814af3 9.99%,
    #8854f3 16.87%,
    #8f5ef4 24.13%,
    #9465f5 29.09%,
    #9b6ff5 35.59%,
    #a076f6 40.93%,
    #a57df6 45.9%,
    #ae8bf7 55.45%,
    #b695f8 62.71%,
    #bb9df8 68.05%,
    #c2a7f9 74.93%
  );
  position: absolute;
  bottom: 0;
  border-radius: 0 0 10px 10px;
} */
.contact {
  width: 100%;
}
.legend {
  text-align: center;
  margin-top: -70px;
  margin-bottom: 2rem;
}
.text-legend {
  font-size: 1.25em;
}

.text-legend,
.text-1,
.text-2 {
  font-family: var(--font-family-3);
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--color-17);
}
.text-2 {
  font-size: 0.625em;
}
.text-1 {
  font-size: 0.75em;
  text-align: start;
  margin: 0.5rem 0 2rem 1rem;
}
.card-content {
  height: 12.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .container-biasVoip {
    width: 100%;
    margin-right: 1rem;
  }
}
@media (min-width: 1024px) and (max-width: 1119px) {
  .titleHeader p {
    font-size: 1.25em;
  }
}
</style>

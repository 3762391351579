<template>
  <div class="row">
    <div class=" col-sm-12  col-lg-6">
      <div class="container-tableVoip01 container-dark">
        <div class="headerTableVoip">
          <p class="titleTablesVoip textWhite">Resumen de llamadas por compañia</p>
        </div>
        <div>
          <table class="tablesVoip">
            <thead>
              <tr>
                <th>Fecha envío</th>
                <th>Cantidad</th>
                <th>Duración promedio</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Entel</td>
                <td>654</td>
                <td>00:02:56</td>
              </tr>
              <tr>
                <td>Wom</td>
                <td>354</td>
                <td>00:02:56</td>
              </tr>
              <tr>
                <td>Claro</td>
                <td>354</td>
                <td>00:02:56</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class=" col-sm-12  col-lg-6 cardVoip02">
      <div class="container-tableVoip02 container-dark">
        <div class="headerTableVoip">
          <p class="titleTablesVoip textWhite">Llamadas por duracíon</p>
        </div>
        <div>
          <table class="tablesVoip">
            <thead>
              <tr>
                <th class="thTable02">Rango (segundos)</th>
                <th class="thTable02">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0 - 1</td>
                <td>40</td>
              </tr>
              <tr>
                <td>2 - 4</td>
                <td>182</td>
              </tr>
              <tr>
                <td>5 - 10</td>
                <td>231</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container-tableVoip01,
.container-tableVoip02 {
  background: var( --color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var( --borderRadius-1);
  padding: 1rem 2rem;
  margin-top: 2rem;
}
.headerTableVoip {
  border-bottom: 1px solid var(--color-03);
}
.container-tableVoip01 {
  width: 99.5%;
}
.container-tableVoip02 {
  width: 97.5%;
}
.cardVoip02 {
  display: flex;
  justify-content: flex-end;
}
.titleTablesVoip {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.75em;
  color: var(--color-17);
  text-align: start;
  margin-bottom: 1rem;
}
th {
  font-family: var(--font-family-3);
  font-weight: normal;
  font-size: 0.938em;
  color: var(--text-01);
  padding: 1rem 0;
}
td {
  font-family: Helvetica;
  font-weight: normal;
  font-size: 0.875em;
  text-align: center;
  color: var(--text-01);
  padding: 1rem 0;
}
table {
  border-collapse: collapse;
}
tr {
  border-bottom: 1px solid rgba(172, 175, 181, 0.48);
}
.thTable02{
    width: 50%
}
@media (max-width: 1200px){
.container-tableVoip01,
.container-tableVoip02{
    width: 100%;
}
.container-tableVoip01{
    margin-top: 4rem
}
.titleTablesVoip{
    font-size: 1.25em
}
}
@media (min-width: 1200px) and (max-width: 1407px){
    .container-tableVoip01{
    margin-top: 2rem
}
.container-tableVoip01 {
  width: 99.5%;
}
.container-tableVoip02 {
  width: 97.5%;
}
.titleTablesVoip{
    font-size: 1.25em
}
}
</style>

<template>
  <div class="container-general">
    <div class="general-header">
      <p class="title-general textWhite">General</p>
    </div>
    <div class="calendar-chart">
      <div class="calendar">
        <p class="date-send textWhite">Fecha ded envío</p>
        <div class="icon-span">
          <span class="icon-calendar"></span>
          <div>
            <span class="text-date textWhite"> 7sept</span> -
            <span class="text-date textWhite ">15:10hrs</span>
          </div>
        </div>
      </div>
      <div class="chart">
        <apexchart
          
          type="radialBar"
          height="270"
          :options="deliverability.chartOptions"
          :series="deliverability.series"
        ></apexchart>
        <div class="legend">
          <p class="legend-perce textWhite">99.2%</p>
          <small class="text-2 textWhite">Entregabilidad</small>
        </div>
      </div>
    </div>
    <div class="chart-bar">
      <apexchart
      class="generalChart"
        type="bar"
        height="125"
        :options="general.chartOptions"
        :series="general.series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      deliverability: {
        series: [70],
        chartOptions: {
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                size: "60%",
              },
              track: {
                background: "#ACAFB5",
              },
              dataLabels: {
                show: false,
              },
            },
          },
          states: {
            normal: {
              filter: {
                type: "none",
              },
            },
            hover: {
              filter: {
                type: "none",
              },
            },
            active: {
              allowMultipleDataPointsSelection: true,
              filter: {
                type: "none",
              },
            },
          },
          fill: {
            colors: ["#7A40F2"],
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0,
              gradientToColors: ["#C2A7F9"],
              inverseColors: true,
              opacityFrom: 50,
              opacityTo: 50,
              stops: [5, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
      general: {
        series: [
          { name: "Envíado", data: [1366] },
          { name: "Aperturas totales", data: [560] },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#596A9C", "#596A9C"],
          stroke: {
            show: true,
            width: 5,
            colors: ["#fff"],
          },
          xaxis: {
            categories: ["Envíado", "Aperturas totales"],
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: ["#404040"],
              },
            },
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          legend: {
            show: false,
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.container-general {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  width: 97%;
  padding: 1rem;
  padding: 1rem 1rem 0 1rem;
}
.title-general,
.date-send,
.text-date,
.legend-perce,
.text-2 {
  font-family: var(--font-family-3);
  color: var(--color-17);
}
.title-general {
  font-weight: 500;
  font-size: 1.25em;
  letter-spacing: 0.694286px;
  text-align: start;
}
.date-send {
  font-size: 1em;
  text-align: start;
}
.text-date {
  font-size: 0.875em;
}
.calendar-chart {
  margin-top: 1rem;
  display: flex;
}
.legend-perce {
  font-weight: 500;
  font-size: 1.563em;
  letter-spacing: 0.694286px;
  margin-bottom: 0.3rem;
}
.text-2 {
  margin-top: 0.3rem;
  font-weight: 500;
  font-size: 0.983em;
}
.calendar {
  width: 50%;
  border-right: 2px solid var(--color-11);
}
.chart {
  width: 50%;
}
.icon-calendar {
  content: url(../../assets/img/iconLila/Calendar.svg);
  margin-bottom: 1rem;
}
.icon-span {
  margin-top: 2rem;
}
.legend {
  margin-top: -6.75rem;
}
.chart-bar {
  padding-left: 1.5rem;
}
@media (min-width: 1200px) and (max-width: 1407px) {
  .title-general {
    font-size: 1.063em;
  }
  .legend-perce {
    font-size: 1.438em;
  }
}
</style>

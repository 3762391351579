<template>
  <modal
    class="modal"
    name="modalCalendar"
    :width="317"
    :height="466"
    :adaptive="true"
  >
    <div class="container-modal">
      <div class="container-all">
        <div class="titleModCal">
          <p>Crear evento</p>
        </div>
        <div class="body-modal">
          <input class='inputModCal' type="text" placeholder="Título" />
        </div>

        <div class="btns">
          <button
            class="btnEvent"
            @click="$event.target.classList.toggle('activeModCal')"
          >
            Evento
          </button>
          <button
            class="btnRecor"
            @click="$event.target.classList.toggle('activeModCal')"
          >
            Recordatorio
          </button>
        </div>
        <div class="color">
          <p class="pColorCal">Color</p>
          <i class="fa fa-circle red"></i>
          <i class="fa fa-circle orange"></i>
          <i class="fa fa-circle yellow"></i>
          <i class="fa fa-circle aqua"></i>
        </div>
        <div class="container-date">
          <p class="pDate">Fecha</p>

          <input
            class="dateofbirth dateofbirth2"
            type="date"
            name="dateofbirth"
            id="dateofbirth"
            v-model="date"
          />
          <div class="input-time">
            <input class="iTime timeOne" type="time" value="13:00" />
            <input class="iTime timeTwo" type="time" value="14:00" />
          </div>
          <div class="container-btns">
            <button class="btn btnCancel">Cancelar</button>
            <button class="btn btnCreate">Crear</button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
 
  data() {
    return {
      date: new Date().toISOString().slice(0, 10),
      isActive: false,
    };
  },
  methods: {
    activate() {
      this.isActive = true;
    },
  },
};
</script>
<style scoped>
.container-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container-all {
  /*  border: 2px solid red; */
  width: 80%;
}
/* .modal {
      position: absolute;

    left: 30rem;
    top: 26rem;
    width: 0;
    height: 0;
} */
.titleModCal {
  font-size: 1.5em;
  font-family: var(--font-family-2);
  font-weight: 600;
  color: var(--text-01);
  text-align: start;
  padding: 2rem 0 1rem 0;
}
input {
  border: none;
  border-bottom: 2px solid var(--color-05);
  width: 100%;
  padding-bottom: 0.5rem;
  outline: none;
}
::placeholder {
  font-family: var(--font-family-2);
  font-size: 1em;
}
.btns {
  display: flex;
  border: 1px solid var(--color-05);
  border-radius: var(--borderRadius-1);
  padding: 0.3rem;
  width: 70%;
  margin-top: 1.5rem;
}
.btnEvent,
.btnRecor {
  font-family: var(--font-family-2);
  font-size: 0.75em;
  background-color: var(--color-01);
  border: none;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  width: 50%;
  border-radius: var(--borderRadius-1);
  color: var(--text-02);
}

.activeModCal {
  background-color: var(--color-05);
  color: var(--color-01);
}
.color {
  display: flex;
  align-items: flex-end;
  margin-top: 2rem;
}
.pColorCal {
  font-family: var(--font-family-2);
  font-weight: 400;
  color: var(--text-02);
  padding-right: 0.2rem;
}
.fa-circle {
  font-size: 1.2em;
  padding: 0 0.2rem 0 0.2rem;
}
.red {
  color: #ff0000;
}
.orange {
  color: #ff7600;
}
.yellow {
  color: #f8c800;
}
.aqua {
  color: #00d3b5;
}
.container-date {
  margin-top: 1.5rem;
}
.pDate {
  font-family: var(--font-family-2);
  font-size: 1em;
  text-align: start;
  color: var(--text-02);
  padding-bottom: 0.5rem;
}
.input-time {
  display: flex;
  padding-top: 0.5rem;
}
.timeOne {
  margin-right: 0.2rem;
}
.timeTwo {
  margin-left: 0.2rem;
}
.container-btns {
  display: flex;
  padding-top: 4rem;
}
.btn {
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-2);
  border: 1px solid var(--color-18);
  font-size: 1.06em;
  text-align: center;
  cursor: pointer;
  width: 50%;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.btnCancel {
  background-color: var(--color-18);
  color: var(--text-02);
  margin-right: 0.2rem;
}
.btnCreate {
  background-color: var(--color-05);
  color: var(--color-01);
  margin-left: 0.2rem;
  border:none
}
.iTime {
  font-family: var(--font-family-2);
  color: var(--text-02);
  border: 1px solid var(--color-05);
  font-size: 1em;
  border-radius: var(--borderRadius-1);
  text-align: center;
  padding: 3px 5px;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../../assets/img/arrow.png") 50% no-repeat;
}

/* DATE PICKER */
.dateofbirth {
  background: url("../../assets/img/iconGrey/calendarGrey.svg") 97% 50% no-repeat;
}

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* custom styles */

label {
  display: block;
}
.dateofbirth {
  border: 1px solid var(--color-05);
  border-radius: var(--borderRadius-1);
  padding: 3px 5px;
  width: 100%;
  text-align: center;
  font-family: var(--font-family-2);
  color: var(--text-02);
  font-size: 1em;
}

@media (min-width: 768px) and (max-width: 1023px) {
  /* .modal {
        left: 15rem;
    top: 12rem;
  }  */
}
@media (min-width: 1024px) and (max-width: 1439px) {
 /*  .modal {
    left: 17.5em;
  } */
}
@media (min-width: 1600px) {
  /*  .modal {
        left: 38rem;
    top: 19.5rem;
  }  */
}
</style>

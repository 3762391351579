<template>
  <div class="drop01">
    <label for="checkHist">
      <div class="dropdownBiasSms">
        <div class="dd-buttonBiasSms">
          <input id="checkHist" type="checkbox" name="menu" />
          <p class="titleDropSms textWhite">
            Historial de campañas
          </p>
          <div class="spanLa">
            <span class="more"> Ver más</span>
            <label for="checkHist" class="arrowD1 "> </label>
          </div>
          <div class="submenuBias  dd-menuDropHis">
            <datatable
              title=""
              :columns="tableColumnsDrafts"
              :clickable="false"
              :rows="tableRowsDrafts"
              :sortable="false"
              class="tableHistCamp"
            >
       
            </datatable>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>
<script>
import DataTable from "vue-materialize-datatable";
export default {
  components: {
    datatable: DataTable,
  },
   data() {
    return {
      tableColumnsDrafts: [
        {
          label: "Fecha envío",
          field: "fechaEnvio",
          numeric: false,
          html: false,
        },
        {
          label: "Campaña",
          field: "campaña",
          numeric: false,
          html: false,
        },
        {
          label: "Mensaje",
          field: "mensaje",
          numeric: false,
          html: false,
        },
        {
          label: "Clicks únicos",
          field: "clicksÚnicos",
          numeric: true,
          html: false,
        },
        {
          label: "Clicks totales",
          field: "clicksTotales",
          numeric: true,
          html: false,
        },
         {
          label: "Créditos",
          field: "creditos",
          numeric: true,
          html: false,
        },
      ],
      tableRowsDrafts: [
        {
          fechaEnvio: "20/03/22 11:22:59",
          campaña: " HabitatLiquiTest",
          mensaje: "[data2][url]",
          clicksÚnicos: 15,
          clicksTotales: 30,
          creditos:230
        },
    {
          fechaEnvio: "20/03/22 11:22:59",
          campaña: "AguasTestHabitat",
          mensaje: "Hola [name], te invito a conocer tu cuenta",
          clicksÚnicos: 15,
          clicksTotales: 30,
          creditos:230
        },
        {
          fechaEnvio: "20/03/22 11:22:59",
          campaña: " HabitatDemoTest",
          mensaje: "[data2][url]",
          clicksÚnicos: 15,
          clicksTotales: 30,
          creditos:230
        },
          {
          fechaEnvio: "20/03/22 11:22:59",
          campaña: " HabitatLiquiTest",
          mensaje: "Hola [name], te invito a conocer tu cuenta",
          clicksÚnicos: 15,
          clicksTotales: 30,
          creditos:230
        },
          {
          fechaEnvio: "20/03/22 11:22:59",
          campaña: " HabitatLiquiTest",
          mensaje: "[data2][url]",
          clicksÚnicos: 15,
          clicksTotales: 30,
          creditos:230
        },
      ],
    }}
};
</script>
<style scoped>
.drop01 {
  background: var( --color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  /*  width: 100%; */
  margin: 0 2rem;
  margin-top: 3rem;
}
.dropdownBiasSms {
  /* display: inline-block; */
  position: relative;
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
}
.dd-buttonBiasSms {
  display: inline-block;
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  padding: 12px 20px 12px 20px;
  /*   background-color: var(--color-01); */

  white-space: nowrap;
  /* width: 100%; */
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* z-index: 1000; */
  /* margin-top: 2rem; */
  font-family: var(--font-family-2);
  font-weight: 600;
  color: var(--color-17);
}

label.arrowD1::before {
  content: url(../../assets/img/iconLila/arrowLiDown.svg);
  padding-left: 0.5rem;
  cursor: pointer;
}
input:checked ~ div label::before {
  content: url(../../assets/img/iconLila/arrowLiUp.svg);
  cursor: pointer;
}
.submenu {
  /*  border: 2px solid  red; */
}
input:checked ~ div.submenuBias {
  display: block;
}
input,
div.submenuBias {
  display: none;
}
.dd-menuDropHis {
  position: absolute;
  /* border: 1px solid #ccc; */
  /*     border-radius: 0 15px 15px 15px;
 */ /* padding: 0; */
  /* margin: 173px 0 0 0; */
  /* box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%); */
  background-color: var(--color-01);
  /* list-style-type: none; */
  /*     width: 140%;
 */ /* height: 8.5rem; */
  z-index: 10;
  /*     top: 2.2rem;
 */
  top: 2.5rem;
  width: 100%;
  border-radius: 0 0 15px 15px;
  height: auto;
  padding: 2rem 1rem;
  right: 0vw;
  
}
.titleDropSms {
  font-family: var(--font-family-3);
  font-weight: normal;
  font-size: 1.375em;
  letter-spacing: 0.694286px;
  color: var(--color-17);
}
.more {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 0.875em;
  letter-spacing: 0.14px;
  color: var(--color-15);
}
.spanLa {
  display: flex;
}
@media  (max-width: 1023px) {
  .dd-menuDropHis {

   overflow-x: scroll
}
}
</style>

<template>
  <div class="all-container">
    <HeaderNewCamp
      text="Nueva campaña SMS"
      url="sms"
      url2="sms/borradores-sms"
      url3="mail/plantillas-mail"
    ></HeaderNewCamp>
    <div class="body-container container-dark">
      <div class="iconInfo">
        <img
          @click="changeComp"
          src="../../assets/img/iconGrey/infoGrey.svg"
          alt=""
        />
      </div>
      <FormNewCamSms v-if="active1"></FormNewCamSms>
      <InforNewCampSms v-if="active2"></InforNewCampSms>
    </div>
  </div>
</template>
<script>

import HeaderNewCamp from "../common/HeaderNewCamp";
import FormNewCamSms from "../panels/FormNewCamSms";
import InforNewCampSms from "../panels/InforNewCampSms";

export default {
  components: {
    
    FormNewCamSms,
    HeaderNewCamp,
    InforNewCampSms,
  },
  data() {
    return {
      active1: true,
      active2: false,
 
    };
  },
  methods: {
  
    changeComp() {
      if (this.active1 == true) {
        this.active1 = false;
        this.active2 = true;
      } else {
        this.active1 = true;
        this.active2 = false;
      }
    },
  },
};
</script>
<style scoped>
.body-container {
  background-color: var(--color-01);
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.iconInfo {
  display: flex;
  width: 90%;
  justify-content: flex-end;
  margin-top: 2rem;
  cursor: pointer;
}

</style>

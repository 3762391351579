<template>
  <div class="container-table container-dark">
    <div class="d-title">
      <p class="p-title titleDark">
        Resumen mensual
      </p>
    </div>
    <table>
      <tbody>
        <tr>
          <th class="titleDark">Mensajes enviados</th>
          <th class="titleDark">Envíos realizados</th>
          <th class="titleDark">Créditos gastados</th>
          <th class="titleDark">Nuevos contactos</th>
        </tr>
        <tr>
          <td class="tdBorder">{{ data1 }}</td>
          <td class="tdBorder">{{ data2 }}</td>
          <td class="tdBorder">{{ data3 }}</td>
          <td>{{ data4 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["data1", "data2", "data3", "data4"],
};
</script>
<style scoped>
.container-table {
  background-color: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 1rem 2rem 0.063rem 2rem;
}
.p-title {
  text-align: start;
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  color: var(--color-17);
  padding-bottom: 1rem;
}
th {
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 0.813em;
  color:  rgba(64, 64, 64, 0.9);;
}

table {
  width: 100%;
  /* padding-bottom: 0.5rem; */
}
td {
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 2.188em;
  color: var(--color-03);
  letter-spacing: 0.694286px;
}

.tdBorder {
  border-right: 1.3px solid var(--color-15);
}
@media (max-width: 991px) {
  .p-title {
    font-size: 1.25em;
  }
  td {
    font-size: 1.8em;
  }
  .img {
    width: 20%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .p-title {
    font-size: 1.25em;
  }

  th {
    font-size: 0.86em;
  }
  td {
    font-size: 1.8em;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
}
</style>

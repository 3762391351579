<template>
  <div class="drop01">
    <label for="check">
      <div class="dropdownBiasMail">
        <div class="dd-buttonBiasMail">
          <input id="check" type="checkbox" name="menu" />
          <p class="titleDropBias textWhite">
            Dominios de correo
            <span class="more"> Ver más</span>
            <label for="check" class="arrowD1 "> </label>
          </p>
          <div class="submenuBias  dd-menuBias">
            <table>
              <tr>
                <th class="textWhite">Domino</th>
                <th class="textWhite">Usuarios</th>
              </tr>
              <tr>
                <td class="textWhite">@gmail.com</td>
                <td class="textWhite">8939</td>
              </tr>
              <tr>
                <td class="textWhite">@hotmail.com</td>
                <td class="textWhite">93893</td>
              </tr>
              <tr>
                <td class="textWhite">@icloud.com</td>
                <td class="textWhite">892384</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.drop01 {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  border-radius: 15px;
  width: 97%;
  margin-bottom: 2rem;
  /*  padding: 1rem 1rem 0 1rem; */
}
.drops {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.dropdownBiasMail {
  /* display: inline-block; */
  position: relative;
  width: 100%;
  cursor: pointer;
}
.dd-buttonBiasMail {
  display: inline-block;
  /* border: 1px solid gray; */
  border-radius: var(--borderRadius-1);
  padding: 12px 20px 12px 20px;
  /*   background-color: var(--color-01); */

  white-space: nowrap;
  /* width: 100%; */
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* z-index: 1000; */
  /* margin-top: 2rem; */
  font-family: var(--font-family-2);
  font-weight: 600;
  color: var(--color-17);
}

label.arrowD1::before {
  content: url(../../assets/img/iconLila/arrowLiDown.svg);
  padding-left: 0.5rem;
  cursor: pointer;
}
input:checked + p label::before {
  content: url(../../assets/img/iconLila/arrowLiUp.svg);
  cursor: pointer;
}
.submenu {
  /*  border: 2px solid  red; */
}
input:checked ~ div.submenuBias {
  display: block;
}
input,
div.submenuBias {
  display: none;
}
.dd-menuBias {
  position: absolute;
  /* border: 1px solid #ccc; */
  /*     border-radius: 0 15px 15px 15px;
 */ /* padding: 0; */
  /* margin: 173px 0 0 0; */
  /* box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%); */
  background-color: var(--color-01);
  /* list-style-type: none; */
  /*     width: 140%;
 */ /* height: 8.5rem; */
  z-index: 10;
  /*     top: 2.2rem;
 */
  top: 2.5rem;
  width: 100%;
  border-radius: 0 0 15px 15px;
  height: auto;
  padding: 0 1rem;
}
.titleDropBias {
  font-size: 1.563em;
  color: var(--color-17);
}
.titleDropBias,
.more {
  font-family: var(--font-family-2);
  font-weight: 600;
}
.more {
  font-size: 0.625em;
  letter-spacing: 0.14px;
  color: var(--color-15);
  margin-left: 14vw;
}

td,
th {
  border-bottom: 1px solid rgba(172, 175, 181, 0.48);
  text-align: left;
  padding: 8px;
  font-family: var(--font-family-3);
  font-weight: normal;
  color: var(--text-01);
}
td {
  font-size: 0.938em;
}
th {
  font-size: 0.875em;
}
table {
  border-top: 1px solid var(--color-11);
  margin-top: 2rem;
}
@media (max-width: 1200px) {
  .more {
    margin-left: 16vw;
  }
}
@media (min-width: 1200px) and (max-width: 1407px) {
  .titleDropBias {
    font-size: 1.063em;
  }
  .dd-buttonBiasMail {
    padding: 20px;
  }
}
</style>

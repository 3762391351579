<template>
  <div class="container-shipPro container-dark">
    <div class="allContainer">
      <div class="nuSend">
        <span class="shipping"></span>
        <span>Envíos en curso</span>
        <p>{{ numSend }}</p>
      </div>
      <span class="arrowShip"></span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["numSend"],
};
</script>
<style scoped>
.container-shipPro {
  background-color: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  margin-top: 3rem;
  width: 93%;
  display: flex;
  justify-content: center;
  height: 61%;
  /* border:2px solid red */
}
.allContainer {
  display: flex;
  width: 80%;
  /*   border: 2px solid red;
 */
  align-items: center;
  justify-content: space-between;
}
.nuSend {
  display: flex;
  align-items: center;
  /*  width: 60%;
  justify-content: space-between; */
    width: 83%;
  justify-content: space-between;
}
.arrowShip {
  content: url(../../assets/img/iconOrange/ArrowRig.svg);
}
.nuSend p {
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 0.694286px;
  color: var(--color-03);
  margin-right: 2rem;

}
.nuSend span {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  /* identical to box height */

  color: var(--color-17);
}

.shipping {
  content: url(../../assets/img/iconWhite/shipping02.svg);
}
@media (max-width: 992px) {
  .container-shipPro {
    height: 5rem;
    margin-top: 2rem;
    width: 100%;
  }
  .allContainer {
    width: 90%;
    justify-content: space-between;
  }
  .nuSend {
    width: 83%;
  }
  .nuSend p {
    font-size: 1.65em;
    margin-right: 1rem;
  }
  .nuSend span {
    font-size: 1.25em;
  }
}
@media (min-width: 993px) and (max-width: 1199px) {
   .allContainer {
    width: 90%;
    justify-content: space-between;
  }
  .nuSend p {
    margin-right: 1rem;
  }
  .nuSend p {
    font-size: 1.65em;
  }
  .nuSend span {
    font-size: 1.25em;
  }
  .nuSend {
    width: 88%;
  }
}
</style>

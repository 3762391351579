<template>
  <div class="container-notiAndSetti">
    <div class="row  " :class="widthRow">
      <div class="slot">
        <slot name="shipping"></slot>
        <slot name="notif"></slot>
        <slot name="config"></slot>
        <slot name="info"></slot>
      </div>
      <!--       <label class="dropdownSlot">
        <div class="dd-buttonSlot">
          <i v-on:click="addClass" class="material-icons icon3">menu</i>
        </div>
        <input type="checkbox" class="dd-input2" id="test" />

        <div v-on:click="deleteClass" id="minumenu2" class="dd-menu2">
          <div class="dSlot2">
            <slot name="logout"></slot>
            <slot name="info"></slot>
            <slot name="config"></slot>
            <slot name="notif"></slot>
          </div>
        </div>
      </label>
 -->
      <img class="" src="../../assets/img/imgUser.svg" alt="" />
      <!--  <label class="dropdown">
        <div class="dd-button">
          <p>{{ name }} <i class="fa fa-angle-down"> </i></p>
        </div>

       
      </label> -->
      <input id="check01" type="checkbox" name="menu" />
      <p class="pNameNotif">
        {{ name }}
      </p>
      <label for="check01"> <span class="arrowD "></span> </label>
      <!--   <label class="dd-button">
        <p>{{ name }} <label for="check01" class="arrowD"> </label></p>
      </label> -->
      <div class="submenuNotif  dd-menu">
        <div class="container-table">
          <p class="titleTable">
            Envíos en curso
          </p>
          <table class="default">
            <tr>
              <td>
                <img src="../../assets/img/iconOrange/mailLi.svg" alt="" />
              </td>
              <td class="campTitle">Retira tu 10%</td>
              <td>
                <span class="pause" @click="toggle"></span>
              </td>
              <td><span class="closeCircle"></span></td>
            </tr>

            <tr>
              <td>
                <img src="../../assets/img/iconOrange/commentLi.svg" alt="" />
              </td>
              <td class="campTitle">Hábitat</td>
              <td>
                <span class="pause" @click="toggle"></span>
              </td>
              <td><span class="closeCircle"></span></td>
            </tr>
            <tr>
              <td>
                <img src="../../assets/img/iconOrange/commentLi.svg" alt="" />
              </td>
              <td class="campTitle">Prueba mail 2</td>
              <td>
                <span class="pause" @click="toggle"></span>
              </td>
              <td><span class="closeCircle"></span></td>
            </tr>
            <tr>
              <td>
                <img src="../../assets/img/iconOrange/mailLi.svg" alt="" />
              </td>
              <td class="campTitle">Cruz verde</td>
              <td>
                <span class="pause" @click="toggle"></span>
              </td>
              <td><span class="closeCircle"></span></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let idElement;
export default {
  props: {
    widthRow: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      name: "Javier Astudillo",
      collapsed: true,
    };
  },
  methods: {
    toggle(event) {
      event.target.classList.toggle("selected");
    },
    addClass(event) {
      // console.log(event.target);
      event.target.classList.toggle("active");
      // this.isAddClass = true;
      idElement = event.target;
    },
    deleteClass() {
      idElement.classList.remove("active");
      //  this.isAddClass = false;
    },
  },
};
</script>

<style scoped>
.pause {
  content: url(../../assets/img/iconGrey/PauseGrey.svg);
  cursor: pointer;
  margin-right: 0.5rem;
}
.selected {
  content: url(../../assets/img/iconOrange/PlayOrange.svg);
  cursor: pointer;
  margin-right: 0.5rem;
}
.closeCircle {
  content: url(../../assets/img/iconGrey/closeCircle.svg);
  cursor: pointer;
  margin-right: 0.5rem;
}
.container-notiAndSetti {
  display: flex;
  justify-content: flex-end;
}
.row {
  align-items: center;
  width: 55%;
  justify-content: space-evenly;
}
.widthRow {
  width: 100%;
}

.slot {
  display: flex;
  width:9rem;
  justify-content: space-around;
}

p,
.pNameNotif {
  font-family: var(--font-family-1);
  font-weight: 400;
  font-size: 1.05em;
  color: var(--text-01);
  display: flex;
}
.container-table {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding-top: 1rem; */
  padding: 1rem 1rem 0 1rem;
}

.campTitle {
  font-family: var(--font-family-3);
  font-size: 0.938em;
  color: var(--text-01);
  text-align: start;
  padding-left: 0.5rem;
}

.titleTable {
  font-family: var(--font-family-2);
  color: var(--color-15);
  font-weight: 600;
  font-size: 0.875em;
  text-align: start;
  padding: 0.5rem;
  padding-bottom: 2rem;
}

a {
  display: flex;
}

/* Dropdown1 */

.dd-button {
  padding: 12px 30px 12px 0px;
}
input,
div.submenuNotif {
  display: none;
}
.submenuNotif {
  /*  border: 2px solid  red; */
  /*  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15); */
  background-color: var(--color-01);
  position: absolute;
  top: 7rem;
  right: 7%;
  width: 17rem;
  height: auto;
  border-radius: var(--borderRadius-1);
}

label {
  cursor: pointer;
}
td img {
  margin-left: 0.2rem;
}
td {
  /* margin-bottom: 1rem; */
  padding-bottom: 1rem;
}
/*show the submenu when input is checked*/
input:checked ~ div.submenuNotif {
  display: block;
}
span.arrowD::before {
  content: url(../../assets/img/iconGrey/ArrowDown.svg);
  padding-left: 0.5rem;
}
input:checked + p span::before {
  content: url(../../assets/img/iconGrey/ArrowUp.svg);
}
/* dropdwon2 */
.dropdownSlot {
  display: none;
}
@media (max-width: 991px) {
  .row {
    flex-wrap: nowrap;
    width: 85%;
  }

  .dd-button {
    padding: 12px 18px 12px 0px;
  }

  p {
    font-size: 0.9em;
  }
  /* dropdown2 */

  .pNameNotif {
    display: none;
  }
}
@media (min-width: 999px) and (max-width: 1199px) {
  .row {
    width: 85%;
  }
  .widthRow {
    width: 100%;
  }
  /*  .submenu{

  right: 4.7rem;
 
} */
  /* .imgUser {
     padding-left: 0; 
    padding-right: 0.5rem;

}
.imgMenu{
     padding-left: 0; 
    padding-right: 0.5rem;
   
} */
  p {
    font-size: 1em;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .row {
    width: 60%;
  }

  .widthRow {
    width: 100%;
  }
}
@media (min-width: 1600px) {
  /*   .submenu{

  right: 10rem;
 
} */
  .widthRow {
    width: 80%;
  }
}
</style>

<template>
  <div class="container-listSites">
    <div class="allList">
      <div class="headerCont">
        <div class="titleHeader">
          <p>Mail</p>
        </div>
        <div class="textArrow">
          <RouterLink to="/alterapay">
            <p>Ver más</p>
            <span class="arrListBias"></span>
          </RouterLink>
        </div>
      </div>
      <div class="bodyList">
        <div class="subTitleBody">
          <p>Lista de sitios</p>
        </div>
        <div class="ruby">
          <div>
            <p class="textRuby01">Ruby</p>
            <p class="textRuby02">ruby.alterabias.com/</p>
          </div>
          <div>
            <RouterLink to="/alterapay">
              <button>Ver</button>
            </RouterLink>
          </div>
        </div>
        <div class="ccu">
          <div>
            <p class="textCcu01">CCU</p>
            <p class="textCcu02">ccu.alterabias.com/</p>
          </div>
          <div>
            <RouterLink to="/alterapay">
              <button>Ver</button>
            </RouterLink>
          </div>
        </div>
        <div class="alteraAfp">
          <div>
            <p class="textAfp01">Altera AFP</p>
            <p class="textAfp02">alteralanding.com/afp</p>
          </div>
          <div>
            <RouterLink to="/alterapay">
              <button>Ver</button>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container-listSites {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  /*  width: 90%; */
  display: flex;
  justify-content: center;
  height: 100%;
}
a {
  display: flex;
}
.allList {
  /* display: flex; */
  width: 90%;
}
.headerCont,
.textArrow {
  display: flex;
}
.headerCont {
  justify-content: space-between;
}
.headerCont {
  margin: 1rem 0 0;
}
.titleHeader p {
  font-size: 1.563em;
}
.titleHeader p,
.textArrow p {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
}

.titleHeader p,
.subTitleBody p {
  color: var(--color-17);
}
.textArrow p {
  font-size: 0.875em;
  letter-spacing: 0.14px;
  color: var(--color-15);
  margin-bottom: 0.3rem;
  margin-right: 1rem;
}
.textArrow {
  align-items: center;
}
.arrListBias {
  content: url(../../assets/img/iconLila/ArrowLiLeft.svg);
}
.subTitleBody p {
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 0.75em;
  text-align: start;
}
.ruby,
.ccu,
.alteraAfp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  border-bottom: 2px solid #1cc8ee;
}
.textRuby01,
.textRuby02,
.textCcu01,
.textCcu02,
.textAfp01,
.textAfp02 {
  font-family: var(--font-family-3);
  font-weight: 500;
  letter-spacing: 0.694286px;
  color: var(--color-17);
}
.textRuby02,
.textCcu02,
.textAfp02{
  margin-bottom: 1rem
}
.textRuby01,
.textCcu01 {
  font-size: 1.25em;
}
.textRuby02,
.textCcu02,.textAfp02 {
  font-size: 0.75em;
}
button {
  background: var(--color-18);
  border: 1px solid var(--color-18);
  box-sizing: border-box;
  border-radius: var(--borderRadius-1);
  width: 7rem;
  /* padding: 0.2rem 0; */
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  color: var(--color-05);
  cursor:pointer
}
.bodyList {
  height: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (max-width: 991px){
  .container-listSites {
    margin-top: 2rem;
}
}
@media (max-width: 1024px){
  .container-listSites {
    margin-top: 2rem;
}
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .container-listSites {
    margin-top: 0;
}
.titleHeader p{
  font-size: 1.25em;
}
.textRuby01, .textCcu01,.textAfp01 {
    font-size: 15px;
}
button {
  
    width: 5rem;
  
    font-size: 15px;
   
}
}
</style>

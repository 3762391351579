<template>
  <div class="container-welcome">
    <div class="title">
      <h1>Buen día, {{ name }}</h1>
         <img
          src="../../assets/gif/home/animation_500_kobkewnz (1).gif"
          alt=""
          class='imgLi'
        />
    </div>
    <div class="body-welcome">
      <div class="btns">
        <div class="container-dropdown">
          <Dropdown></Dropdown>
        </div>
        <ModalCredits></ModalCredits>
        <div class="container-btn">
          <button @click="onClick">Solicitar créditos</button>
        </div>
      </div>
      <div class="container-img">
        <img 
          src="../../assets/gif/home/animation_500_kobkewnz (1).gif"
          alt=""
          class='imgTa'
        />
      </div>
    </div>
  </div>
</template>
<script>
import Dropdown from "../../components/buttons/Dropdown";
import ModalCredits from "../modals/ModalCredits";
export default {
  components: {
    Dropdown,
    ModalCredits,
  },
  data() {
    return {
      name: "Javier ",
    };
  },
  methods: {
    onClick() {
      this.$modal.show("modalCredits");
    },
  },
};
</script>
<style scoped>
.title {
  text-align: start;
}

.container-welcome {
  padding: 2rem 0.5rem 1.5rem 2.5rem;
  background-color: var(--color-04);
  border-radius: var(--borderRadius-1);
  width: 95%;
  margin-left: 0.5rem; 
}
h1 {
  font-family: var(--font-family-2);
  font-size: 1.875em;
  color:var(--text-01)
}
.body-welcome {
  display: flex;
}
/* .btns {
  display: flex;
  flex-direction: column;
  width: 50%;

} */
.container-dropdown {
  /*   border: 2px solid pink; */
 /*  display: flex; */
  margin-top: 2.5rem;
}
.container-btn {
  /* border: 2px solid rgb(24, 190, 18); */
 /*  display: flex; */
  margin-top: 3rem;
}
button {
  width: 100%;
  border-radius: 15px;
  padding: 12px 20px 12px 20px;
  font-size: 1.063em;
  cursor: pointer;
  background-color: var(--color-03);
  border: 1px solid var(--color-12);
  color: var(--color-01);
  font-family: var(--font-family-2);
  font-weight: 600;
}
.container-img {
  width: 50%; 
  /*   border: 2px solid red; */
}
img {
  width: 95%;
}
.imgLi{
  display:none
}
.btns {
    /* overflow: visible; */
    width: 50%;
}
@media (max-width: 1022px){
.container-welcome {
    width: 75%;
    margin-bottom: 1rem;
    height: 100%;
}
img {
    width: 78%;
}
}
@media (min-width: 1022px) and (max-width: 1024px) {
  .container-welcome {
    width: 60%;
    margin-bottom: 1rem;
    height: 100%;
}
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .imgTa{
    display:none
  }
  .imgLi{
    display: initial;
    width: 50%;
    border-radius: 50%;
}
/*   .imgLi {
    content: url("../../assets/gif/home/animation_500_kobkewnz (1).gif");
    width: 50px;
    height: 50px;
    transform: scale(3);
    border-radius: 50%;
    
    margin-top: 2rem;
    margin-left: 1rem;
  } */
  .container-img {
    display: none;
  }
  .title {
    display: flex;
    align-items: center;
   /*  margin-bottom: 4rem; */
      /*  margin-top: .5rem; */
  }
  h1 {
    width: 50%;
    font-size: 1.375em;
        margin-left: 2rem;
  }
  .container-dropdown {
    margin-top: 0;
  }
  .container-btn {
    margin-top: 2.5rem;
  }
/*   img {
    width: 90%;
  } */

  .container-welcome{
    padding: 0;
  /*   padding-top: 0.5rem; */
    width: 90% 
  }
  .body-welcome {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .btns {
    display: flex;
    flex-direction: column;
    width: 55%;
    /*  border: 2px solid black; */
    margin-bottom:2rem;
        margin-top: 1rem;
  }
  button{
    font-size: 0.938em
  }
}
/* @media (min-width: 1024px){

}
 */
</style>

<template>
  <table  class="bordered">
    <thead :style="{ display: display}">
      <tr>
        <th class="thNewCamp"><label>Nombre</label></th>
        <th class="thNewCamp"><label>GSM</label></th>
        <th class="thNewCamp"><label>Mail</label></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><label>Juanito Perez</label></td>
        <td><label>7682789</label></td>
        <td>
          <label> jperez@gmail.com</label>
        </td>
      </tr>
      <tr>
        <td><label>Camila Ossa</label></td>
        <td><label> 98723783</label></td>
        <td>
          <label> jperez@gmail.com</label>
        </td>
      </tr>
      <tr>
        <td><label>Jose Marinna</label></td>
        <td><label>87399933</label></td>
        <td>
          <label> jperez@gmail.com</label>
        </td>
      </tr>
      <tr>
        <td><label>Cristobal Manriquez</label></td>
        <td><label>82092380</label></td>
        <td>
          <label> jperez@gmail.com</label>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
    data: function(){
        return{
            display:'table-header-group'
        }
    },
    created() {
        /*Al crear el componente, declaramos una escucha en el evento changeColor, que se ejecutará si algún
        componente envia un evento con este nombre*/
        this.$root.$on('changeDisplay', ()=>{

            if (this.display=='table-header-group') {
                this.display='none'
            }else {

            this.display = 'table-header-group';
            }

            console.log(this.display)
        });

    },
};
</script>
<style scoped>
/* tabla */

table {
  border-collapse: separate;
  border-spacing: 0;
  /*     width: 600px;
    margin: 30px; */
}
.bordered {
  border: 1px solid var(--color-05);
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

th {
  font-family: var(--font-family-3);
  font-size: 1.25em;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--color-01);
}
label {
  font-weight: normal;
}
.bordered td,
.bordered th {
  border-left: 2px solid var(--color-01);
  padding: 0.5rem 0;
  text-align: center;
  width: 39%;
}
.bordered th {
  background-color: var(--color-05);
}
.bordered td:first-child,
.bordered th:first-child {
  border-left: none;
}
.bordered th:first-child {
  -moz-border-radius: 15px 0 0 0;
  -webkit-border-radius: 15px 0 0 0;
  border-radius: 15px 0 0 0;
}
.bordered th:last-child {
  -moz-border-radius: 0 15px 0 0;
  -webkit-border-radius: 0 15px 0 0;
  border-radius: 0 15px 0 0;
}
.bordered th:only-child {
  -moz-border-radius: 15px 15px 0 0;
  -webkit-border-radius: 15px 15px 0 0;
  border-radius: 15px 15px 0 0;
}
.bordered tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 15px;
  -webkit-border-radius: 0 0 0 15px;
  border-radius: 0 0 0 15px;
}
.bordered tr:last-child td:last-child {
  -moz-border-radius: 0 0 15px 0;
  -webkit-border-radius: 0 0 15px 0;
  border-radius: 0 0 15px 0;
}
.headCheck {
  border: 2px solid var(--color-01);
  background-color: var(--color-05);
}

td label {
  font-family: Helvetica;
  font-size: 1.25em;
  letter-spacing: 0.694286px;
  color: var(--text-02);
}
table {
  table-layout: fixed;
  width: 100%;
}
tr th:nth-child(2) {
  width: 5rem;
}
</style>

<template>
  <div class="container-btnJo">
     <router-link to="/contactos" class="linkBtnJour">
    <div class="titleBtnJo">
      <p class="titleJour">Conoce el</p>
      <p class="titleJour2">Journey</p>
      <p class="titleJour">de tu cliente</p>
    </div>
    <div class="iconBtnJo">
      <span class="arroBtnJour"></span>
    </div>
     </router-link>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
a {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    border-radius: 15px;
}
.container-btnJo {
/*   background: #ffffff; */
  border: 1px solid var(--color-20);
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  width: 90%;
  display: flex;
 /*  align-items: center;
  justify-content: space-around; */
  height: 100%;
  background-color: none
}

.titleJour,
.titleJour2 {
  font-family:var(--font-family-2);
  font-style: normal;
  font-size: 1.25em;
  line-height: 31px;
  letter-spacing: 2px;
  color: var(--color-20);
  text-align: start;
}
.titleJour2 {
  font-weight: 800;
  font-size: 25px;
}
.arroBtnJour {
  content: url(../../assets/img/iconOrange/ArrowRig.svg);
}
@media (min-width: 992px) and (max-width: 1199px) {
.titleJour,
.titleJour2 {
 
  font-size: 1.15em;

}
.titleJour2 {
  
  font-size: 1.375em;
}
}
</style>

<template>
  <div class="all-container">
    <div class="btnsCamp">
      <button class="btnHead   ">Mis plantillas</button>
      <button class="btnHead btn-drafts">Borradores</button>
    </div>
    <div class="container-table">
      <datatable
        title=""
        :columns="tableColumnsDrafts"
        :clickable="false"
        :rows="tableRowsDrafts"
        :sortable="false"
        class="tableDrafts"
      >
        <th class="th" slot="thead-tr">
          <p>Servicio</p>
        </th>
        <template slot="tbody-tr">
          <td class="td">
            <span class="sms"></span>
          </td>
        </template>
      </datatable>
    </div>
    <div class="btns-foot">
      <router-link to="/sms/nueva-camp-sms" class="">
        <button class="btn-foot">Cancelar</button>
      </router-link>
      <button class="btn-foot btn-cancel">Usar borrador</button>
    </div>
  </div>
</template>
<script>
import DataTable from "vue-materialize-datatable";
export default {
  components: {
    datatable: DataTable,
  },
  data() {
    return {
      tableColumnsDrafts: [
        {
          label: "Nombre de campaña",
          field: "nombreCampaña",
          numeric: false,
          html: false,
        },
        {
          label: "Asunto/Mensaje",
          field: "asunMens",
          numeric: false,
          html: false,
        },
        {
          label: "Día de creacíon",
          field: "diaCra",
          numeric: false,
          html: false,
        },
        {
          label: "Remitente",
          field: "remitente",
          numeric: false,
          html: false,
        },
        {
          label: "Cantidad de contactos",
          field: "cantCont",
          numeric: true,
          html: false,
        },
      ],
      tableRowsDrafts: [
        {
          nombreCampaña: "Prueba 1",
          asunMens: " Tu solicitu de retiro fué rechazada",
          diaCra: "20/03/22 11:22:59",
          remitente: "AFP habitat (ariela.tolosa.silva@gmail.com)",
          cantCont: 10,
        },
        {
          nombreCampaña: "Prueba 1",
          asunMens: " Tu solicitu de retiro fué rechazada",
          diaCra: "20/03/22 11:22:59",
          remitente: "AFP habitat (ariela.tolosa.silva@gmail.com)",
          cantCont: 33,
        },
        {
          nombreCampaña: "Prueba 1",
          asunMens: " Tu solicitu de retiro fué rechazada",
          diaCra: "20/03/22 11:22:59",
          remitente: "AFP habitat (ariela.tolosa.silva@gmail.com)",
          cantCont: 3,
        },
      ],
    };
  },
};
</script>
<style scoped>
.all-container {
  width: 100%;
}
.btnsCamp {
  display: flex;
  justify-content: flex-end;
}
.btnHead {
  background-color: var(--color-01);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--borderRadius-1);
  border: none;
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  letter-spacing: 0.14px;
  color: var(--color-03);
  padding: 0.7rem 0;
  cursor: pointer;
  width: 14vw;
}
.btn-drafts {
  color: var(--color-01);
  background-color: var(--color-03);
  margin-left: 1rem;
}
.sms {
  content: url(../../assets/img/iconOrange/comment.svg);
}
.tableDrafts {
  margin-top: 4rem;
}
.btns-foot {
  display: flex;
  justify-content: flex-end;
  margin-top: 20rem;
}
.btn-foot {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  color: var(--text-02);
  background-color: var(--color-18);
  border: 1px solid var(--color-18);
  border-radius: var(--borderRadius-1);
  width: 10vw;
  padding: 0.3rem 0;
  cursor: pointer;
}
.btn-cancel {
  background-color: var(--color-05);
  border: none;
  margin-left: 1rem;
  color: var(--color-01);
}
@media (max-width: 991px) {
  .btnHead {
    width: 21vw;
  }

  .btn-foot {
    width: 18vw;
  }
  .container-table {
    overflow-y: hidden;
    overflow-x: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* .container-table{
  padding: 1rem
} */

  .container-table {
    overflow-y: hidden;
    overflow-x: auto;
  }
  .btn-foot {
    width: 13vw;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  /*   .container-table{
  overflow-y: hidden;
    overflow-x: auto;
} */
  /* .tableMail{
   
    margin: 0 0.5rem;
} */
}
</style>

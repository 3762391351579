<template>
  <div class="container-chartsMail">
    <p class="title-charts">MAIL</p>
    <div class="row">
      <div class="col-sm-4 col-md-4 columns">
        <div class="campaign-sent">
          <div class="icon-mes">
            <router-link to="/mail" class="">
            <span class="mes-mail"></span>
            </router-link>
            <div>
              <p class="number textWhite">10</p>
              <p class="textChart textWhite">Campañas</p>
              <p class="textChart textWhite">enviadas</p>
            </div>
          </div>
          <div>
            <p class="subText">A partir de</p>
            <p class="textDate textWhite">{{ dateInitial }} al {{ dateFinal }}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 columns">
        <div class="minutes">
          <apexchart
            type="radialBar"
            height="250"
            :options="minutes.chartOptions"
            :series="minutes.series"
          ></apexchart>
          <div class="legend">
            <p class="text-legend">44.705</p>
            <p class="text-2">Créditos gastados <br />últimos 30 días</p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 columns03">
        <div class="progressAll">
          <div class="progress01">
            <div class="textPro">
              <span >Apertura</span>
            </div>
            <div class="progrBar">
              <progress-bar
                size="medium"
                :bar-border-radius="15"
                bar-color="#7A40F2"
                bg-color="#EDEEF4"
                val="100"
              ></progress-bar>
            </div>
          </div>
          <div class="progress01">
            <div class="textPro">
              <span>Clicks</span>
            </div>
            <div class="progrBar">
              <progress-bar
                size="medium"
                :bar-border-radius="15"
                bar-color="#7A40F2"
                bg-color="#EDEEF4"
                val="50"
              ></progress-bar>
            </div>
          </div>
          <div class="progress01">
            <div class="textPro">
              <span>Rebotes</span>
            </div>
            <div class="progrBar">
              <progress-bar
                size="medium"
                :bar-border-radius="15"
                bar-color="#7A40F2"
                bg-color="#EDEEF4"
                val="30"
              ></progress-bar>
            </div>
          </div>
        </div>
        <div class="container-down">
          <div class="allPerce">
            <div>
              <span class="letterOp"></span>
              <span class="perc textWhite">72%</span>
            </div>
            <div>
              <p class="textPerc textWhite">Aperturas</p>
            </div>
          </div>
          <div class="allPerce">
            <div>
              <span class="clicks"></span>
              <span class="perc textWhite">28%</span>
            </div>
            <div>
              <p class="textPerc textWhite">Clicks</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProgressBar from "vue-simple-progress";
export default {
  components: {
    ProgressBar,
  },
  data() {
    return {
      dateInitial: "09/10/2019",
      dateFinal: "01/04/2021",
      minutes: {
        series: [80],
        chartOptions: {
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                size: "60%",
              },
              track: {
                background: "#ACAFB5",
              },
              dataLabels: {
                show: false,
              },
            },
          },
          states: {
            normal: {
              filter: {
                type: "none",
              },
            },
            hover: {
              filter: {
                type: "none",
              },
            },
            active: {
              allowMultipleDataPointsSelection: true,
              filter: {
                type: "none",
              },
            },
          },
          fill: {
            colors: ["#7A40F2"],
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0,
              gradientToColors: ["#C2A7F9"],
              inverseColors: true,
              opacityFrom: 50,
              opacityTo: 50,
              stops: [5, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.container-chartsMail {
  width: 100%;
  display: flex;
  flex-direction: column;
  /*  align-items: center; */
}
.title-charts,
.subText {
  font-family: var(--font-family-2);
  font-weight: 600;
  letter-spacing: 3px;
  text-align: start;
}
.title-charts {
  font-size: 1.563em;
  color: var(--color-17);
  margin: 2rem 0 2rem 3rem;
}
.mes-mail {
  content: url(../../assets/img/iconLila/MessageLila.svg);
}
.icon-mes {
  display: flex;
  justify-content: space-between;
      align-items: center;
}
.number,
.textChart,
.textDate,
.text-legend,
.text-2,
.textPro span, .perc,.textPerc {
  font-family: var(--font-family-3);
  font-style: normal;
  font-weight: 500;
  color: var(--color-17);
}
.number,
.textChart,
.textDate {
  text-align: start;
}
.number,
.text-legend, .perc {
  font-size: 1.563em;
  letter-spacing: 0.694286px;
}
.perc{
  margin-left: 1rem
}
.textChart,
.text-2,
.textPro span {
  font-size: 0.938em;
}
.textPro span {
  text-align: start;
}
.subText {
  color: var(--color-15);
  font-size: 0.875em;
  letter-spacing: 0.14px;
}
.textDate,.textPerc  {
  font-size: 0.875em;
}
.legend {
  text-align: center;
  margin-top: -30%;
}
.row {
  align-items: center;
}
.columns,
.columns03 {
  display: flex;
  flex-direction: column;

  height: 100%;
  justify-content: center;
 
}
.columns {
  align-items: center;
}
.columns03{
     justify-content: space-evenly;
}
.progress01 {
  display: flex;
  align-items: center;
  text-align: start;
}
.progrBar {
  width: 40%;
}
.textPro {
  width: 30%;
}
.progressAll {
  width: 100%;
}
.letterOp {
  content: url(../../assets/img/iconLila/letterOp.svg);
}
.container-down {
  display: flex;
  justify-content: space-around;
  width: 72%;
  align-items: center;
  margin-top: 1rem;
}
.clicks{
  content: url(../../assets/img/iconLila/silence.svg)
}
.allPerce {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


@media (max-width: 992px) {
  .number, .text-legend, .perc{
    font-size: 1.25em;
   
}
.textChart, .text-2, .textPro span{
    font-size: 0.863em;
}
.mes-mail {
  width: 65px;
  height: 65px;
  transform: scale(0.7);
}
  .icon-mes {

    justify-content: space-evenly;
}
.columns03 {
       align-items: center;
}
.progress01 {
  
    justify-content: center;
}
.progrBar{
    margin-left: 0.5rem;
}
.letterOp,.clicks {
  width: 40px;
  height: 40px;
  transform: scale(0.7);
}
.textDate, .textPerc {
    font-size: 0.75em;
}
.allPerce {

    align-items: center;
}
.perc {
    margin-left:0;
}
.title-charts {
    font-size: 1.375em;
  
}
}

</style>

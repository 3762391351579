<template>
  <div class="container">
    <div class="row">
      <div class="main-sidebar">
        <Sidebar></Sidebar>
      </div>
      <div class="col-sm-12  container-all">
        <div class="main">
          <div class="row center-sm center-md center-lg  ">
            <div class=" main-all">
              <div class="row rowOne">
                <div class="col-sm-5 col-md-6 col-lg-5  ">
                  <Search></Search>
                </div>
                <div class="col-sm-7 col-md-6 col-lg-7  ">
                  <NotifAndSetti>
                     <template slot="shipping">
                      <img
                        class="imgMenu"
                        src="../assets/img/iconOrange/Shipping.svg"
                        alt=""
                      />
                    </template>
                    <template slot="logout">
                      <img
                        class="imgMenu"
                        src="../assets/icons/home/Logout.svg"
                        alt=""
                      />
                    </template>
                    <template slot="info">
                      <router-link to="/centro-ayuda" class="imgMenu">
                        <img
                          class="imgInfo"
                          src="../assets/icons/home/info.svg"
                          alt=""
                        />
                      </router-link>
                    </template>
                    <template slot="config">
                      <router-link to="/perfil" class="imgMenu">
                        <img
                          class="imgMenu"
                          src="../assets/icons/home/herramientas.svg"
                          alt=""
                        />
                      </router-link>
                    </template>
                    <template slot="notif">
                      <img
                        class="imgMenu"
                        src="../assets/icons/home/campana.svg"
                        alt=""
                      />
                    </template>
                  </NotifAndSetti>
                </div>
              </div>
              <div class="row rowTwo">
                <div class="col-sm-12 col-md-5 col-lg-5 contWelc">
                  <Welcome></Welcome>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                  <Contactability> </Contactability>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <Calendar></Calendar>
                </div>
              </div>
              <div class="row rowThree">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="title-services">
                    <p class="tiServ">Nuestros servicios</p>
                  </div>
                  <CardList></CardList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/common/Sidebar";
import Search from "../components/common/Search";
import NotifAndSetti from "../components/common/NotifAndSetti";
import Welcome from "../components/panels/Welcome.vue";
import Contactability from "../components/panels/Contactability";
import Calendar from "../components/calendar/Calendar";
import CardList from "../components/slider/CardList";

export default {
  components: {
    Sidebar,
    Search,
    NotifAndSetti,
    Welcome,
    Contactability,
    Calendar,
    CardList,
    /*  ModalLogout, */
  },

  /* methods: {
    openModal() {
      this.$modal.show("modalLogout");
    },
  }, */
};
</script>

<style scoped>
.rowOne {
  /* border: 2px solid brown; */
  padding-bottom: 2rem;
}
.rowTwo {
  /* border: 2px solid red; */
}
/* .col-sm-12.col-md-5.col-lg-5 {
  border:2px solid black
} */
.col-sm-7.col-md-4.col-lg-4 {
  /* border: 2px solid rgb(124, 32, 245); */
}
.col-sm-5.col-md-3.col-lg-3 {
  /* border: 2px solid rgb(14, 231, 130); */
  justify-content: flex-end;

  display: flex;
}
.main-sidebar {
  display: flex;
  justify-content: center;
  position: fixed;
  /* justify-content: center; */
  /*  border: 2px solid green;  */
  /*  width: 8%;  */
  height: 100%;
  z-index: 1000;
  /* padding-top: 3.5%; */
  /* top: 3.3vh; */
  /*  padding-left: 2.5vh; */
  /* padding-bottom: 4%; */
  /* background-color: aqua; */
  left: 3.4vh;
  /* margin: 1rem 0 1rem */
}

.container-all {
  /* margin:1rem 5rem ; */
  display: flex;
  justify-content: center;
}
.main {
  background-color: var(--color-16);
  min-height: 100vh;
  width: 92%;
  border-radius: var(--borderRadius-1);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.main-all {
  /* border: 2px solid black; */
  width: 90%;
  min-height: 100vh;
  margin-top: 2rem;
  /* margin-right: 2rem; */
  margin-left: 2.5vw;
  /* margin-bottom: 2rem; */
}

/* .search {
  width: 95%;
} */

.rowThree {
  /* border:2px solid pink; */
  /* margin-top: 2rem; */
}
.tiServ {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.563em;
  color: var(--color-17);
}
.title-services {
    display: flex;
    margin-left: 2rem;
        margin-bottom: 1rem;
}
@media (max-width: 1024px) {
  .contWelc {
    display: flex;
    justify-content: center;
    /*  margin-bottom: 2rem; */
  }
  .main-all {
    width: 88%;
    margin-left: 6.5vw;
    margin-right: 0;
  }
  /* .container-welcome {
    margin-left: 1rem;
  } */
  .main-sidebar {
    padding-left: 0;
    height: 100%;
    /* padding-top: 7%; */
    left: 0;
  }
  .col-sm-6.col-md-4.col-lg-4,.col-sm-6.col-md-3.col-lg-3 {
    margin-top: 2rem;
}
 /*  .col-sm-6 col-md-3 col-lg-3, .col-sm-12 col-md-12 col-lg-12{
    margin-top:2rem
  } */
  .rowThree {
  /* border:2px solid pink; */
   margin-top: 1rem; 
}
.title-services {
   
        margin-bottom: .5rem;
}
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .contWelc {
    display: flex;
    justify-content: flex-start;
  }
  .main-sidebar {
    /* padding-left: 0.5vh; */
    /* height: 88vh; */

    /* top: 5.5vh; */
    left: 0.3em;
  }
  /*  .container-welcome {
    margin-left: 0.5rem;
  } */
  .main-all {
    margin-right: 0;
    margin-left: 5.7vh;
  }
    .rowThree {
  /* border:2px solid pink; */
   margin-top: 1rem; 
}
  .col-sm-6.col-md-4.col-lg-4,.col-sm-6.col-md-3.col-lg-3 {
    margin-top: 0;
}
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .main-all {
    margin-right: 0;
    margin-left: 5.4vh;
  }
  .main-sidebar {
    /* padding-left: 0.5vh; */
/*     height: 88vh;

    top: 5.5vh;
    left: 1em; */
  }
  /*   .container-welcome {
    margin-left: 0.5rem;
  } */
     .rowThree {
  /* border:2px solid pink; */
   margin-top: 1rem; 
}
}
@media (min-width: 1600px) {
  .main-sidebar {
    /*   padding-left: 3.5vh; */
 /*    height: 94.4vh; */
    padding-top: 0%;
    left: 1.6em;
    
  }
}
@media (min-width: 1700px) {
  .main-sidebar {
    /*   padding-left: 3.5vh; */
    /*   height: 94.4vh; */
    padding-top: 0%; 
    left: 1.9em;
   /*  top:2.8vh */
  }
}
@media (min-width: 1800px) {
  .main-sidebar {
    /*   padding-left: 3.5vh; */
    /*   height: 90vh;
    padding-top: 0%; */
    left: 2.1em;
  }
}
@media (min-width: 1900px) {
  .main-sidebar {
    /*   padding-left: 3.5vh; */
    /*   height: 90vh;
    padding-top: 0%; */
    left: 2.5em;
  }
}
</style>

<template>
     <div @click="$router.go(-1)" class="currHead">
      <img
        class="imgArrow"
        src="../../assets/icons/others/ArrowLeft.svg"
        alt=""
      />
      <p class="title titleDark">{{text}}</p>
    </div>
</template>
<script>
export default {
     props: ["text"],
}
</script>
<style scoped>

.currHead {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  cursor: pointer;
}
.imgArrow {
  width: 1rem;
}
.title {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 3px;
  color: var(--color-17);
  margin-left: 2rem;
}
@media (max-width: 1407px){
.title{
  font-size: 1.25em
}
}
</style>

<template>
  <div class="" v-if="renderPeaks">
    <div class="tab-control">
      <p class="title-charBarr textWhite">{{ chartTitle }}</p>
      <div class="btns">
        <button
          @click="changeTab(1)"
          class="btn-tab "
          :class="{ active: currentTab == 1 }"
        >
          Aperturas
        </button>
        <button
          @click="changeTab(2)"
          class="btn-tab "
          :class="{ active: currentTab == 2 }"
        >
          Frecuencia
        </button>
      </div>
    </div>
    <div v-if="currentTab == 1">
      <apexchart
        type="bar"
        height="300"
        :options="barChartOptions"
        :series="clicksPerDay"
        class="clicksPerDay"
      />
    </div>
    <div v-if="currentTab == 2">
      <apexchart
        type="bar"
        height="300"
        :options="barChartOptions"
        :series="frecuencyPerDay"
        class="frecuencyPerDay"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentTab: 1,
      chartTitle: "Promedio de aperturas únicas según día de envío",
      renderPeaks: true,
      clicksPerDay: [
        {
          data: [400, 430, 448, 470, 540, 580, 690],
        },
      ],
      frecuencyPerDay: [
        {
          data: [470, 540, 580, 690, 1100, 1200, 1380],
        },
      ],
      barChartOptions: {
        colors: ["#7A40F2"],
        chart: {
          toolbar: {
            show: false,
          },
          height: 300,
          type: "bar",
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        dataLabels: {
          enabled: false,
        },

        xaxis: {
          categories: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: [],
            },
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
    };
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == 1) {
        this.chartTitle = "Promedio de aperturas únicas según día de envío";
      } else {
        this.chartTitle = "Frecuencia de envío según día";
      }
    },
  },
};
</script>
<style scoped>
.title-charBarr {
  font-family: var(--font-family-3);
  font-weight: normal;
  font-size: 1.188em;
  letter-spacing: 0.694286px;
  color: var(--text-01);
  margin-bottom: 1rem;
}
.btn-tab {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  color: var(--color-05);
  background: var(--color-18);
  border: 1px solid var(--color-18);
  box-sizing: border-box;
  border-radius: 15px;
  padding: 0 0.5rem;
  cursor: pointer;
}
.active {
  background-color: var(--color-05);
  color: var(--color-18);
}
.btns {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
}
.tab-control {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1200px) and (max-width: 1407px) {
  .title-charBarr {
    font-size: 1.063em;
  }
  .btn-tab {
    font-size: 0.938em;
  }
}
</style>

<template>
  <div class="container-biasMail">
    <div class="allBiasMail">
      <div class="headerCont">
        <div class="titleHeader">
          <p>SMS</p>
        </div>
        <div class="textArrow">
           <router-link to="/bias/resumen-sms" class="">
          <p>Ver más</p>
          <span class="arrLeftMailBias"></span>
           </router-link>
        </div>
      </div>
      <div class="bodyBiasMail">
        <div class="subTitleBody">
          <p>última campaña</p>
        </div>
        <div class="allCont">
          <div class="containerLeft">
            <div class="letText01">
              <div class="img01">
                <img src="../../assets/img/iconLila/chatLi.svg" alt="" />
              </div>
              <div class="dateHour">
                <p class="date">10 Marzo</p>
                <p class="hour">17:39hrs</p>
              </div>
            </div>
            <div class="letText02">
              <div class="percText">
                <p class="perc">50</p>
                <p class="text">Enviados</p>
              </div>
              <div class="progBar">
                <progress-bar
                  size="medium"
                  :bar-border-radius="15"
                  bar-color="#7A40F2"
                  bg-color="#EDEEF4"
                  val="50"
                ></progress-bar>
              </div>
            </div>
            <div class="letText03">
              <div class="percText">
                <p class="perc">30</p>
                <p class="text">Entrevista</p>
              </div>
              <div class="progBar">
                <progress-bar
                  size="medium"
                  :bar-border-radius="15"
                  bar-color="#7A40F2"
                  bg-color="#EDEEF4"
                  val="30"
                ></progress-bar>
              </div>
            </div>
            <div class="letText04">
              <div class="percText">
                <p class="perc">11</p>
                <p class="text">Rechazados</p>
              </div>
              <div class="progBar">
                <progress-bar
                  size="medium"
                  :bar-border-radius="15"
                  bar-color="#7A40F2"
                  bg-color="#EDEEF4"
                  val="11"
                ></progress-bar>
              </div>
            </div>
          </div>
          <div class="containerRight">
            <chartBiasSms></chartBiasSms>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chartBiasSms from "../../components/charts/chartBiasSms";
import ProgressBar from "vue-simple-progress";
export default {
  components: {
    chartBiasSms,
    ProgressBar,
  },
};
</script>
<style scoped>
a{
  display: flex
}
.container-biasMail {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var( --borderRadius-1);
/*   width: 90%; */
  display: flex;
  justify-content: center;
  height: 100%;
}
.allBiasMail {
  width: 90%;
  /*   border: 2px solid red; */
}
.headerCont,
.textArrow {
  display: flex;
}
.headerCont {
  justify-content: space-between;
}
.headerCont {
  margin: 1rem 0 0;
}
.titleHeader p {
  font-size: 1.563em;
}
.textArrow {
  align-items: center;
}
.titleHeader p,
.textArrow p {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
}
.textArrow p {
  font-size: 0.875em;
  letter-spacing: 0.14px;
  color: var(--color-15);
  margin-bottom: 0.3rem;
  margin-right: 1rem;
}
.arrLeftMailBias {
  content: url(../../assets/img/iconLila/ArrowLiLeft.svg);
}
.subTitleBody p {
  font-size:0.75em;
  text-align: start;
}

.subTitleBody p,
.dateHour p,
.percText p {
  font-family: var(--font-family-3);
  font-weight: 500;
}
.dateHour,
.percText {
  line-height: 100%;
  text-align: start;
}
.dateHour p,
.subTitleBody p,
.titleHeader p,
.percText p {
  color: var(--color-17);
}
.percText {
  width: 35%;
}

.date,
.perc {
  font-size:1.25rem;
  letter-spacing: 0.694286px;
}
.hour,
.text {
  font-size: 10px;
}
.containerLeft {
  width: 50%;
  margin-top: 2rem;
}
.letText01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #1cc8ee;
  padding-bottom: 1.5rem;
}
.letText02,
.letText03,
.letText04 {
  display: flex;
  align-items: baseline;
  margin: 0.5rem 0;
  /* align-items: center; */

  /* justify-content: space-around; */
}
.letText02 {
  /* margin: 1.5rem 0 1.5rem; */
}
.letText03 {
  /*  margin: 0 0 1rem; */
}
.containerRight {
  width: 50%;
}
.allCont {
  display: flex;
  justify-content: space-between;
}
.progBar {
  width: 65%;
}
@media (max-width: 992px){
   .titleHeader p{
    font-size: 1.25em
  }
  .img01 img{
  width: 70%;
}
.perc {
    font-size: 1.05em;
    
}
.allBiasMail {
    width: 95%;
   
}
}
@media (min-width: 1024px) and (max-width: 1119px){
  .titleHeader p{
    font-size: 1.25em;
  }
  .img01 img{
  width: 70%;
}
.date, .perc {
    font-size: 1.05em;
    
}
.allBiasMail {
    width: 95%;
    /* border: 2px solid red; */
}
.containerLeft{
    width: 40%;
    
}
.containerRight {
    width: 56%;
}
}

</style>
